// ROUTES
import router from '@/router'

// Localhost API
// export const apiDomain = 'http://127.0.0.1:8000/'

// On the fly API
// export const apiDomain = 'https://venuemarketer.com/new/agingcarenet/api/public/'
export const apiDomain = 'https://api.agingcarenet.com/'

// Path
export const path = 'api/'

export const loginData = {
    clientId: '2',
    clientSecret: 'zYWlr7Z92oObfSyv5Dy4Wgrll4QjypKM6xyJF4VX',
    email: 'api@agingcarenet.com',
    password: '9q798u*J?xsMCa,'
}

export const apiMethods = {
    // AUTH ROUTES
    loginURL: `${apiDomain}oauth/token`,
    resetPassword: `${apiDomain}${path}resetPassword`,
    sendEmailResetPassword: `${apiDomain}${path}sendEmailResetPassword/`,

    // USER ROUTES
    userData: `${apiDomain}${path}user`,

    // SETTINGS
    settings: `${apiDomain}${path}settings?source=website`,

    // SERVICES ROUTES
    // -- NURSE AIDE TRAINING -- //
    nurseAideTraining: `${apiDomain}${path}nurse-aide-training`,
    nurseAideTrainingWithHash: `${apiDomain}${path}nurse-aide-training-with-hash`,
    stripeMakeCharge: `${apiDomain}${path}stripe-payment`,

    // -- CPR TRAINING -- //
    CPRTraining: `${apiDomain}${path}cpr-training`,
    CPRTrainingWithHash: `${apiDomain}${path}cpr-training-with-hash`,

    // -- HOME ASSESSMENT -- //
    homeAssessment: `${apiDomain}${path}home-assessment`,

    // -- HEALTH CARE SERVICE -- //
    healthCare: `${apiDomain}${path}health-care`,
    healthCareEmployment: `${apiDomain}${path}health-care-employment`,

    // -- PROVIDE HOME CARE -- //
    homeCare: `${apiDomain}${path}home-care`,

    // -- LPN PROGRAM -- //
    LPNProgram: `${apiDomain}${path}lpn-program`,
    LPNProgramWithHash: `${apiDomain}${path}lpn-program-with-hash`,

    // -- REFERRALS -- //
    referrals: `${apiDomain}${path}referrals`,

    // -- MAILING LIST -- //
    mailingList: `${apiDomain}${path}mailing-list`,

    // -- CONTACT US -- //
    contactUs: `${apiDomain}${path}contact-us`,

    // -- FAQ -- //
    FAQs: `${apiDomain}${path}faqs`,

    // -- STORE -- //
    store: `${apiDomain}${path}store`,

    // -- ORDER -- //
    order: `${apiDomain}${path}order`,

    // -- GALLERY -- //
    gallery: `${apiDomain}${path}gallery`,

    // -- STAFF -- //
    jobs: `${apiDomain}${path}job`,

    // SETTINGS ROUTES
    // -- USERS SETTINGS -- //
    users: `${apiDomain}${path}users`,
}

export const getHeaders = function () {
    const authData = JSON.parse(localStorage.getItem('authUserData'))
    if (authData && authData.accessToken) {
        const headers = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${authData.accessToken}`
            }
        }
        return headers
    } else {
        router.push({
            name: 'Home'
        })
    }
}
