import Vue from 'vue'
import Router from 'vue-router'

// import Home from '@/components/pages/Home'
import AboutUs from '@/components/pages/AboutUs'
import Services from '@/components/pages/Services'
import Careers from '@/components/pages/Careers'
import NurseAideTrainingServices from '@/components/pages/NurseAide'
// import LPNProgram from '@/components/pages/LPNProgram'
import RequestDocuments from '@/components/pages/RequestDocuments'
import ContactUs from '@/components/pages/ContactUs'
import FAQ from '@/components/pages/FAQ'

// SERVICESf
import RNAndLPNSkilled from '@/components/pages/Services/RNAndLPNSkilled'
import AttendantCareOrCompanionshipServices from '@/components/pages/Services/AttendantCareOrCompanionshipServices'
import CareManagementEnvironmentalModifications from '@/components/pages/Services/CareManagementEnvironmentalModifications'

import FreeInHomeAssessment from '@/components/pages/FreeInHomeAssessment'
import ApplyToProvideHomeCare from '@/components/pages/ApplyToProvideHomeCare'
import MeetOurStaff from '@/components/pages/MeetOurStaff'
import ReferSomeoneToUs from '@/components/pages/ReferSomeoneToUs'

import InsuranceAccepted from '@/components/pages/InsuranceAccepted'
import PhotoGallery from '@/components/pages/PhotoGallery'
import WatchOurVideos from '@/components/pages/WatchOurVideos'

import PrivacyPolicy from '@/components/pages/PrivacyPolicy'

//
import Confirmation from '@/components/pages/Confirmation'
import Payment from '@/components/pages/Payment'

Vue.use(Router)

const routes = [{
    path: '/',
    name:'Home',
    component: () => import('@/pages/HomePage')
},
// {
//     path: '/',
//     name: 'Home',
//     component: Home,
// },
{
    path: '/home-care',
    name: 'HomeCare',
    component: () => import('@/pages/HomeCare'),
},
{
    path: '/home-care/home-health-care-about-us',
    name: 'AboutUs',
    component: AboutUs,
},
{
    path: '/home-care/home-health-care-services',
    name: 'Services',
    component: Services,
},
{
    path: '/home-care/complete-home-health-care-services-application',
    name: 'CompleteHomeHealthCareServiceApplication',
    component: () => import('@/pages/CompleteHomeHealthCareServiceApplication'),
},
{
    path: '/home-care/home-health-care-careers',
    name: 'Careers',
    component: Careers,
},
{
    path: '/home-care/home-health-care-contact-us',
    name: 'ContactUs',
    component: ContactUs,
}, // START SERVICES ITEMS
{
    path: '/home-care/home-health-care-services/rn-and-lpn-skilled-home-health-care-services',
    name: 'RNAndLPNSkilled',
    component: RNAndLPNSkilled,
},
{
    path: '/home-care/home-health-care-services/attendant-care-or-companionship-services',
    name: 'AttendantCareOrCompanionshipServices',
    component: AttendantCareOrCompanionshipServices,
},
{
    path: '/home-care/home-health-care-services/care-management-environmental-modifications',
    name: 'CareManagementEnvironmentalModifications',
    component: CareManagementEnvironmentalModifications,
},
{
    // home-care/
    path: '/home-health-care-services/request-documents/:hash',
    name: 'RequestDocuments',
    component: RequestDocuments,
    props: true,
},
{
    path: '/home-care/home-health-care-free-in-home-assessment',
    name: 'FreeInHomeAssessment',
    component: FreeInHomeAssessment,
},
{
    path: '/education',
    name: 'Education',
    component: () => import('@/pages/Education'),
},
{
    path: '/education/home-health-care-services/nurse-aide-training-services',
    name: 'NurseAideTrainingServices',
    component: NurseAideTrainingServices,
},
// {
//     path: '/education/home-health-care-services/lpn-program',
//     name: 'LPNProgram',
//     component: LPNProgram,
// },
{
    path: '/education/home-health-care-services/cpr-training-services',
    name: 'CPRTrainingServices',
    component: () => import('@/components/pages/CPR')
},
{
    path: '/education/store',
    name: 'Store',
    component: () => import('@/pages/store')
},
{
    path: '/education/store/:id',
    name: 'StoreItem',
    props: true,
    component: () => import('@/pages/store/_id')
},
{
    path: '/education/home-health-care-faq',
    name: 'FAQ',
    component: FAQ
},
//
{
    path: '/home-health-care-apply-to-provide-home-care-to-your-loved-one-and-get-paid',
    name: 'ApplyToProvideHomeCare',
    component: ApplyToProvideHomeCare,
},
{
    path: '/home-health-care-meet-our-staff',
    name: 'MeetOurStaff',
    component: MeetOurStaff,
},
{
    path: '/home-health-care-refer-someone-to-us-and-get-compensated',
    name: 'ReferSomeoneToUs',
    component: ReferSomeoneToUs,
},
//
{
    path: '/home-health-care-insurance-accepted',
    name: 'InsuranceAccepted',
    component: InsuranceAccepted,
},
{
    path: '/home-health-care-photo-gallery',
    name: 'PhotoGallery',
    component: PhotoGallery,
},
{
    path: '/home-health-care-watch-our-video',
    name: 'WatchOurVideos',
    component: WatchOurVideos,
},
//
{
    path: '/check-out',
    name: 'CheckOut',
    props: true,
    component: () => import('@/pages/checkout')
},
//
{
    path: '/confirmation',
    name: 'Confirmation',
    component: Confirmation
},
//
{
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
},
//
{
    path: '/payment/:hash',
    name: 'Payment',
    component: Payment,
    props: true,
},
{
    path: '*',
    redirect: '/'
}]

export const router = new Router({
    mode: 'history',
    routes
})

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo, {
    container: "body",
    duration: 800,
    easing: "linear",
    offset: 0,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
})

router.beforeEach((to, from, next) => {
    VueScrollTo.scrollTo('body', 700, {
        offset: 0
    })

    next()
})

export default router
