<template>
    <div>
        <!-- Banner -->
        <app-banner pageName="Nurse Aide Training Documents"></app-banner>
        <!-- End Banner -->

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>

                    <!--  -->
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Services' }" tag="a" property="item" typeof="WebPage" title="Go to Services." class="post post-page">
                                <span property="name">Services</span>
                            </router-link>
                            <meta property="position" content="2">
                        </span> &gt;
                        <span class="post post-page current-item">Nurse Aide Training Documents</span>
                    </div>

                    <!--  -->
                    <div id="post-50" class="post-50 page type-page status-publish hentry">
                        <div class="entry-content">
                            <div class=intro_txt>Helping you get CNA skills and certification you need to provide safe patient care.</div>
                            <!-- <p>
                                As a PA approved private school we provide nurse aide training using State approved curriculum.
                                <br/>
                                We train and help individual pass State certification exam needed to become a nurse aide aka CNA.
                            </p>
                            <p> Interested? Enroll now! With our help, you can be properly trained to administer quality care to those who need it.</p> -->

                            <!-- FORM HERE -->
                            <request-documents-form :hash="hash" />
                            <!-- END FORM -->

                        </div>
                    </div>

                </main>

                <div class="wrapper text-center" style="padding-bottom: 20px;">
                    <div class="animate" data-animate="fadeInUp" data-duration="1.0s"></div>
                    <div class="animate" data-animate="fadeInUp" data-duration="2091ms">
                        <img style="padding: 10px;" src="~@/assets/images/approved.png" alt="">
                        <img style="padding: 10px;" src="~@/assets/images/license.png" alt="">
                    </div>
                    <div class="animate" data-animate="fadeInUp" data-duration="1.0s"></div>
                </div>

                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/components/layouts/banner'
import RequestDocumentsForm from '@/components/forms/RequestDocumentsForm'

export default {
    props: ['hash'],
    data() {
        return {}
    },
    components: {
        appBanner: banner,
        RequestDocumentsForm,
    }
}
</script>
