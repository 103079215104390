<template>
    <div>
        <!-- Banner -->
        <app-banner pageName="Nurse Aide Training Services"></app-banner>
        <!-- End Banner -->

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>

                    <!--  -->
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Services' }" tag="a" property="item" typeof="WebPage" title="Go to Services." class="post post-page">
                                <span property="name">Services</span>
                            </router-link>
                            <meta property="position" content="2">
                        </span> &gt;
                        <span class="post post-page current-item">Nurse Aide Training Services</span>
                    </div>

                    <!--  -->
                    <div id="post-50" class="post-50 page type-page status-publish hentry">
                        <div class="entry-content">
                            <div class=intro_txt>
                                Helping you gain the knowledge and skills to care <br /> for your aging or ailing loved one at home.
                            </div>
                            <p>
                                As a PA approved private school we provide nurse aide training using State approved curriculum.
                                <br/>
                                We train and help individual pass State certification exam needed to become a nurse aide aka CNA.
                            </p>
                            <p> Interested? Enroll now! With our help, you can be properly trained to administer quality care to those who need it.</p>

                            <!-- FORM HERE -->
                            <nurse-aide-training-services-form />
                            <!-- END FORM -->

                        </div>
                    </div>

                </main>

                <div class="wrapper text-center" style="padding-bottom: 20px;">
                    <div class="animate" data-animate="fadeInUp" data-duration="1.0s"></div>
                    <div class="animate" data-animate="fadeInUp" data-duration="2091ms">
                        <img style="padding: 10px;" src="~@/assets/images/approved.png" alt="">
                        <img style="padding: 10px;" src="~@/assets/images/license.png" alt="">
                    </div>
                    <div class="animate" data-animate="fadeInUp" data-duration="1.0s"></div>
                </div>

                <div class="clearfix"></div>
            </div>
        </div>

        <!-- MODAL -->
        <div v-if="showPopup" class="modal-container">
            <span class="external-modal-close" @click="showPopup = false">x</span>
            <div class="modal-popup">
                <span class="modal-close" @click="showPopup = false">x</span>
                <template v-for="(popup, index) in popups">
                    <div class="modal-content" v-show="activeSlide == index" :key="`popup-${index}`">
                        <div class="popup-header" v-if="popup.subject">
                            <h1>{{ popup.subject }}</h1>
                        </div>
                        <div class="popup-body" v-if="popup.content">
                            <div class="popup-content">
                                <div class="popup-txt" v-html="popup.content"></div>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="modal-nav" v-if="popups && popups.length && popups.length > 1">
                    <span class="nav-direction nav-next" :class="{ 'is-disabled': activeSlide == (popups.length - 1) }" @click="activeSlide++">
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                    <span class="nav-direction nav-prev" :class="{ 'is-disabled': activeSlide == 0 }" @click="activeSlide--">
                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                    </span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { getSettings } from '@/api/apiCalls'
import banner from '@/components/layouts/banner'
import NurseAideTrainingServicesForm from '@/components/forms/NurseAideTrainingServicesForm'

export default {

    data() {
        return {
            intro: {},
            popups: [],
            activeSlide: 0,
            showPopup: false,
        }
    },

    components: {
        appBanner: banner,
        NurseAideTrainingServicesForm
    },

    beforeMount () {
        getSettings().then(({ data }) => {
            this.intro = data.data
            this.popups = this.intro.popups

            this.showPopup = this.popups && this.popups.length ? true : false
        })
    },

}
</script>

<style>
.external-modal-close {
    width: 30px;
    height: 30px;
    background: darkred;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    margin: 0 auto;
    line-height: 30px;
    position: fixed;
    top: 15px;
    right: 15px;
    font-size: 18px;
    z-index: 999999999;
}
.modal-nav {
    position: absolute;
    top: 50%;
    margin: 0 auto;
    text-align: center;
    display: block;
    width: 100%;
}
.nav-direction {
    position: absolute;
    color: #fff;
    width: 30px;
    height: 30px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    background: darkred;
    line-height: 0;
    margin: 0 auto;
    cursor: pointer;
    font-size: 18px;
}
.nav-direction.nav-next {
    right: -40px!important;
}
.nav-direction.nav-prev {
    left: -40px!important;
}
.nav-direction.is-disabled {
    cursor: not-allowed!important;
    pointer-events: none!important;
    background: #8b000061!important;
}

@media only screen and (max-width: 600px) {
    .modal-popup {
        width: 75%!important;
    }
}

.bnr_info {
    padding: 5px 50px 20px 43.4%!important;
}

body {
    position: relative;
}

.modal-container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 999;
}

.modal-container .modal-popup .modal-close {
    width: 30px;
    height: 30px;
    background: darkred;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    margin: 0 auto;
    line-height: 30px;
    position: absolute;
    top: -15px;
    right: -10px;
    font-size: 18px;
}

.modal-container .modal-popup h3 {
    font-size: 1.3em;
    color: #000;
    padding: 10px;
    font-weight: bold;
}

.modal-container .modal-popup p {
    font-size: 1.1em;
    color: #454545;
    padding: 1px;
}

.modal-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background: #fff;
    border-radius: 5px;
    z-index: 99999;
}
.modal-popup .popup-header {
    width: 100%;
    padding: 20px 0px;
    display: flex;
    justify-content: center;
    background: #2355f2 url(https://rvs-newsletter-popup.vercel.app/Assets/Pattern.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.modal-popup .popup-header h1 {
    text-align: center;
    color: #fff;
    font-size: 34px;
    line-height: 42px;
    font-weight: 600;
}
.modal-popup .popup-body {
    width: 100%;
    padding: 25px 0px;
    display: flex;
    justify-content: center;
}
.modal-popup .popup-body .popup-content {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;
}
.modal-popup .popup-body .popup-content .popup-txt, .modal-popup .popup-body .popup-profile .profile-txt p {
    width: 90%;
    color: #a3a3a3;
    line-height: 20px;
    text-align: center;
    font-weight: 500;
}
.modal-popup .popup-body .popup-content .popup-form {
    width: 100%;
    display: flex;
    column-gap: 10px;
    margin-top: 15px;
}
.modal-popup .popup-body .popup-content .popup-form input, .modal-popup .popup-body .popup-content .popup-form button {
    padding: 15px;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
}
.modal-popup .popup-body .popup-content .popup-form input {
    width: 70%;
    border: 2px solid #e9eaf1;
    background-color: #f5f7fa;
    color: #a3a3a3;
}
.modal-popup .popup-body .popup-content .popup-form button {
    cursor: pointer;
    background-color: #2355f2;
    border: 0px;
    color: #fff;
}
.modal-popup .popup-body .popup-content .popup-alt-txt {
    width: 100%;
    color: #a3a3a3aa;
    line-height: 20px;
    text-align: start;
    font-weight: 500;
}
.modal-popup .popup-body .popup-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 12px;
    margin-top: 20px;
}
.modal-popup .popup-body .popup-profile .profile-content {
    display: flex;
    column-gap: 12px;
    align-items: center;
}
.modal-popup .popup-body .popup-profile .profile-content .profile-img img {
    width: 50px;
}
.modal-popup .popup-body .popup-profile .profile-content .profile-details h4 {
    font-weight: 600;
}
.modal-popup .popup-body .popup-profile .profile-content .profile-details p {
    color: #a3a3a3;
    font-size: 14px;
}

@media only screen and (max-width: 600px) {
    .modal-popup {
        width: 100%;
    }
    .modal-popup .popup-header {
        padding: 10px 0px;
    }
}
</style>