<template>
    <div class="clearfix">
        <div class="wrapper">
            <div id="contact_us_form_1" class="template_form">
                <div class="form_frame_b">
                    <div class="form_content">
                        <form id="submitform" @submit.prevent="handleSubmitForm" name="contact">

                            <alert :alertData="alertData"/>

                            <span class="required-info">* Required Information</span>

                            <!-- Applicant Information -->
                            <div class="main fieldbox">
                                <div class="fieldbox">
                                    <div class="fieldheader">
                                        <p>
                                            <i class="fa fa-info-circle"></i> Applicant Information
                                        </p>
                                        <input autocomplete="nope" type="hidden" name="Applicant_Information" value=":">
                                    </div>
                                    <div class="fieldcontent">
                                        <div class="form_box_col2">
                                            <div class="group">
                                                <div class="form_label">
                                                    <label for="First_Name" class="text_uppercase">
                                                        <strong>First Name
                                                            <span class="required_filed">*</span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" name="First_Name" v-model="nurseAideTraining.applicant.firstName" :class="{ 'error': submitted && $v.nurseAideTraining.applicant.firstName.$error }" class="form_field" id="First_Name" placeholder="Enter first name here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                            <div class="group">
                                                <div class="form_label">
                                                    <label for="Last_Name" class="text_uppercase">
                                                        <strong>Last Name
                                                            <span class="required_filed">*</span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" name="Last_Name" v-model="nurseAideTraining.applicant.lastName" :class="{ 'error': submitted && $v.nurseAideTraining.applicant.lastName.$error }" class="form_field" id="Last_Name" placeholder="Enter last name here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                            <!-- <div class="group">
                                                <div class="form_label">
                                                    <label class="text_uppercase">
                                                        <strong>Middle Name
                                                            <span class="required_filed"></span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" name="Middle_Name" v-model="nurseAideTraining.applicant.middleName" class="form_field" id="Middle_Name" placeholder="Enter middle name here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="form_box_col2">
                                            <div class="group">
                                                <div class="form_label">
                                                    <label for="Address" class="text_uppercase">
                                                        <strong>Address
                                                            <span class="required_filed">*</span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" name="Address" v-model="nurseAideTraining.applicant.address" :class="{ 'error': submitted && $v.nurseAideTraining.applicant.address.$error }" class="form_field" id="Address" placeholder="Enter address here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                            <div class="group">
                                                <div class="form_label">
                                                    <label for="Apartment_Number" class="text_uppercase">
                                                        <strong>Apartment Number
                                                            <span class="required_filed">*</span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" name="Apartment_Number" v-model="nurseAideTraining.applicant.apartmentNumber" :class="{ 'error': submitted && $v.nurseAideTraining.applicant.apartmentNumber.$error }" class="form_field" id="Apartment_Number" placeholder="Enter here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_box_col3">
                                            <div class="group">
                                                <div class="form_label">
                                                    <label for="City" class="text_uppercase">
                                                        <strong>City
                                                            <span class="required_filed">*</span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" name="City" v-model="nurseAideTraining.applicant.city" :class="{ 'error': submitted && $v.nurseAideTraining.applicant.city.$error }" class="form_field" id="City" placeholder="Enter city here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                            <div class="group">
                                                <div class="form_label">
                                                    <label for="State" class="text_uppercase">
                                                        <strong>State
                                                            <span class="required_filed"></span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="select-field">
                                                    <select name="State" id="State" v-model="nurseAideTraining.applicant.state" class="form_field">
                                                        <option value="">Please select state.</option>
                                                        <option value="Alabama">Alabama</option>
                                                        <option value="Alaska">Alaska</option>
                                                        <option value="Arizona">Arizona</option>
                                                        <option value="Arkansas">Arkansas</option>
                                                        <option value="California">California</option>
                                                        <option value="Colorado">Colorado</option>
                                                        <option value="Connecticut">Connecticut</option>
                                                        <option value="Delaware">Delaware</option>
                                                        <option value="District Of Columbia">District Of Columbia</option>
                                                        <option value="Florida">Florida</option>
                                                        <option value="Georgia">Georgia</option>
                                                        <option value="Hawaii">Hawaii</option>
                                                        <option value="Idaho">Idaho</option>
                                                        <option value="Illinois">Illinois</option>
                                                        <option value="Indiana">Indiana</option>
                                                        <option value="Iowa">Iowa</option>
                                                        <option value="Kansas">Kansas</option>
                                                        <option value="Kentucky">Kentucky</option>
                                                        <option value="Louisiana">Louisiana</option>
                                                        <option value="Maine">Maine</option>
                                                        <option value="Maryland">Maryland</option>
                                                        <option value="Massachusetts">Massachusetts</option>
                                                        <option value="Michigan">Michigan</option>
                                                        <option value="Minnesota">Minnesota</option>
                                                        <option value="Mississippi">Mississippi</option>
                                                        <option value="Missouri">Missouri</option>
                                                        <option value="Montana">Montana</option>
                                                        <option value="Nebraska">Nebraska</option>
                                                        <option value="Nevada">Nevada</option>
                                                        <option value="New Hampshire">New Hampshire</option>
                                                        <option value="New Jersey">New Jersey</option>
                                                        <option value="New Mexico">New Mexico</option>
                                                        <option value="New York">New York</option>
                                                        <option value="North Carolina">North Carolina</option>
                                                        <option value="North Dakota">North Dakota</option>
                                                        <option value="Ohio">Ohio</option>
                                                        <option value="Oklahoma">Oklahoma</option>
                                                        <option value="Oregon">Oregon</option>
                                                        <option value="Pennsylvania">Pennsylvania</option>
                                                        <option value="Puerto Rico">Puerto Rico</option>
                                                        <option value="Rhode Island">Rhode Island</option>
                                                        <option value="South Carolina">South Carolina</option>
                                                        <option value="South Dakota">South Dakota</option>
                                                        <option value="Tennessee">Tennessee</option>
                                                        <option value="Texas">Texas</option>
                                                        <option value="Utah">Utah</option>
                                                        <option value="Vermont">Vermont</option>
                                                        <option value="Virgin Islands">Virgin Islands</option>
                                                        <option value="Virginia">Virginia</option>
                                                        <option value="Washington">Washington</option>
                                                        <option value="West Virginia">West Virginia</option>
                                                        <option value="Wisconsin">Wisconsin</option>
                                                        <option value="Wyoming">Wyoming</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="group">
                                                <div class="form_label">
                                                    <label for="Zip" class="text_uppercase">
                                                        <strong>Zip
                                                            <span class="required_filed">*</span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" name="Zip" v-model="nurseAideTraining.applicant.zipCode" :class="{ 'error': submitted && $v.nurseAideTraining.applicant.zipCode.$error }" class="form_field" id="Zip" placeholder="Enter zip here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form_box_col2">
                                            <div class="group">
                                                <div class="form_label">
                                                    <label for="Phone" class="text_uppercase">
                                                        <strong>Phone
                                                            <span class="required_filed">*</span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <vue-tel-input
                                                        name="Phone"
                                                        v-model="nurseAideTraining.applicant.phoneNumber"
                                                        :class="{ 'error': submitted && $v.nurseAideTraining.applicant.phoneNumber.$error }"
                                                        class="form_field cphone"
                                                        placeholder="+1 (phone number)"
                                                        :inputOptions="{
                                                            id: 'Phone'
                                                        }"
                                                    />
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>

                                            <!-- <div class="group">
                                                <div class="form_label">
                                                    <label class="text_uppercase">
                                                        <strong>Second Phone
                                                            <span class="required_filed"></span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <vue-tel-input name="secondPhone" v-model="nurseAideTraining.applicant.secondPhone" class="form_field cphone" placeholder="+1 (phone number)"></vue-tel-input>
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div> -->

                                            <div class="group">
                                                <div class="form_label">
                                                    <label for="Email" class="text_uppercase">
                                                        <strong>Email
                                                            <span class="required_filed">*</span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" name="Email" v-model="nurseAideTraining.applicant.email" :class="{ 'error': submitted && $v.nurseAideTraining.applicant.email.$error }" class="form_field" id="Email" placeholder="Enter email here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>


                                            <div class="form_box_col2">

                                                <div class="group">
                                                    <table class="radio" border="0" cellpadding="0" cellspacing="0" id="places-options">
                                                        <tr>
                                                            <th>
                                                                <div class="form_label">
                                                                    <div class="text_uppercase">
                                                                        <strong>Desired location/campus
                                                                            <span class="required_filed">*</span>
                                                                        </strong>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 100%!important;">
                                                                <input autocomplete="nope" type="radio" :class="{ error: submitted && $v.nurseAideTraining.applicant.desiredLocation.$error }" v-model="nurseAideTraining.applicant.desiredLocation" name="Do_you_have_a_driver's_license?" value="1" id="w-tabor-road-aka">
                                                                <label for="w-tabor-road-aka" style="font-weight:normal; color:#000;">
                                                                    <a href="https://goo.gl/maps/s9LZ5CfiVmoq8T1L6" title="1335 w tabor road aka Broad and Olney Philadelphia" target="_blank">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 82.83 83.01" id="map">
                                                                            <g data-name="Layer 2">
                                                                                <g data-name="Layer 1">
                                                                                    <path fill="#d0d3d6"
                                                                                        d="m60.41 77.11-19 5-19-5L0 83.01l6.63-63.27 18.78-5.87 16 5 16-5 18.79 5.87 6.63 63.27-22.42-5.9z"></path>
                                                                                    <path fill="#40b29b"
                                                                                        d="m73.42 22.01-16.01-5-16 5-16-5-16 5-5.99 57 18.99-5 19 5 19-5 19.01 5-6-57z"></path>
                                                                                    <path fill="#40b29b" d="m22.41 74.01-18.99 5 5.99-57 16-5-3 57z"></path>
                                                                                    <path fill="#30917a" d="m41.41 79.01-19-5 3-57 16 5v57z"></path>
                                                                                    <path fill="#40b29b" d="m60.41 74.01-19 5v-57l16-5 3 57z"></path>
                                                                                    <path fill="#30917a" d="m41.67 59.59 17.74-7.74V33.01L41.67 59.59z"></path>
                                                                                    <path fill="#30917a" d="m79.42 79.01-19.01-5-3-57 16.01 5 6 57z"></path>
                                                                                    <path fill="#eec64a"
                                                                                        d="M41.42 0a23.25 23.25 0 0 0-23.26 23.25c0 12.84 20.86 36.58 23.25 36.58s23.26-23.74 23.26-36.58A23.25 23.25 0 0 0 41.42 0zm0 31.67A8.71 8.71 0 1 1 50.13 23a8.71 8.71 0 0 1-8.71 8.67z"></path>
                                                                                    <path fill="#ecf0f1"
                                                                                        d="M41.42 11.51A11.45 11.45 0 1 0 52.87 23a11.45 11.45 0 0 0-11.45-11.49zm0 20.17A8.71 8.71 0 1 1 50.13 23a8.71 8.71 0 0 1-8.71 8.67z"></path>
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                    </a>

                                                                    1335 w tabor road aka Broad and Olney Philadelphia
                                                                </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 100%!important;">
                                                                <input autocomplete="nope" type="radio" :class="{ error: submitted && $v.nurseAideTraining.applicant.desiredLocation.$error }" v-model="nurseAideTraining.applicant.desiredLocation" name="Do_you_have_a_driver's_license?" value="2" id="garrett-rd-upper">
                                                                <label for="garrett-rd-upper" style="font-weight:normal; color:#000;">
                                                                    <a href="https://goo.gl/maps/LwtfZhEsDXepcSTn8" title="46 Garrett rd upper darby - aka 69th street location" target="_blank">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 82.83 83.01" id="map">
                                                                            <g data-name="Layer 2">
                                                                                <g data-name="Layer 1">
                                                                                    <path fill="#d0d3d6"
                                                                                        d="m60.41 77.11-19 5-19-5L0 83.01l6.63-63.27 18.78-5.87 16 5 16-5 18.79 5.87 6.63 63.27-22.42-5.9z"></path>
                                                                                    <path fill="#40b29b"
                                                                                        d="m73.42 22.01-16.01-5-16 5-16-5-16 5-5.99 57 18.99-5 19 5 19-5 19.01 5-6-57z"></path>
                                                                                    <path fill="#40b29b" d="m22.41 74.01-18.99 5 5.99-57 16-5-3 57z"></path>
                                                                                    <path fill="#30917a" d="m41.41 79.01-19-5 3-57 16 5v57z"></path>
                                                                                    <path fill="#40b29b" d="m60.41 74.01-19 5v-57l16-5 3 57z"></path>
                                                                                    <path fill="#30917a" d="m41.67 59.59 17.74-7.74V33.01L41.67 59.59z"></path>
                                                                                    <path fill="#30917a" d="m79.42 79.01-19.01-5-3-57 16.01 5 6 57z"></path>
                                                                                    <path fill="#eec64a"
                                                                                        d="M41.42 0a23.25 23.25 0 0 0-23.26 23.25c0 12.84 20.86 36.58 23.25 36.58s23.26-23.74 23.26-36.58A23.25 23.25 0 0 0 41.42 0zm0 31.67A8.71 8.71 0 1 1 50.13 23a8.71 8.71 0 0 1-8.71 8.67z"></path>
                                                                                    <path fill="#ecf0f1"
                                                                                        d="M41.42 11.51A11.45 11.45 0 1 0 52.87 23a11.45 11.45 0 0 0-11.45-11.49zm0 20.17A8.71 8.71 0 1 1 50.13 23a8.71 8.71 0 0 1-8.71 8.67z"></path>
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                    </a>

                                                                    46 Garrett rd upper darby - aka 69th street location
                                                                </label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 100%!important;">
                                                                <input autocomplete="nope" type="radio" :class="{ error: submitted && $v.nurseAideTraining.applicant.desiredLocation.$error }" v-model="nurseAideTraining.applicant.desiredLocation" name="Do_you_have_a_driver's_license?" value="3" id="frankford-ave">
                                                                <label for="frankford-ave" style="font-weight:normal; color:#000;">
                                                                    <a href="https://goo.gl/maps/LFpFBKreAZ8WE4ob9" title="4421 frankford ave Philadelphia - aka frankford location" target="_blank">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 82.83 83.01" id="map">
                                                                            <g data-name="Layer 2">
                                                                                <g data-name="Layer 1">
                                                                                    <path fill="#d0d3d6"
                                                                                        d="m60.41 77.11-19 5-19-5L0 83.01l6.63-63.27 18.78-5.87 16 5 16-5 18.79 5.87 6.63 63.27-22.42-5.9z"></path>
                                                                                    <path fill="#40b29b"
                                                                                        d="m73.42 22.01-16.01-5-16 5-16-5-16 5-5.99 57 18.99-5 19 5 19-5 19.01 5-6-57z"></path>
                                                                                    <path fill="#40b29b" d="m22.41 74.01-18.99 5 5.99-57 16-5-3 57z"></path>
                                                                                    <path fill="#30917a" d="m41.41 79.01-19-5 3-57 16 5v57z"></path>
                                                                                    <path fill="#40b29b" d="m60.41 74.01-19 5v-57l16-5 3 57z"></path>
                                                                                    <path fill="#30917a" d="m41.67 59.59 17.74-7.74V33.01L41.67 59.59z"></path>
                                                                                    <path fill="#30917a" d="m79.42 79.01-19.01-5-3-57 16.01 5 6 57z"></path>
                                                                                    <path fill="#eec64a"
                                                                                        d="M41.42 0a23.25 23.25 0 0 0-23.26 23.25c0 12.84 20.86 36.58 23.25 36.58s23.26-23.74 23.26-36.58A23.25 23.25 0 0 0 41.42 0zm0 31.67A8.71 8.71 0 1 1 50.13 23a8.71 8.71 0 0 1-8.71 8.67z"></path>
                                                                                    <path fill="#ecf0f1"
                                                                                        d="M41.42 11.51A11.45 11.45 0 1 0 52.87 23a11.45 11.45 0 0 0-11.45-11.49zm0 20.17A8.71 8.71 0 1 1 50.13 23a8.71 8.71 0 0 1-8.71 8.67z"></path>
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                    </a>

                                                                    4421 frankford ave Philadelphia - aka frankford location
                                                                </label>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>

                                                <div class="group" v-if="nurseAideTraining.applicant.desiredLocation">
                                                    <table class="radio" border="0" cellpadding="0" cellspacing="0">
                                                        <tr>
                                                            <th>
                                                                <div class="form_label">
                                                                    <div class="text_uppercase">
                                                                        <strong>Select desired class
                                                                            <span class="required_filed">*</span>
                                                                        </strong>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td style="width: 100%!important;">
                                                                <input autocomplete="nope" type="radio" :class="{ error: submitted && $v.nurseAideTraining.applicant.desiredClass.$error }" v-model="nurseAideTraining.applicant.desiredClass" name="desiredClass" value="a" id="desiredClass0">
                                                                <label for="desiredClass0" style="font-weight:normal; color:#000;">A. Day class from 9AM to 3PM</label>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="nurseAideTraining.applicant.desiredLocation && nurseAideTraining.applicant.desiredLocation != 3">
                                                            <td style="width: 100%!important;">
                                                                <input autocomplete="nope" type="radio" :class="{ error: submitted && $v.nurseAideTraining.applicant.desiredClass.$error }" v-model="nurseAideTraining.applicant.desiredClass" name="desiredClass" value="b" id="desiredClass1">
                                                                <label for="desiredClass1" style="font-weight:normal; color:#000;">B. Evening class from 5PM to 10PM</label>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="nurseAideTraining.applicant.desiredLocation && nurseAideTraining.applicant.desiredLocation != 1">
                                                            <td style="width: 100%!important;">
                                                                <input autocomplete="nope" type="radio" :class="{ error: submitted && $v.nurseAideTraining.applicant.desiredClass.$error }" v-model="nurseAideTraining.applicant.desiredClass" name="desiredClass" value="c" id="desiredClass2">
                                                                <label for="desiredClass2" style="font-weight:normal; color:#000;">C. Saturday class from 9AM to 5PM</label>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>

                                            </div>


                                            <!-- <div class="group">
                                                <div class="form_label">
                                                    <label class="text_uppercase">
                                                        <strong>Date of Birth
                                                            <span class="required_filed"></span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <datepicker format="MM/dd/yyyy" v-model="nurseAideTraining.applicant.birthDate" class="form_field Date" placeholder="Enter date here"></datepicker>
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div> -->

                                        </div>

                                        <div class="form_box">
                                            <div class="form_box_col1">
                                                <div class="group">
                                                    <div class="form_label">
                                                        <label for="mce-referredBy" class="text_uppercase">
                                                            <strong>How did you hear about us?</strong>
                                                        </label>
                                                    </div>
                                                    <div class="field_holder animated_box">
                                                        <input
                                                            type="text"
                                                            v-model="nurseAideTraining.applicant.referredBy"
                                                            name="referredBy"
                                                            class="form_field"
                                                            autocomplete="nope"
                                                            placeholder="How did you hear about us?"
                                                            :class="{ error: submitted && $v.nurseAideTraining.applicant.referredBy.$error }"
                                                            id="mce-referredBy"
                                                        />
                                                        <span class="animated_class"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="form_box_col2">
                                            <div class="group">
                                                <div class="form_label">
                                                    <label class="text_uppercase">
                                                        <strong>Date of Birth
                                                            <span class="required_filed"></span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <datepicker format="MM/dd/yyyy" v-model="nurseAideTraining.applicant.birthDate" class="form_field Date" placeholder="Enter date here"></datepicker>
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                            <div class="group">
                                                <div class="form_label">
                                                    <label class="text_uppercase">
                                                        <strong>Social Security Number
                                                            <span class="required_filed"></span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" name="Social_Security_Number" v-model="nurseAideTraining.applicant.socialSecurityNum" class="form_field" id="Social_Security_Number" placeholder="Enter here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                        </div> -->

                                        <div class="form_box5 secode_box text-center" style="margin-top: 10px;">
                                            <div class="inner_form_box1 recapBtn">
                                                <div class="btn-submit">
                                                    <button type="submit" class="button form_button" :disabled="loading">SUBMIT</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <!-- How You Hear About Us -->
                            <!-- <div class="main fieldbox">
                                <div class="fieldbox">
                                    <div class="fieldheader">
                                        <p>
                                            <i class="fa fa-info-circle"></i> How did you hear about our school (Please check all applicable)
                                        </p>
                                        <input autocomplete="nope" type="hidden" name="How_did_you_hear_about_our_school_(Please_check_all_ applicable)" value=":">
                                    </div>
                                    <div class="fieldcontent">
                                        <div class="form_box">
                                            <div class="form_box_col1">
                                                <div class="group">
                                                    <span class="checkbox-container">
                                                        <input autocomplete="nope" v-model="nurseAideTraining.hearAboutUs.source" name="How_did_you_hear_about_our_school" id="How_did_you_hear_about_our_school_1" type="checkbox" style="-webkit-appearance:checkbox;" value="Metro">
                                                        <label for="How_did_you_hear_about_our_school_1" style="font-weight:normal; color:#000;">Metro</label>
                                                    </span>

                                                    <span class="checkbox-container">
                                                        <input autocomplete="nope" v-model="nurseAideTraining.hearAboutUs.source" name="How_did_you_hear_about_our_school" id="How_did_you_hear_about_our_school_2" type="checkbox" style="-webkit-appearance:checkbox;" value="Employment Weekly">
                                                        <label for="How_did_you_hear_about_our_school_2" style="font-weight:normal; color:#000;">Employment Weekly</label>
                                                    </span>

                                                    <span class="checkbox-container">
                                                        <input autocomplete="nope" v-model="nurseAideTraining.hearAboutUs.source" name="How_did_you_hear_about_our_school" id="How_did_you_hear_about_our_school_3" type="checkbox" style="-webkit-appearance:checkbox;" value="Walk-In">
                                                        <label for="How_did_you_hear_about_our_school_3" style="font-weight:normal; color:#000;">Walk-In</label>
                                                    </span>

                                                    <span class="checkbox-container">
                                                        <input autocomplete="nope" v-model="nurseAideTraining.hearAboutUs.source" name="How_did_you_hear_about_our_school" id="How_did_you_hear_about_our_school_4" type="checkbox" style="-webkit-appearance:checkbox;" value="Van">
                                                        <label for="How_did_you_hear_about_our_school_4" style="font-weight:normal; color:#000;">Van</label>
                                                    </span>

                                                    <span class="checkbox-container">
                                                        <input autocomplete="nope" v-model="nurseAideTraining.hearAboutUs.source" name="How_did_you_hear_about_our_school" id="How_did_you_hear_about_our_school_5" type="checkbox" style="-webkit-appearance:checkbox;" value="SEPTA Ad">
                                                        <label for="How_did_you_hear_about_our_school_5" style="font-weight:normal; color:#000;">SEPTA Ad</label>
                                                    </span>

                                                    <span class="checkbox-container">
                                                        <input autocomplete="nope" v-model="nurseAideTraining.hearAboutUs.source" name="How_did_you_hear_about_our_school" id="How_did_you_hear_about_our_school_6" type="checkbox" style="-webkit-appearance:checkbox;" value="Friend">
                                                        <label for="How_did_you_hear_about_our_school_6" style="font-weight:normal; color:#000;">Friend</label>
                                                    </span>

                                                    <span class="checkbox-container">
                                                        <input autocomplete="nope" v-model="nurseAideTraining.hearAboutUs.source" name="How_did_you_hear_about_our_school" id="How_did_you_hear_about_our_school_7" type="checkbox" style="-webkit-appearance:checkbox;" value="Previous Student">
                                                        <label for="How_did_you_hear_about_our_school_7" style="font-weight:normal; color:#000;">Previous Student</label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_box" v-if="nurseAideTraining.hearAboutUs.source.indexOf('Previous Student') !== -1">
                                            <div class="form_box_col1">
                                                <div class="group">
                                                    <div class="field_holder animated_box">
                                                        <input autocomplete="nope" type="text" v-model="nurseAideTraining.hearAboutUs.prevStudentName" name="Previous_Student__" class="form_field" id="Previous_Student__" placeholder="Enter name of previous student here">
                                                        <span class="animated_class"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_box">
                                            <div class="form_box_col2">
                                                <div class="group">
                                                    <div class="form_label">
                                                        <label class="text_uppercase">
                                                            <strong>Sex
                                                                <span class="required_filed"></span>
                                                            </strong>
                                                        </label>
                                                    </div>
                                                    <table class="radio" border="0" cellpadding="0" cellspacing="0">
                                                        <tr>
                                                            <td style='width: 50%;'>
                                                                <input autocomplete="nope" type="radio" v-model="nurseAideTraining.hearAboutUs.gender" name="Sex" value="Male"  id="Sex0">
                                                                <label for="Sex0" style="font-weight:normal; color:#000;">Male</label>
                                                            </td>
                                                            <td style='width: 50%;'>
                                                                <input autocomplete="nope" type="radio" v-model="nurseAideTraining.hearAboutUs.gender" name="Sex" value="Female"  id="Sex1">
                                                                <label for="Sex1" style="font-weight:normal; color:#000;">Female</label>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div class="group">
                                                    <div class="form_label">
                                                        <label class="text_uppercase">
                                                            <strong>Martial Status
                                                                <span class="required_filed"></span>
                                                            </strong>
                                                        </label>
                                                    </div>
                                                    <table class="radio" border="0" cellpadding="0" cellspacing="0">
                                                        <tr>
                                                            <td style='width: 50%;'>
                                                                <input autocomplete="nope" type="radio" v-model="nurseAideTraining.hearAboutUs.maritalStatus" name="Martial_Status" value="Married"  id="Martial_Status0">
                                                                <label for="Martial_Status0" style="font-weight:normal; color:#000;">Married</label>
                                                            </td>
                                                            <td style='width: 50%;'>
                                                                <input autocomplete="nope" type="radio" v-model="nurseAideTraining.hearAboutUs.maritalStatus" name="Martial_Status" value="Single"  id="Martial_Status1">
                                                                <label for="Martial_Status1" style="font-weight:normal; color:#000;">Single</label>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_box_col1">
                                            <div class="group">
                                                <div class="form_label">
                                                    <label class="text_uppercase">
                                                        <strong>Have you lived in Pennsylvania for more than 2 years
                                                            <span class="required_filed"></span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <table class="radio" border="0" cellpadding="0" cellspacing="0">
                                                    <tr>
                                                        <td style='width: 50%;'>
                                                            <input autocomplete="nope" type="radio" v-model="nurseAideTraining.hearAboutUs.livedInPS" name="Have_you_lived_in_Pennsylvania_for_more_than_2_years" :value="true"  id="Have_you_lived_in_Pennsylvania_for_more_than_2_years0">
                                                            <label for="Have_you_lived_in_Pennsylvania_for_more_than_2_years0" style="font-weight:normal; color:#000;">Yes</label>
                                                        </td>
                                                        <td style='width: 50%;'>
                                                            <input autocomplete="nope" type="radio" v-model="nurseAideTraining.hearAboutUs.livedInPS" name="Have_you_lived_in_Pennsylvania_for_more_than_2_years" :value="false"  id="Have_you_lived_in_Pennsylvania_for_more_than_2_years1">
                                                            <label for="Have_you_lived_in_Pennsylvania_for_more_than_2_years1" style="font-weight:normal; color:#000;">No</label>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="form_box_col1" v-if="nurseAideTraining.hearAboutUs.livedInPS === false">
                                            <div class="group">
                                                <div class="input" id="Explanation">
                                                    <div class="form_label">
                                                        <label class="text_uppercase">
                                                            <strong>How long have you lived in Pennsylvania
                                                                <span class="required_filed"></span>
                                                            </strong>
                                                        </label>
                                                    </div>
                                                    <div class="field_holder animated_box">
                                                        <input autocomplete="nope" type="text" v-model="nurseAideTraining.hearAboutUs.livedInPSLong" name="How_long_have_you_lived_in_Pennsylvania" class="form_field" id="How_long_have_you_lived_in_Pennsylvania" placeholder="Enter here">
                                                        <span class="animated_class"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <!-- Emergency Contact -->
                            <!-- <div class="main fieldbox">
                                <div class="fieldbox">
                                    <div class="fieldheader">
                                        <p><i class="fa fa-info-circle"></i> Emergency Contact</p>
                                        <input autocomplete="nope" type="hidden" name="Emergency_Contact" value=":">
                                    </div>
                                    <div class="fieldcontent">
                                        <div class="form_box_col2">
                                            <div class="group">
                                                <div class="form_label">
                                                    <label class="text_uppercase">
                                                        <strong>First Name
                                                            <span class="required_filed"></span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" v-model="nurseAideTraining.emergencyContact.firstName" name="First_Name_" class="form_field" id="First_Name_" placeholder="Enter first name here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                            <div class="group">
                                                <div class="form_label">
                                                    <label class="text_uppercase">
                                                        <strong>Last Name
                                                            <span class="required_filed"></span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" v-model="nurseAideTraining.emergencyContact.lastName" name="Last_Name_" class="form_field" id="Last_Name_" placeholder="Enter last name here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_box_col2">
                                            <div class="group">
                                                <div class="form_label">
                                                    <label class="text_uppercase">
                                                        <strong>Relationship to Applicant
                                                            <span class="required_filed"></span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" v-model="nurseAideTraining.emergencyContact.relationShip" name="Relationship_to_Applicant" class="form_field" id="Relationship_to_Applicant" placeholder="Enter here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                            <div class="group">
                                                <div class="form_label">
                                                    <label class="text_uppercase">
                                                        <strong>Phone
                                                            <span class="required_filed"></span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <vue-tel-input name="Phone" v-model="nurseAideTraining.emergencyContact.phoneNumber" class="form_field cphone" placeholder="+1 (phone number)"></vue-tel-input>
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_box_col2">
                                            <div class="group">
                                                <div class="form_label">
                                                    <label class="text_uppercase">
                                                        <strong>Address
                                                            <span class="required_filed"></span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" v-model="nurseAideTraining.emergencyContact.address" name="Address_" class="form_field" id="Address_" placeholder="Enter address here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                            <div class="group">
                                                <div class="form_label">
                                                    <label class="text_uppercase">
                                                        <strong>Apartment Number
                                                            <span class="required_filed"></span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" v-model="nurseAideTraining.emergencyContact.apartmentNumber" name="Apartment_Number_" class="form_field" id="Apartment_Number_" placeholder="Enter here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_box_col3">
                                            <div class="group">
                                                <div class="form_label">
                                                    <label class="text_uppercase">
                                                        <strong>City
                                                            <span class="required_filed"></span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" v-model="nurseAideTraining.emergencyContact.city" name="City_" class="form_field" id="City_" placeholder="Enter city here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                            <div class="group">
                                                <div class="form_label">
                                                    <label class="text_uppercase">
                                                        <strong>State
                                                            <span class="required_filed"></span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="select-field">
                                                    <select name="State_" v-model="nurseAideTraining.emergencyContact.state" class="form_field">
                                                        <option value="">Please select state.</option>
                                                        <option value="Alabama">Alabama</option>
                                                        <option value="Alaska">Alaska</option>
                                                        <option value="Arizona">Arizona</option>
                                                        <option value="Arkansas">Arkansas</option>
                                                        <option value="California">California</option>
                                                        <option value="Colorado">Colorado</option>
                                                        <option value="Connecticut">Connecticut</option>
                                                        <option value="Delaware">Delaware</option>
                                                        <option value="District Of Columbia">District Of Columbia</option>
                                                        <option value="Florida">Florida</option>
                                                        <option value="Georgia">Georgia</option>
                                                        <option value="Hawaii">Hawaii</option>
                                                        <option value="Idaho">Idaho</option>
                                                        <option value="Illinois">Illinois</option>
                                                        <option value="Indiana">Indiana</option>
                                                        <option value="Iowa">Iowa</option>
                                                        <option value="Kansas">Kansas</option>
                                                        <option value="Kentucky">Kentucky</option>
                                                        <option value="Louisiana">Louisiana</option>
                                                        <option value="Maine">Maine</option>
                                                        <option value="Maryland">Maryland</option>
                                                        <option value="Massachusetts">Massachusetts</option>
                                                        <option value="Michigan">Michigan</option>
                                                        <option value="Minnesota">Minnesota</option>
                                                        <option value="Mississippi">Mississippi</option>
                                                        <option value="Missouri">Missouri</option>
                                                        <option value="Montana">Montana</option>
                                                        <option value="Nebraska">Nebraska</option>
                                                        <option value="Nevada">Nevada</option>
                                                        <option value="New Hampshire">New Hampshire</option>
                                                        <option value="New Jersey">New Jersey</option>
                                                        <option value="New Mexico">New Mexico</option>
                                                        <option value="New York">New York</option>
                                                        <option value="North Carolina">North Carolina</option>
                                                        <option value="North Dakota">North Dakota</option>
                                                        <option value="Ohio">Ohio</option>
                                                        <option value="Oklahoma">Oklahoma</option>
                                                        <option value="Oregon">Oregon</option>
                                                        <option value="Pennsylvania">Pennsylvania</option>
                                                        <option value="Puerto Rico">Puerto Rico</option>
                                                        <option value="Rhode Island">Rhode Island</option>
                                                        <option value="South Carolina">South Carolina</option>
                                                        <option value="South Dakota">South Dakota</option>
                                                        <option value="Tennessee">Tennessee</option>
                                                        <option value="Texas">Texas</option>
                                                        <option value="Utah">Utah</option>
                                                        <option value="Vermont">Vermont</option>
                                                        <option value="Virgin Islands">Virgin Islands</option>
                                                        <option value="Virginia">Virginia</option>
                                                        <option value="Washington">Washington</option>
                                                        <option value="West Virginia">West Virginia</option>
                                                        <option value="Wisconsin">Wisconsin</option>
                                                        <option value="Wyoming">Wyoming</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="group">
                                                <div class="form_label">
                                                    <label class="text_uppercase">
                                                        <strong>Zip
                                                            <span class="required_filed"></span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" v-model="nurseAideTraining.emergencyContact.zipCode" name="Zip_" class="form_field" id="Zip_" placeholder="Enter zip here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_box_col1">
                                            <div class="group">
                                                <div class="form_label">
                                                    <label class="text_uppercase">
                                                        <strong>Phone
                                                            <span class="required_filed"></span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <vue-tel-input name="Phone" v-model="nurseAideTraining.emergencyContact.phoneNumber" class="form_field cphone" placeholder="+1 (phone number)"></vue-tel-input>
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_box">
                                            <div class="form_box_col1">
                                                <div class="group">
                                                    <div class="form_label">
                                                        <label class="text_uppercase">
                                                            <strong>Please check which program you are interested in attending:
                                                                <span class="required_filed"></span>
                                                            </strong>
                                                        </label>
                                                    </div>
                                                    <div class="checkbox-container mb-0 width-100">
                                                        <input autocomplete="nope" v-model="nurseAideTraining.emergencyContact.interestedProgram" name="Please_check_which_program_you_are_interested_in_attending" id="Please_check_which_program_you_are_interested_in_attending" type="checkbox" style="-webkit-appearance:checkbox;" value="Nursing Assistant Day Class">
                                                        <label for="Please_check_which_program_you_are_interested_in_attending">Nursing Assistant Day Class</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_box_col1">
                                            <div class="group">
                                                <div class="field_holder animated_box">
                                                    <datepicker format="MM/dd/yyyy" v-model="nurseAideTraining.emergencyContact.interestedProgramDate.dayClass" class="form_field Date" placeholder="Enter date here"></datepicker>
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_box">
                                            <div class="form_box_col1">
                                                <div class="group">
                                                    <div class="checkbox-container mb-0 width-100">
                                                        <input autocomplete="nope" v-model="nurseAideTraining.emergencyContact.interestedProgram" name="Please_check_which_program_you_are_interested_in_attending_" id="Please_check_which_program_you_are_interested_in_attending_" type="checkbox" style="-webkit-appearance:checkbox;" value="Nursing Assistant Evening Class">
                                                        <label for="Please_check_which_program_you_are_interested_in_attending_">Nursing Assistant Evening Class</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_box_col1">
                                            <div class="group">
                                                <div class="field_holder animated_box">
                                                    <datepicker format="MM/dd/yyyy" v-model="nurseAideTraining.emergencyContact.interestedProgramDate.eveningClass" class="form_field Date" placeholder="Enter date here"></datepicker>
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_box">
                                            <div class="form_box_col1">
                                                <div class="group">
                                                    <div class="checkbox-container mb-0 width-100">
                                                        <input autocomplete="nope" v-model="nurseAideTraining.emergencyContact.interestedProgram" name="Please_check_which_program_you_are_interested_in_attending__" id="Please_check_which_program_you_are_interested_in_attending__" type="checkbox" style="-webkit-appearance:checkbox;" value="Nursing Assistant Saturday Class">
                                                        <label for="Please_check_which_program_you_are_interested_in_attending__">Nursing Assistant Saturday Class</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_box_col1">
                                            <div class="group">
                                                <div class="field_holder animated_box">
                                                    <datepicker format="MM/dd/yyyy" v-model="nurseAideTraining.emergencyContact.interestedProgramDate.saturdayClass" class="form_field Date" placeholder="Enter date here"></datepicker>
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="main fieldbox">
                                    <div class="fieldbox">
                                        <div class="fieldheader">
                                            <p><i class="fa fa-info-circle"></i> Previous Education</p>
                                            <input autocomplete="nope" type="hidden" name="Previous_Education" value=":">
                                        </div>
                                        <div class="fieldcontent">
                                            <div class="form_box_col1">
                                                <div class="group">
                                                    <div class="form_label">
                                                        <label class="text_uppercase">
                                                            <strong>College or Trade School
                                                                <span class="required_filed"></span>
                                                            </strong>
                                                        </label>
                                                    </div>
                                                    <div class="field_holder animated_box">
                                                        <input autocomplete="nope" type="text" v-model="nurseAideTraining.previousEducation.tradeSchool.name" name="College_or_Trade_School" class="form_field" id="College_or_Trade_School" placeholder="Enter here">
                                                        <span class="animated_class"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form_box_col3">
                                                <div class="group">
                                                    <div class="form_label">
                                                        <label class="text_uppercase">
                                                            <strong>Major
                                                                <span class="required_filed"></span>
                                                            </strong>
                                                        </label>
                                                    </div>
                                                    <div class="field_holder animated_box">
                                                        <input autocomplete="nope" type="text" v-model="nurseAideTraining.previousEducation.tradeSchool.major" name="Major" class="form_field" id="Major" placeholder="Enter major here">
                                                        <span class="animated_class"></span>
                                                    </div>
                                                </div>
                                                <div class="group">
                                                    <div class="form_label">
                                                        <label class="text_uppercase">
                                                            <strong>Number of Years Attended
                                                                <span class="required_filed"></span>
                                                            </strong>
                                                        </label>
                                                    </div>
                                                    <div class="field_holder animated_box">
                                                        <input autocomplete="nope" type="text" v-model="nurseAideTraining.previousEducation.tradeSchool.numOfYears" name="Number_of_Years_Attended" class="form_field" id="Number_of_Years_Attended" placeholder="Enter here">
                                                        <span class="animated_class"></span>
                                                    </div>
                                                </div>
                                                <div class="group">
                                                    <div class="form_label">
                                                        <label class="text_uppercase">
                                                            <strong>Year Graduated
                                                                <span class="required_filed"></span>
                                                            </strong>
                                                        </label>
                                                    </div>
                                                    <div class="field_holder animated_box">
                                                        <input autocomplete="nope" type="text" v-model="nurseAideTraining.previousEducation.tradeSchool.graduatedYear" name="Year_Graduated" class="form_field" id="Year_Graduated" placeholder="Enter here">
                                                        <span class="animated_class"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form_box_col3">
                                                <div class="group">
                                                    <div class="form_label">
                                                        <label class="text_uppercase">
                                                            <strong>High School Name
                                                                <span class="required_filed"></span>
                                                            </strong>
                                                        </label>
                                                    </div>
                                                    <div class="field_holder animated_box">
                                                        <input autocomplete="nope" type="text" v-model="nurseAideTraining.previousEducation.heighSchool.name" name="High_School_Name" class="form_field" id="High_School_Name" placeholder="Enter name here">
                                                        <span class="animated_class"></span>
                                                    </div>
                                                </div>
                                                <div class="group">
                                                    <div class="form_label">
                                                        <label class="text_uppercase">
                                                            <strong>City
                                                                <span class="required_filed"></span>
                                                            </strong>
                                                        </label>
                                                    </div>
                                                    <div class="field_holder animated_box">
                                                        <input autocomplete="nope" type="text" v-model="nurseAideTraining.previousEducation.city" name="City__" class="form_field" id="City__" placeholder="Enter city here">
                                                        <span class="animated_class"></span>
                                                    </div>
                                                </div>
                                                <div class="group">
                                                    <div class="form_label">
                                                        <label class="text_uppercase">
                                                            <strong>State
                                                                <span class="required_filed"></span>
                                                            </strong>
                                                        </label>
                                                    </div>
                                                    <div class="select-field">
                                                        <select name="State__" v-model="nurseAideTraining.previousEducation.state" class="form_field">
                                                            <option value="">Please select state.</option>
                                                            <option value="Alabama">Alabama</option>
                                                            <option value="Alaska">Alaska</option>
                                                            <option value="Arizona">Arizona</option>
                                                            <option value="Arkansas">Arkansas</option>
                                                            <option value="California">California</option>
                                                            <option value="Colorado">Colorado</option>
                                                            <option value="Connecticut">Connecticut</option>
                                                            <option value="Delaware">Delaware</option>
                                                            <option value="District Of Columbia">District Of Columbia</option>
                                                            <option value="Florida">Florida</option>
                                                            <option value="Georgia">Georgia</option>
                                                            <option value="Hawaii">Hawaii</option>
                                                            <option value="Idaho">Idaho</option>
                                                            <option value="Illinois">Illinois</option>
                                                            <option value="Indiana">Indiana</option>
                                                            <option value="Iowa">Iowa</option>
                                                            <option value="Kansas">Kansas</option>
                                                            <option value="Kentucky">Kentucky</option>
                                                            <option value="Louisiana">Louisiana</option>
                                                            <option value="Maine">Maine</option>
                                                            <option value="Maryland">Maryland</option>
                                                            <option value="Massachusetts">Massachusetts</option>
                                                            <option value="Michigan">Michigan</option>
                                                            <option value="Minnesota">Minnesota</option>
                                                            <option value="Mississippi">Mississippi</option>
                                                            <option value="Missouri">Missouri</option>
                                                            <option value="Montana">Montana</option>
                                                            <option value="Nebraska">Nebraska</option>
                                                            <option value="Nevada">Nevada</option>
                                                            <option value="New Hampshire">New Hampshire</option>
                                                            <option value="New Jersey">New Jersey</option>
                                                            <option value="New Mexico">New Mexico</option>
                                                            <option value="New York">New York</option>
                                                            <option value="North Carolina">North Carolina</option>
                                                            <option value="North Dakota">North Dakota</option>
                                                            <option value="Ohio">Ohio</option>
                                                            <option value="Oklahoma">Oklahoma</option>
                                                            <option value="Oregon">Oregon</option>
                                                            <option value="Pennsylvania">Pennsylvania</option>
                                                            <option value="Puerto Rico">Puerto Rico</option>
                                                            <option value="Rhode Island">Rhode Island</option>
                                                            <option value="South Carolina">South Carolina</option>
                                                            <option value="South Dakota">South Dakota</option>
                                                            <option value="Tennessee">Tennessee</option>
                                                            <option value="Texas">Texas</option>
                                                            <option value="Utah">Utah</option>
                                                            <option value="Vermont">Vermont</option>
                                                            <option value="Virgin Islands">Virgin Islands</option>
                                                            <option value="Virginia">Virginia</option>
                                                            <option value="Washington">Washington</option>
                                                            <option value="West Virginia">West Virginia</option>
                                                            <option value="Wisconsin">Wisconsin</option>
                                                            <option value="Wyoming">Wyoming</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form_box_col3">
                                                <div class="group">
                                                    <div class="form_label">
                                                        <label class="text_uppercase">
                                                            <strong>Major
                                                                <span class="required_filed"></span>
                                                            </strong>
                                                        </label>
                                                    </div>
                                                    <div class="field_holder animated_box">
                                                        <input autocomplete="nope" type="text" v-model="nurseAideTraining.previousEducation.heighSchool.major" name="Major" class="form_field" id="Major" placeholder="Enter major here">
                                                        <span class="animated_class"></span>
                                                    </div>
                                                </div>
                                                <div class="group">
                                                    <div class="form_label">
                                                        <label class="text_uppercase">
                                                            <strong>Number of Years Attended
                                                                <span class="required_filed"></span>
                                                            </strong>
                                                        </label>
                                                    </div>
                                                    <div class="field_holder animated_box">
                                                        <input autocomplete="nope" type="text" v-model="nurseAideTraining.previousEducation.heighSchool.numOfYears" name="Number_of_Years_Attended" class="form_field" id="Number_of_Years_Attended" placeholder="Enter here">
                                                        <span class="animated_class"></span>
                                                    </div>
                                                </div>
                                                <div class="group">
                                                    <div class="form_label">
                                                        <label class="text_uppercase">
                                                            <strong>Year Graduated
                                                                <span class="required_filed"></span>
                                                            </strong>
                                                        </label>
                                                    </div>
                                                    <div class="field_holder animated_box">
                                                        <input autocomplete="nope" type="text" v-model="nurseAideTraining.previousEducation.heighSchool.graduatedYear" name="Year_Graduated" class="form_field" id="Year_Graduated" placeholder="Enter here">
                                                        <span class="animated_class"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form_box_col1">
                                                <div class="group">
                                                    <label class="text_uppercase">
                                                        <strong>G.E.D Obtained: if applicable
                                                            <span class="required_filed"></span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" v-model="nurseAideTraining.previousEducation.GEDObtained" name="G_E_D_Obtained_if_applicable" class="form_field" id="G_E_D_Obtained_if_applicable" placeholder="Enter here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                            <div class="form_box_col1">
                                                <div class="group">
                                                    <label class="text_uppercase">
                                                        <strong>List any additional skills or training which you possess including Honors Awards, Scholarships, or any extracurricular activities which you would like us to know about:
                                                            <span class="required_filed"></span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <textarea v-model="nurseAideTraining.previousEducation.skills" name="List_any_additional_skills_or_training_which_you_possess_including_Honors_Awards_Scholarships_or_any_extracurricular_activities_which_you_would_like_us_to_know_about" class="form_field" id="List_any_additional_skills_or_training_which_you_possess_including_Honors_Awards_Scholarships_or_any_extracurricular_activities_which_you_would_like_us_to_know_about" placeholder="Enter here"></textarea>
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <!-- Employment Background -->
                            <!-- <div class="fieldheader">
                                <p>
                                    <i class="fa fa-info-circle"></i> Give us a brief description of your recent Employment background.
                                </p>
                                <input autocomplete="nope" type="hidden" name="Give_us_a_brief_description_of_your_recent_Employment_background." value=":">
                            </div> -->

                            <!-- <div class="fieldcontent">
                                <p>Include places of employment, job titles, duties, salary and indicate full-time or part-time.</p>
                                <div class="form_box">
                                    <div class="form_box_col2">
                                        <div class="group">
                                            <div class="form_label">
                                                <label class="text_uppercase">
                                                    <strong>Employer
                                                        <span class="required_filed"></span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <input autocomplete="nope" type="text" v-model="nurseAideTraining.recentEmploymentBG.work[0].employer" name="Employer" class="form_field" id="Employer" placeholder="Enter here">
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                        <div class="group">
                                            <div class="form_label">
                                                <label class="text_uppercase">
                                                    <strong>Salary Desired
                                                        <span class="required_filed"></span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <i id="icon" class="fa fa-dollar-sign "></i>
                                                <input autocomplete="nope" type="text" v-model="nurseAideTraining.recentEmploymentBG.work[0].salary" name="Salary_Desired" class="form_field amount" placeholder="Enter amount here">
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_box">
                                    <div class="form_box_col3">
                                        <div class="group">
                                            <div class="form_label">
                                                <label class="text_uppercase">
                                                    <strong>From
                                                        <span class="required_filed"></span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <datepicker format="MM/dd/yyyy" v-model="nurseAideTraining.recentEmploymentBG.work[0].from" class="form_field Date" placeholder="Enter date here"></datepicker>
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                        <div class="group">
                                            <div class="form_label">
                                                <label class="text_uppercase">
                                                    <strong>To
                                                        <span class="required_filed"></span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <datepicker format="MM/dd/yyyy" v-model="nurseAideTraining.recentEmploymentBG.work[0].to" class="form_field Date" placeholder="Enter date here"></datepicker>
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                        <div class="group">
                                            <div class="form_label">
                                                <label class="text_uppercase">
                                                    <strong>Job Title
                                                        <span class="required_filed"></span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <input autocomplete="nope" type="text" v-model="nurseAideTraining.recentEmploymentBG.work[0].jobTitle" name="Job_Title" class="form_field" id="Job_Title" placeholder="Enter job title here">
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_box">
                                    <div class="form_box_col2">
                                        <div class="group">
                                            <div class="form_label">
                                                <label class="text_uppercase">
                                                    <strong>Duties
                                                        <span class="required_filed"></span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <input autocomplete="nope" type="text" v-model="nurseAideTraining.recentEmploymentBG.work[0].duties" name="Duties" class="form_field" id="Duties" placeholder="Enter duties here">
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                        <div class="group">
                                            <div class="form_label">
                                                <label class="text_uppercase">
                                                    <strong>Work Phone
                                                        <span class="required_filed"></span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <vue-tel-input name="Phone" v-model="nurseAideTraining.recentEmploymentBG.work[0].phoneNumber" class="form_field cphone" placeholder="+1 (phone number)"></vue-tel-input>
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form_box">
                                    <div class="form_box_col2">
                                        <div class="group">
                                            <div class="form_label">
                                                <label class="text_uppercase">
                                                    <strong>Employer
                                                        <span class="required_filed"></span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <input autocomplete="nope" type="text" v-model="nurseAideTraining.recentEmploymentBG.work[1].employer" name="Employer_" class="form_field" id="Employer_" placeholder="Enter here">
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                        <div class="group">
                                            <div class="form_label">
                                                <label class="text_uppercase">
                                                    <strong>Salary Desired
                                                        <span class="required_filed"></span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <i id="icon" class="fa fa-dollar-sign "></i>
                                                <input autocomplete="nope" type="text" v-model="nurseAideTraining.recentEmploymentBG.work[1].salary" name="Salary_Desired_" class="form_field amount" placeholder="Enter amount here">
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_box">
                                    <div class="form_box_col3">
                                        <div class="group">
                                            <div class="form_label">
                                                <label class="text_uppercase">
                                                    <strong>From
                                                        <span class="required_filed"></span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <datepicker format="MM/dd/yyyy" v-model="nurseAideTraining.recentEmploymentBG.work[1].from" class="form_field Date" placeholder="Enter date here"></datepicker>
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                        <div class="group">
                                            <div class="form_label">
                                                <label class="text_uppercase">
                                                    <strong>To
                                                        <span class="required_filed"></span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <datepicker format="MM/dd/yyyy" v-model="nurseAideTraining.recentEmploymentBG.work[1].to" class="form_field Date" placeholder="Enter date here"></datepicker>
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                        <div class="group">
                                            <div class="form_label">
                                                <label class="text_uppercase">
                                                    <strong>Job Title
                                                        <span class="required_filed"></span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <input autocomplete="nope" type="text" v-model="nurseAideTraining.recentEmploymentBG.work[1].jobTitle" name="Job_Title_" class="form_field" id="Job_Title_" placeholder="Enter job title here">
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_box">
                                    <div class="form_box_col2">
                                        <div class="group">
                                            <div class="form_label">
                                                <label class="text_uppercase">
                                                    <strong>Duties
                                                        <span class="required_filed"></span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <input autocomplete="nope" type="text" v-model="nurseAideTraining.recentEmploymentBG.work[1].duties" name="Duties_" class="form_field" id="Duties_" placeholder="Enter duties here">
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                        <div class="group">
                                            <div class="form_label">
                                                <label class="text_uppercase">
                                                    <strong>Work Phone
                                                        <span class="required_filed"></span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <vue-tel-input name="Phone" v-model="nurseAideTraining.recentEmploymentBG.work[1].phoneNumber" class="form_field cphone" placeholder="+1 (phone number)"></vue-tel-input>
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_box">
                                    <div class="form_box_col2">
                                        <div class="group">
                                            <div class="form_label">
                                                <label class="text_uppercase">
                                                    <strong>Applicant Name
                                                        <span class="required_filed"></span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <input autocomplete="nope" type="text" v-model="nurseAideTraining.recentEmploymentBG.applicantName" name="Applicant_Name" class="form_field" id="Applicant_Name" placeholder="Enter here">
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                        <div class="group">
                                            <div class="form_label">
                                                <label class="text_uppercase">
                                                    <strong>Date
                                                        <span class="required_filed"></span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <datepicker format="MM/dd/yyyy" v-model="nurseAideTraining.recentEmploymentBG.date" class="form_field Date" placeholder="Enter date here"></datepicker>
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_box left">
                                    <div class="form_box_col1">
                                        <div class="group">
                                            <div class="form_label">
                                                <label class="text_uppercase">
                                                    <strong>Attach Resume
                                                        <span class="required_filed"></span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <input autocomplete="nope" type="file" name="attachment[]" id="file" class="input-file" multiple @change="uploadResume($event)">
                                            <label for="file" class="btn btn-tertiary js-labelFile">
                                                <span class="">{{ resumeName ? resumeName : 'Choose a file' }}</span>
                                                <span class="icon"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form_box5 secode_box">
                                    <div class="inner_form_box1 recapBtn">
                                        <div class="btn-submit">
                                            <button type="submit" class="button form_button" :disabled="loading">SUBMIT</button>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import alert from '@/components/layouts/alert'
import { getUserAgent, getUserIp } from '@/helpers'
import { postNewNurseAideTraining } from '@/api/apiCalls'
import { required, email } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            submitted: false,
            loading: false,
            resume: '',
            resumeName: '',
            attachments: [],
            alertData: {
                showAlert: false,
                alertType: 'success',
                alertText: 'Data Added Successfully!'
            },
            nurseAideTraining: {
                applicant: {
                    state: '',
                },
                hearAboutUs: {
                    source: [],
                },
                emergencyContact: {
                    interestedProgram: [],
                    interestedProgramDate: {
                        dayClass: '',
                        eveningClass: '',
                        saturdayClass: '',
                    },
                    state: '',
                },
                previousEducation: {
                    tradeSchool: {},
                    heighSchool: {},
                    state: '',
                },
                recentEmploymentBG: {
                    work: [
                        {},
                        {}
                    ],
                },
            },
        }
    },
    validations: {
        nurseAideTraining: {
            applicant: {
                firstName: { required },
                lastName: { required },
                address: { required },
                apartmentNumber: { required },
                city: { required },
                zipCode: { required },
                phoneNumber: { required },
                email: { required, email },
                desiredLocation: { required },
                desiredClass: { required },
                referredBy: {},
            }
        }
    },
    components: {
        alert
    },
    methods: {
        uploadResume(event) {
            const files = event.target.files
            this.attachments = files
            this.resumeName = files[0].name

            var reader = new FileReader
            reader.addEventListener('load', () => {
                this.resume = reader.result
            })
            reader.readAsDataURL(files[0])
        },
        handleSubmitForm() {
            this.submitted = true

            // stop here if form is invalid
            this.$v.$touch()

            // Scroll To Error
            this.scrollToError()

            if (!this.$v.$invalid) {
                this.loading = true

                if (this.nurseAideTraining) {
                    if (this.nurseAideTraining.applicant) {
                        this.nurseAideTraining.applicant.userAgent = getUserAgent()
                        this.nurseAideTraining.applicant.ip = getUserIp()
                    }

                    if (this.nurseAideTraining.applicant && this.nurseAideTraining.applicant.birthDate) {
                        this.nurseAideTraining.applicant.birthDate = this.nurseAideTraining.applicant.birthDate ? moment(this.nurseAideTraining.applicant.birthDate).format("MM/DD/YYYY") : null
                    }

                    if (this.nurseAideTraining.emergencyContact && this.nurseAideTraining.emergencyContact.interestedProgramDate) {
                        if (this.nurseAideTraining.emergencyContact.interestedProgramDate.dayClass) {
                            this.nurseAideTraining.emergencyContact.interestedProgramDate.dayClass = this.nurseAideTraining.emergencyContact.interestedProgramDate.dayClass ? moment(this.nurseAideTraining.emergencyContact.interestedProgramDate.dayClass).format("MM/DD/YYYY") : null
                        }

                        if (this.nurseAideTraining.emergencyContact.interestedProgramDate.eveningClass) {
                            this.nurseAideTraining.emergencyContact.interestedProgramDate.eveningClass = this.nurseAideTraining.emergencyContact.interestedProgramDate.eveningClass ? moment(this.nurseAideTraining.emergencyContact.interestedProgramDate.eveningClass).format("MM/DD/YYYY") : null
                        }

                        if (this.nurseAideTraining.emergencyContact.interestedProgramDate.saturdayClass) {
                            this.nurseAideTraining.emergencyContact.interestedProgramDate.saturdayClass = this.nurseAideTraining.emergencyContact.interestedProgramDate.saturdayClass ? moment(this.nurseAideTraining.emergencyContact.interestedProgramDate.saturdayClass).format("MM/DD/YYYY") : null
                        }
                    }

                    if (this.nurseAideTraining.recentEmploymentBG) {
                        this.nurseAideTraining.recentEmploymentBG.resume = this.resume ? this.resume : null
                        this.nurseAideTraining.recentEmploymentBG.resumeName = this.resumeName ? this.resumeName : null

                        if (this.nurseAideTraining.recentEmploymentBG.date) {
                            this.nurseAideTraining.recentEmploymentBG.date = this.nurseAideTraining.recentEmploymentBG.date ? moment(this.nurseAideTraining.recentEmploymentBG.date).format("MM/DD/YYYY") : null
                        }

                        if (this.nurseAideTraining.recentEmploymentBG.work[0]) {
                            if (this.nurseAideTraining.recentEmploymentBG.work[0].from) {
                                this.nurseAideTraining.recentEmploymentBG.work[0].from = this.nurseAideTraining.recentEmploymentBG.work[0].from ? moment(this.nurseAideTraining.recentEmploymentBG.work[0].from).format("MM/DD/YYYY") : null
                            }

                            if (this.nurseAideTraining.recentEmploymentBG.work[0].to) {
                                this.nurseAideTraining.recentEmploymentBG.work[0].to = this.nurseAideTraining.recentEmploymentBG.work[0].to ? moment(this.nurseAideTraining.recentEmploymentBG.work[0].to).format("MM/DD/YYYY") : null
                            }

                            if (this.nurseAideTraining.recentEmploymentBG.work[1].from) {
                                this.nurseAideTraining.recentEmploymentBG.work[1].from = this.nurseAideTraining.recentEmploymentBG.work[1].from ? moment(this.nurseAideTraining.recentEmploymentBG.work[1].from).format("MM/DD/YYYY") : null
                            }

                            if (this.nurseAideTraining.recentEmploymentBG.work[1].to) {
                                this.nurseAideTraining.recentEmploymentBG.work[1].to = this.nurseAideTraining.recentEmploymentBG.work[1].to ? moment(this.nurseAideTraining.recentEmploymentBG.work[1].to).format("MM/DD/YYYY") : null
                            }
                        }
                    }
                }

                let data = {
                    applicant: this.nurseAideTraining.applicant,
                    hearAboutUs: this.nurseAideTraining.hearAboutUs,
                    emergencyContact: this.nurseAideTraining.emergencyContact,
                    previousEducation: this.nurseAideTraining.previousEducation,
                    recentEmploymentBG: this.nurseAideTraining.recentEmploymentBG
                }

                postNewNurseAideTraining(data).then(({ data }) => {
                    this.$scrollTo('#contact_us_form_1', 100)
                    this.alertData.showAlert = true

                    // Change Action To Payment Page
                    if (data.data && data.data.hash) {
                        this.$router.push({
                            name: 'Payment',
                            params: {
                                hash: data.data.hash,
                            },
                            query: {
                                type: 'nurse-aide-training-services'
                            }
                        })
                    } else {
                        this.$router.push({
                            name: 'Confirmation'
                        })
                    }

                    this.nurseAideTraining = {
                        applicant: {
                            state: '',
                            referredBy: null,
                        },
                        hearAboutUs: {
                            source: [],
                        },
                        emergencyContact: {
                            interestedProgram: [],
                            interestedProgramDate: {
                                dayClass: '',
                                eveningClass: '',
                                saturdayClass: '',
                            },
                            state: '',
                        },
                        previousEducation: {
                            tradeSchool: {},
                            heighSchool: {},
                            state: '',
                        },
                        recentEmploymentBG: {
                            work: [
                                {},
                                {}
                            ],
                        },
                    }
                }).catch(({ response }) => {
                    this.alertData = {
                        showAlert: true,
                        alertType: 'danger',
                        alertText: response.data.message ? response.data.message : (response.data.errors.email && response.data.errors.email[0] ? response.data.errors.email[0] : 'Something went wrong, please try again later' )
                    }

                    this.scrollToError('#warning-box-container')
                }).finally(() => {
                    this.loading = false
                    this.submitted = false

                    setTimeout(() => {
                        this.alertData.showAlert = false
                    }, 3000)
                })
            }
        }
    }
}
</script>

<style>
body {
    font-family: 'Muli', sans-serif;
}
</style>

<style lang="scss" scoped>
.form_head {border-radius: 10px; }
.form_head p.title_head:nth-child(1) { background: #ff3f3f;  margin: 0;  padding: 10px;  color: #fff;  font-weight: bold;  border-top-right-radius: 8px;  border-top-left-radius: 8px;}
.form_head .form_box .form_box_col1 p { margin-bottom: 4px; }
.form_head .form_box { margin: 0; padding: 25px 28px; border: 2px solid #ddd; border-top: none;  border-bottom-right-radius: 8px;  border-bottom-left-radius: 8px;}
.fieldh{text-align: center;}
.amount{
    padding: 10px 90px;
}
#icon {
    position: absolute;
    padding: 10px 39px 10px 10px;
    background: #002948;
    // height: 61px;
    color: #fff;
    font-size: 31px;
    top: 0;
    bottom: 0;
    margin-top: 0!important;
}
.fa-dollar-sign::before {
    content: "\f155";
    position: relative;
    left: 13px;
    top: 5px;
}
.label {text-transform: uppercase;font-weight: bold;font-size: 20px;display: block;background: #1d58b3;padding: 20px 0;text-align: center;color: #fff;margin:5px 0;}
.sub_cont {padding: 14px;background: #eeeeee;font-size: 20px;line-height: 29px;margin-bottom: 20px;}
.main.fieldbox { margin-bottom: 30px; }
.fieldbox { margin: 10px 0; }
.fieldheader p { margin: 0; background: #002948; padding: 8px; color: #fff; text-align: center; font-weight: 700; border-top-left-radius: 5px; border-top-right-radius: 10px; font-size:25px;}
.fieldheader p i.fa { margin: 0 auto!important;width: 45px;height: 20px;text-align: center; }
.fieldcontent { padding: 20px; border: 3px solid #002948; border-top: 0; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; }

@import "./../../assets/css/forms.css";
</style>
