<template>
    <div class="clearfix">
        <div class="wrapper">
            <div id="contact_us_form_1" class="template_form">
                <div class="form_frame_b">
                    <div class="form_content">
                        <form id="submitform" name="contact" novalidate @submit.prevent="handleSubmitForm">

                            <alert :alertData="alertData"/>

                            <span class="required-info">* Required Information</span>
                            <div class="form_box">
                                <div class="form_box_col1">
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Name
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" v-model="applicant.name" :class="{ 'error': submitted && $v.applicant.name.$error }" type="text" name="Name" class="form_field" id="Name" placeholder="Enter name here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form_box">
                                <div class="form_box_col2">
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Address
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" v-model="applicant.address" :class="{ 'error': submitted && $v.applicant.address.$error }" type="text" name="Address" class="form_field" id="Address" placeholder="Enter address here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>City
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" v-model="applicant.city" :class="{ 'error': submitted && $v.applicant.city.$error }" type="text" name="City" class="form_field" id="City" placeholder="Enter city here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form_box">
                                <div class="form_box_col2">
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>State
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="select-field">
                                            <select name="State" v-model="applicant.state" :class="{ 'error': submitted && $v.applicant.state.$error }" class="form_field">
                                                <option value="">Please select state.</option>
                                                <option value="Alabama">Alabama</option>
                                                <option value="Alaska">Alaska</option>
                                                <option value="Arizona">Arizona</option>
                                                <option value="Arkansas">Arkansas</option>
                                                <option value="California">California</option>
                                                <option value="Colorado">Colorado</option>
                                                <option value="Connecticut">Connecticut</option>
                                                <option value="Delaware">Delaware</option>
                                                <option value="District Of Columbia">District Of Columbia</option>
                                                <option value="Florida">Florida</option>
                                                <option value="Georgia">Georgia</option>
                                                <option value="Hawaii">Hawaii</option>
                                                <option value="Idaho">Idaho</option>
                                                <option value="Illinois">Illinois</option>
                                                <option value="Indiana">Indiana</option>
                                                <option value="Iowa">Iowa</option>
                                                <option value="Kansas">Kansas</option>
                                                <option value="Kentucky">Kentucky</option>
                                                <option value="Louisiana">Louisiana</option>
                                                <option value="Maine">Maine</option>
                                                <option value="Maryland">Maryland</option>
                                                <option value="Massachusetts">Massachusetts</option>
                                                <option value="Michigan">Michigan</option>
                                                <option value="Minnesota">Minnesota</option>
                                                <option value="Mississippi">Mississippi</option>
                                                <option value="Missouri">Missouri</option>
                                                <option value="Montana">Montana</option>
                                                <option value="Nebraska">Nebraska</option>
                                                <option value="Nevada">Nevada</option>
                                                <option value="New Hampshire">New Hampshire</option>
                                                <option value="New Jersey">New Jersey</option>
                                                <option value="New Mexico">New Mexico</option>
                                                <option value="New York">New York</option>
                                                <option value="North Carolina">North Carolina</option>
                                                <option value="North Dakota">North Dakota</option>
                                                <option value="Ohio">Ohio</option>
                                                <option value="Oklahoma">Oklahoma</option>
                                                <option value="Oregon">Oregon</option>
                                                <option value="Pennsylvania">Pennsylvania</option>
                                                <option value="Puerto Rico">Puerto Rico</option>
                                                <option value="Rhode Island">Rhode Island</option>
                                                <option value="South Carolina">South Carolina</option>
                                                <option value="South Dakota">South Dakota</option>
                                                <option value="Tennessee">Tennessee</option>
                                                <option value="Texas">Texas</option>
                                                <option value="Utah">Utah</option>
                                                <option value="Vermont">Vermont</option>
                                                <option value="Virgin Islands">Virgin Islands</option>
                                                <option value="Virginia">Virginia</option>
                                                <option value="Washington">Washington</option>
                                                <option value="West Virginia">West Virginia</option>
                                                <option value="Wisconsin">Wisconsin</option>
                                                <option value="Wyoming">Wyoming</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Zip
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" v-model="applicant.zipCode" :class="{ 'error': submitted && $v.applicant.zipCode.$error }" type="text" name="Zip" class="form_field" id="Zip" placeholder="Enter zip here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form_box prefer-mobi">
                                <div class="form_box_col1">
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>How do you prefer to be contacted?
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <table class="radio" border="0" cellpad ding="0" cellspacing="0">
                                            <tr>
                                                <td style='width: 33%;' :class="{ 'form_field error': submitted && $v.applicant.contactMethod.$error }">
                                                    <input autocomplete="nope" v-model="applicant.contactMethod" type="radio" name="How_do_you_prefer_to_be_contacted" value="Phone"  id="How_do_you_prefer_to_be_contacted0">
                                                    <label for="How_do_you_prefer_to_be_contacted0" style="font-weight:normal; color:#000;">Phone</label>
                                                </td>
                                                <td style='width: 33%;' :class="{ 'form_field error': submitted && $v.applicant.contactMethod.$error }">
                                                    <input autocomplete="nope" v-model="applicant.contactMethod" type="radio" name="How_do_you_prefer_to_be_contacted" value="Fax"  id="How_do_you_prefer_to_be_contacted1">
                                                    <label for="How_do_you_prefer_to_be_contacted1" style="font-weight:normal; color:#000;">Fax</label>
                                                </td>
                                                <td style='width: 33%;' :class="{ 'form_field error': submitted && $v.applicant.contactMethod.$error }">
                                                    <input autocomplete="nope" v-model="applicant.contactMethod" type="radio" name="How_do_you_prefer_to_be_contacted" value="Email"  id="How_do_you_prefer_to_be_contacted2">
                                                    <label for="How_do_you_prefer_to_be_contacted2" style="font-weight:normal; color:#000;">Email</label>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="form_box">
                                <div class="form_box_col2">
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Email Address
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" v-model="applicant.email" :class="{ 'error': submitted && $v.applicant.email.$error }" type="text" name="Email_Address" class="form_field" id="Email_Address" placeholder="Enter email address here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Fax
                                                    <span class="required_filed"></span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" v-model="applicant.fax" type="text" name="Fax" class="form_field" id="Fax" placeholder="Enter fax here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form_box">
                                <div class="form_box_col2">
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Phone
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" v-model="applicant.phoneNumber" :class="{ 'error': submitted && $v.applicant.phoneNumber.$error }" type="text" name="Phone" class="form_field" id="Phone" placeholder="Enter phone here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Best time to call
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="select-field">
                                            <select name="Best_time_to_call" v-model="applicant.bestCallTime" :class="{ 'error': submitted && $v.applicant.bestCallTime.$error }" class="form_field">
                                                <option value="">- Please select -</option>
                                                <option value="Anytime">Anytime</option>
                                                <option value="Morning at Home">Morning at Home</option>
                                                <option value="Morning at Work">Morning at Work</option>
                                                <option value="Afternoon at Home">Afternoon at Home</option>
                                                <option value="Afternoon at Work">Afternoon at Work</option>
                                                <option value="Evening at Home">Evening at Home</option>
                                                <option value="Evening at Work">Evening at Work</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form_box">
                                <div class="form_box_col2">
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Preferred Date
                                                    <span class="required_filed"></span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <datepicker format="MM/dd/yyyy" v-model="applicant.callDate" class="form_field Date" id="Preferred_Date" placeholder="Enter preferred date here"></datepicker>
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Preferred Time
                                                    <span class="required_filed"></span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" v-model="applicant.callTime" type="text" name="Preferred_Time" class="form_field" id="Preferred_Time" placeholder="Enter preferred time here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form_box">
                                <div class="form_box_col1">
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Comment
                                                    <span class="required_filed"></span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <textarea name="Comment" v-model="applicant.comment" class="text form_field" id="Comment" placeholder="Enter comment here"></textarea>
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form_box5 secode_box">
                                <div class="group">
                                    <div class="inner_form_box1 recapBtn">
                                        <div class="btn-submit">
                                            <button type="submit" class="button form_button" :disabled="loading">SUBMIT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import alert from '@/components/layouts/alert'
import { getUserAgent, getUserIp } from '@/helpers'
import { postNewHomeAssessment } from '@/api/apiCalls'
import { required, email } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            submitted: false,
            loading: false,
            applicant: {
                state: "",
                bestCallTime: ""
            },
            alertData: {
                showAlert: false,
                alertType: "success",
                alertText: "Data Added Successfully!"
            }
        }
    },
    components: {
        alert
    },
    validations: {
        applicant: {
            name: { required },
            address: { required },
            city: { required },
            state: { required },
            zipCode: { required },
            contactMethod: { required },
            email: { required, email },
            phoneNumber: { required },
            bestCallTime: { required },
        }
    },
    methods: {
        handleSubmitForm() {
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();

            // Scroll To Error
            this.$nextTick(() => {
                let element = document.querySelector('.form_field.error');
                if(element) {
                    let domRect = element.getBoundingClientRect();
                    window.scrollTo({
                        left: domRect.left + document.documentElement.scrollLeft,
                        top: domRect.top + (document.documentElement.scrollTop - 100),
                        behavior: 'smooth'
                    });
                }
            });

            if(!this.$v.$invalid) {
                this.loading = true;

                let data = {
                    clientName: this.applicant.name,
                    phoneNumber: this.applicant.phoneNumber,
                    fax: this.applicant.fax,
                    email: this.applicant.email,
                    address: this.applicant.address,
                    city: this.applicant.city,
                    state: this.applicant.state,
                    zipCode: this.applicant.zipCode,
                    contactMethod: this.applicant.contactMethod,
                    bestCallTime: this.applicant.bestCallTime,
                    callDate: this.applicant.callDate ? moment(this.applicant.callDate).format("MM/DD/YYYY") : null,
                    callTime: this.applicant.callTime,
                    comment: this.applicant.comment,
                    userAgent: getUserAgent(),
                    ip: getUserIp()
                };

                postNewHomeAssessment(data).then(() => {
                    this.alertData.showAlert = true;
                    this.loading = false;
                    this.submitted = false;
                    this.$router.push({
                        name: 'Confirmation'
                    });
                    this.applicant = {
                        state: "",
                        bestCallTime: ""
                    };
                    setTimeout(() => {
                        this.alertData.showAlert = false;
                    }, 3000);
                }).catch(errors => {
                    this.alertData = {
                        showAlert: true,
                        alertType: "danger",
                        alertText: errors.response.data.errors.email[0]
                    };
                    this.loading = false;
                    this.submitted = false;
                    setTimeout(() => {
                        this.alertData.showAlert = false;
                    }, 3000);
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./../../assets/css/forms.css";
</style>
