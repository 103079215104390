<template>
    <div>
        <!-- Banner -->
        <app-banner pageName="Privacy Policy"></app-banner>
        <!-- End Banner -->

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home."
                                class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span class="post post-page current-item">Privacy Policy</span>
                    </div>
                    <div id="post-16" class="post-16 page type-page status-publish hentry">
                        <div class="entry-content">
                            <p>
                                At AgingCare, we understand that privacy is important to you.
                                We are providing this Privacy Statement to help you understand how we may collect and use information.

                                <br><br>
                                General Data Protection Regulation (GDPR)
                                <br>
                                AgingCare has measures in place which meet the principles of data protection by design and data protection by default (per GDPR Article 25).
                                Additionally, we have verified that our vendors and partners are in compliance with the GDPR or plan to be in compliance before May 25, 2018.
                                If you have a GDPR question or concern or would like to receive a full copy of our GDPR addendum to this privacy policy,
                                please email us at <a href="mailto:admin@agingcarenet.com">admin@agingcarenet.com</a>
                            </p>
                            <p>
                                <strong>I. Introduction</strong>
                            </p>
                            <p>AgingCare (“Software provider”) takes your privacy seriously. AgingCare pledges to follow the provisions of this privacy policy in order to protect your privacy rights.</p>
                            <p>
                                We reserve the right to modify this policy at any time and will do so from time to time.
                                Each modification shall be effective upon an e-mail notification to all persons for whom information is collected.
                                Your failure to contact us and request that it discontinue the collection of your information following any such modification constitutes your acceptance of any changes to this Policy.
                                It is therefore important that you review this Policy regularly.
                                If you have any questions concerning this Policy, please contact us at <a href="mailto:admin@agingcarenet.com">admin@agingcarenet.com</a>
                            </p>
                            <p>Any capitalized term used but not defined herein shall have the meaning prescribed in the Terms of Use. In the event of a conflict between this Policy and the Terms of Use, then the Terms of Use shall control.</p>
                            <p>
                                AgingCare is a software and a website that facilitate communication, booking, transaction, marketing between customer and Business through Business website,
                                AgingCare DOES NOT SHARE BUSINESS S DATA, CUSTOMER DATA OR PERSONAL INFORMATION under any circumstances,
                                we are only a medium that facilitates communication and transaction between Business & their customer.
                            </p>

                            <p>
                                <strong>II. Types of Information Collected through Business websites.</strong>
                            </p>
                            <p>
                                Business s collect two types of information: Customer personal information and anonymous information. Additionally, we may use both types of information to create aggregate information. We collect the following categories of information:
                            </p>
                            <ul class="bullet">
                                <li>Information customer provide to Business  representative, including your first name and surname, gender, date of birth, email address, phone number, Facebook username, and Twitter Handle.</li>
                                <li>Transaction information you provide when paying for Business  service, including first name and surname, credit card number, and any other required payment information.</li>
                                <li>AgingCare  system does not store credit card information, credit cards processing take place through 3rd party processor gateway service.</li>
                            </ul>

                            <p>
                                <strong>III. How Business s Collect Your Information</strong>
                            </p>
                            <ul class="bullet">
                                <li>Business collect information you provide when you create a reservation, check in as part of a group, pay for items, or access Business website.</li>
                                <li>Business s collect information you provide to them when you request information from them, register, participate, respond to customer surveys.</li>
                                <li>Information you provide to Business s when you use Business s site and application or third-party sites or platforms such as social networking sites.</li>
                            </ul>

                            <p>
                                <strong>IV. Use of Your Information by Business s</strong>
                            </p>
                            <p>
                                Business s will be the data controller for your information. Other members of Business  may have access to your booking information where they perform services on behalf of the data controller and, unless prohibited under applicable law, for use on their own behalf for the following purposes:
                            </p>
                            <ul class="bullet">
                                <li>Provide you with the products, services, or information you request.</li>
                                <li>Communicate with you about your account or transactions with the Business  and send you information about changes.</li>
                                <li>Optimize or improve products, services, and operations.</li>
                                <li>Detect, investigate, and prevent activities that may violate policies or be illegal.</li>
                            </ul>

                            <p>
                                <strong>V. Sharing Your Information with Other Companies</strong>
                            </p>
                            <p>
                                As a white label Business  management software WE WILL NOT SHARE BUSINESS S DATA, CUSTOMER DATA OR PERSONAL INFORMATION under any circumstances, we are only a medium that facilitates communication and transaction between Business s & their customer.
                            </p>
                            <p>As a Business, when you allow us to share your personal information with another service provider, such as:</p>
                            <ul class="bullet">
                                <li>Directing us to join mass marketing service on your behalf to facilitate Email or SMS service marketing campaigns that are controlled and initiated by you as a Business.</li>
                            </ul>

                            <p>
                                Please note that once we share your personal information with another service provider, the information received becomes subject to the provide privacy practices, marketing companies are not permitted to use customer contacts by any means, they are only permitted to send marketing campaigns based on your action as a Business  operator using AgingCare marketing suite to send Business  promotion campaigns.
                            </p>
                            <ul class="bullet">
                                <li>With third parties in connection with the sale of a business, to enforce our Terms of Use, to ensure the safety and security of your customers, to protect our rights and property, to comply with legal process, or in other cases if we believe in good faith that disclosure is required by law</li>
                            </ul>

                            <p>
                                <strong>VI. Your Controls and Choices</strong>
                            </p>
                            <p>We provide Business s with the ability to exercise controls and choices regarding the use, and sharing of customer information. In accordance with local law, your controls and choices may include:</p>
                            <ul class="bullet">
                                <li>As a Business you may correct, update, and delete your account.</li>
                                <li>You may change your choices for alerts.</li>
                                <li>You may choose whether we share your personal information with other companies so they can send you offers and promotions about their products and services.</li>
                                <li>You may request access to the personal information we hold about you and that we amend or delete it and we request third parties with whom we have shared the information to do the same.</li>
                            </ul>
                            <p>You may exercise your controls and choices, or request access to your personal information, by contacting <a href="mailto:admin@agingcarenet.com">admin@agingcarenet.com</a> Please be aware that, if you do not allow us to collect personal information from you, some of our services may not be able to take account of your interests and preferences.</p>

                            <p>
                                <strong>VII. Data Security and Integrity</strong>
                            </p>
                            <p>The security, integrity, and confidentiality of your information are important to us. We have implemented security measures that are designed to protect your information from unauthorized access, disclosure, use, and modification. Please be aware though that, despite our best efforts, no security measures are perfect or impenetrable.</p>

                            <p>
                                <strong>VIII. Data Transfers, Storage, and Processing Globally</strong>
                            </p>
                            <p>We operate globally and may transfer your personal information to third parties in locations around the world for the purposes described in this privacy policy. Wherever your personal information is transferred, stored, or processed by us, we will take reasonable steps to safeguard the privacy of your personal information.</p>
                            <ul class="bullet">
                                <li>Access: The Business  will allow you “The Customer” to access your personal information and allow you to correct, amend, inaccurate information or unsubscribe from marketing campaigns.</li>
                            </ul>

                            <p>Access will not be allowed where the burden or expense of providing access would be disproportionate to the risks to your privacy or where personal rights other than your own would be violated.</p>
                            <ul class="bullet">
                                <li>Security: The Business shall take reasonable steps to protect personal information from loss, misuse, unauthorized access, disclosure, alteration, or destruction. The Business has in place appropriate physical, electronic, and managerial procedures to safeguard and secure personal information.</li>
                                <li>Data Integrity: The Business shall only process personal information in a way that is compatible with and relevant to the purpose for which it was collected or has been authorized. To the extent necessary for those purposes, The Business  shall take reasonable steps to ensure that personal information is accurate, complete, current, and reliable for its intended use.</li>
                            </ul>

                        </div>
                    </div>
                </main>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/components/layouts/banner'

export default {
    data() {
        return {

        }
    },
    components: {
        appBanner: banner,
    }
}
</script>
