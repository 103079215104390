<template>
    <div>
        <!-- Banner -->
        <app-banner pageName="Frequently Asked Questions (FAQ)"></app-banner>
        <!-- End Banner -->

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span class="post post-page current-item">Frequently Asked Questions (FAQ)</span>
                    </div>
                    <div id="post-10" class="post-10 page type-page status-publish hentry">
                        <div class="entry-content">
                            <p class="text-center">Below you'll find answers to the questions we get asked, Everything you need to know so you can use Aging Care like a pro.</p>
                            <div id="faq">

                                <badger-accordion>
                                    <badger-accordion-item v-for="(faq, index) in faqs" :key="index">
                                        <template slot="header">{{ faq.question }} <div class="badger-accordion__trigger-icon"></div></template>
                                        <template slot="content">{{ faq.answer }}</template>
                                    </badger-accordion-item>
                                </badger-accordion>

                            </div>
                        </div>
                    </div>
                </main>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { getFAQs } from '@/api/apiCalls'
import banner from '@/components/layouts/banner'
import { BadgerAccordion, BadgerAccordionItem } from 'vue-badger-accordion'

export default {
    components: {
        appBanner: banner,
        BadgerAccordion,
        BadgerAccordionItem
    },
    data () {
        return {
            faqs: []
        }
    },
    beforeMount() {
        getFAQs('website').then(({ data }) => {
            this.faqs = data
        })
    },
    created () {
        // this.getData()
    },
    methods: {
        getData () {
            getFAQs('website').then(({ data }) => {
                this.faqs = data
            })
        }
    }
}
</script>

<style>
#faq {
    margin-top: 40px;
}
.badger-accordion__panel {
    max-height: 75vh;
    overflow: hidden;
}
.badger-accordion__panel.-ba-is-hidden {
    max-height: 0 !important;
}
.badger-accordion--initialized .badger-accordion__panel {
    transition: max-height ease-in-out 0.2s;
}
.badger-accordion {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1), 0 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
}
.badger-accordion__header:not(:last-of-type) {
    border-bottom: 1px solid #EFF1F0;
}
.badger-accordion__header .js-badger-accordion-header .badger-accordion-toggle {
    flex: 0 0 100%!important;
    position: relative!important;
}
.js-badger-accordion-header {
    align-content: space-between;
    align-items: center;
    background-color: #fff;
    border: 0;
    border-radius: 0px;
    color: #34495e;
    display: flex;
    font-family: 'Josefin Sans', Arial;
    font-size: 1.25rem;
    line-height: 1;
    padding: 20px;
    text-align: left;
    transition: all ease-in-out 0.2s;
    width: 100%;
}
.js-badger-accordion-header.-ba-is-active {
    background: linear-gradient(90deg,#b6ca0a -11%,#649a15 103%);
    color: #fff;
}
.js-badger-accordion-header[aria-expanded=true] .badger-accordion__trigger-icon:before {
    -webkit-transform: rotate(45deg) translate3d(13px, 14px, 0);
    transform: rotate(45deg) translate3d(13px, 14px, 0);
}
.js-badger-accordion-header[aria-expanded=true] .badger-accordion__trigger-icon:after {
    -webkit-transform: rotate(-45deg) translate3d(-13px, 14px, 0);
    transform: rotate(-45deg) translate3d(-13px, 14px, 0);
}
.js-badger-accordion-header:focus,
.js-badger-accordion-header:active,
.js-badger-accordion-header:hover {
    /* background-color: #16a085; */
    background: linear-gradient(90deg,#b6ca0a -11%,#649a15 103%);
    cursor: pointer;
    outline: none;
    color: #fff;
}
.js-badger-accordion-header:focus .badger-accordion__trigger-title,
.js-badger-accordion-header:hover .badger-accordion__trigger-title {
    color: #fff;
}
.js-badger-accordion-header:focus .badger-accordion__trigger-icon:after,
.js-badger-accordion-header:focus .badger-accordion__trigger-icon:before,
.js-badger-accordion-header:hover .badger-accordion__trigger-icon:after,
.js-badger-accordion-header:hover .badger-accordion__trigger-icon:before,
.js-badger-accordion-header.-ba-is-active .badger-accordion__trigger-icon:before,
.js-badger-accordion-header.-ba-is-active .badger-accordion__trigger-icon:after {
    background-color: #fff;
}
.js-badger-accordion-header::-moz-focus-inner {
    border: none;
}
.badger-accordion__trigger-title {
    font-size: 1.2rem;
    transition: ease-in-out 0.3s;
}
.badger-accordion__trigger-icon {
    display: inline-block;
    height: 20px;
    margin-left: auto;
    position: relative;
    transition: all ease-in-out 0.2s;
    width: 40px;
    position: absolute;
    top: -10px;
    right: 0;
}
.badger-accordion__trigger-icon:after,
.badger-accordion__trigger-icon:before {
    background-color: #333;
    content: "";
    height: 3px;
    position: absolute;
    top: 10px;
    transition: all ease-in-out 0.1333333333s;
    width: 30px;
}
.badger-accordion__trigger-icon:before {
    left: 1px;
    -webkit-transform: rotate(45deg) translate3d(8px, 22px, 0);
            transform: rotate(45deg) translate3d(8px, 22px, 0);
    -webkit-transform-origin: 100%;
            transform-origin: 100%;
}
.badger-accordion__trigger-icon:after {
    -webkit-transform: rotate(-45deg) translate3d(-8px, 22px, 0);
            transform: rotate(-45deg) translate3d(-8px, 22px, 0);
    right: 1px;
    -webkit-transform-origin: 0;
            transform-origin: 0;
}
.badger-accordion__panel {
    background-color: #fafafa;
    position: relative;
}
.badger-accordion__panel:after {
    background-color: #EFF1F0;
    bottom: 0;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
}
.js-badger-accordion-panel-inner {
    padding: 20px 20px 40px;
    white-space: pre-wrap!important;
}
@media screen and (max-width: 767px) {
    .badger-accordion__trigger-icon {
        display: none;
        padding: 20px;
    }
}
</style>
