<template>
    <div>
        <!-- Banner -->
        <app-banner pageName="Watch Our Video"></app-banner>
        <!-- End Banner -->

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span class="post post-page current-item">Watch Our Video</span>
                    </div>
                    <div id="post-38" class="post-38 page type-page status-publish hentry">
                        <div class="entry-content">
                            <p>Learn more about how we can be of help to you or your loved ones through this video.</p>
                            <p>
                                <iframe src="https://www.youtube-nocookie.com/embed/fweDY-TeITk?rel=0&amp;showinfo=0" allowfullscreen></iframe><br />
                                <iframe src="https://www.youtube-nocookie.com/embed/CTl4z6117M8?rel=0&amp;showinfo=0" allowfullscreen></iframe><br />
                                <iframe src="https://www.youtube-nocookie.com/embed/_KKVQNZ6WLo?rel=0&amp;showinfo=0" allowfullscreen></iframe>
                            </p>
                        </div>
                    </div>
                </main>
                <div class="clearfix"></div>
            </div>
        </div>

    </div>
</template>

<script>
import banner from '@/components/layouts/banner'

export default {
    data() {
        return {

        }
    },
    components: {
        appBanner: banner,
    }
}
</script>

<style scoped>
iframe {
    border: none;
    width: 100%;
    height: 450px;
    margin: 10px auto;
}


@media only screen and (max-width : 600px) {
    iframe {
        height: 300px;
    }
}
</style>
