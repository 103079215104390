<template>
    <div class="clearfix">

        <alert :alertData="alertData"/>

        <div class="wrapper">
            <div id="contact_us_form_1" class="template_form">
                <div class="form_frame_b">
                    <div class="form_content">
                        <form id="submitform" name="contact" @submit.prevent="handleSubmitForm">
                            <span class="required-info">* Required Information</span>
                            <div class="form_box">
                                <div class="form_box_col2">
                                    <div class="group">
                                        <div class="form_label">
                                            <label for="Name" class="text_uppercase">
                                                <strong>Full Name
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" type="text" v-model="applicant.clientName" :class="{ 'error': submitted && $v.applicant.clientName.$error }" name="Name" class="form_field" id="Name" placeholder="Enter name here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>

                                    <div class="group">
                                        <div class="form_label">
                                            <label for="Position" class="text_uppercase">
                                                <strong>Desired Position
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" type="text" v-model="applicant.position" :class="{ 'error': submitted && $v.applicant.position.$error }" name="Position" class="form_field" id="Position" placeholder="Enter Desired Position">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form_box">
                                <div class="form_box_col2">
                                    <div class="group">
                                        <div class="form_label">
                                            <label for="Address" class="text_uppercase">
                                                <strong>Address
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" type="text" v-model="applicant.address" :class="{ 'error': submitted && $v.applicant.address.$error }" name="Address" class="form_field" id="Address" placeholder="Enter address here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                    <div class="group">
                                        <div class="form_label">
                                            <label for="City" class="text_uppercase">
                                                <strong>City
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" type="text" v-model="applicant.city" :class="{ 'error': submitted && $v.applicant.city.$error }" name="City" class="form_field" id="City" placeholder="Enter city here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form_box">
                                <div class="form_box_col2">
                                    <div class="group">
                                        <div class="form_label">
                                            <label for="State" class="text_uppercase">
                                                <strong>State
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="select-field">
                                            <select v-model="applicant.state" id="State" :class="{ 'error': submitted && $v.applicant.state.$error }" name="State" class="form_field">
                                                <option value="">Please select state.</option>
                                                <option value="Alabama">Alabama</option>
                                                <option value="Alaska">Alaska</option>
                                                <option value="Arizona">Arizona</option>
                                                <option value="Arkansas">Arkansas</option>
                                                <option value="California">California</option>
                                                <option value="Colorado">Colorado</option>
                                                <option value="Connecticut">Connecticut</option>
                                                <option value="Delaware">Delaware</option>
                                                <option value="District Of Columbia">District Of Columbia</option>
                                                <option value="Florida">Florida</option>
                                                <option value="Georgia">Georgia</option>
                                                <option value="Hawaii">Hawaii</option>
                                                <option value="Idaho">Idaho</option>
                                                <option value="Illinois">Illinois</option>
                                                <option value="Indiana">Indiana</option>
                                                <option value="Iowa">Iowa</option>
                                                <option value="Kansas">Kansas</option>
                                                <option value="Kentucky">Kentucky</option>
                                                <option value="Louisiana">Louisiana</option>
                                                <option value="Maine">Maine</option>
                                                <option value="Maryland">Maryland</option>
                                                <option value="Massachusetts">Massachusetts</option>
                                                <option value="Michigan">Michigan</option>
                                                <option value="Minnesota">Minnesota</option>
                                                <option value="Mississippi">Mississippi</option>
                                                <option value="Missouri">Missouri</option>
                                                <option value="Montana">Montana</option>
                                                <option value="Nebraska">Nebraska</option>
                                                <option value="Nevada">Nevada</option>
                                                <option value="New Hampshire">New Hampshire</option>
                                                <option value="New Jersey">New Jersey</option>
                                                <option value="New Mexico">New Mexico</option>
                                                <option value="New York">New York</option>
                                                <option value="North Carolina">North Carolina</option>
                                                <option value="North Dakota">North Dakota</option>
                                                <option value="Ohio">Ohio</option>
                                                <option value="Oklahoma">Oklahoma</option>
                                                <option value="Oregon">Oregon</option>
                                                <option value="Pennsylvania">Pennsylvania</option>
                                                <option value="Puerto Rico">Puerto Rico</option>
                                                <option value="Rhode Island">Rhode Island</option>
                                                <option value="South Carolina">South Carolina</option>
                                                <option value="South Dakota">South Dakota</option>
                                                <option value="Tennessee">Tennessee</option>
                                                <option value="Texas">Texas</option>
                                                <option value="Utah">Utah</option>
                                                <option value="Vermont">Vermont</option>
                                                <option value="Virgin Islands">Virgin Islands</option>
                                                <option value="Virginia">Virginia</option>
                                                <option value="Washington">Washington</option>
                                                <option value="West Virginia">West Virginia</option>
                                                <option value="Wisconsin">Wisconsin</option>
                                                <option value="Wyoming">Wyoming</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="group">
                                        <div class="form_label">
                                            <label for="zipCode" class="text_uppercase">
                                                <strong>Zip Code
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" type="text" v-model="applicant.zipCode" :class="{ 'error': submitted && $v.applicant.zipCode.$error }" name="zipCode" class="form_field" id="zipCode" placeholder="Enter zip code here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form_box">
                                <div class="form_box_col2">
                                    <div class="group">
                                        <div class="form_label">
                                            <label for="Phone_Day" class="text_uppercase">
                                                <strong>Phone no.
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" type="text" v-model="applicant.phoneNumber" :class="{ 'error': submitted && $v.applicant.phoneNumber.$error }" name="Phone_Day" class="form_field" id="Phone_Day" placeholder="Enter phone day here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                    <div class="group">
                                        <div class="form_label">
                                            <label for="Email" class="text_uppercase">
                                                <strong>Email Address
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" type="text" v-model="applicant.email" :class="{ 'error': submitted && $v.applicant.email.$error }" name="Email" class="form_field" id="Email" placeholder="Enter email address here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>

                                    <div class="form_box">
                                        <div class="form_box_col1">
                                            <div class="group">
                                                <table class="radio" border="0" cellpadding="0" cellspacing="0">
                                                    <tr>
                                                        <th>
                                                            <div class="form_label">
                                                    <div class="text_uppercase">
                                                        <strong>Are you currently working with a client?
                                                            <span class="required_filed">*</span>
                                                        </strong>
                                                    </div>
                                                </div>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input autocomplete="nope" type="radio" :class="{ error: submitted && $v.applicant.workingWithClient.$error }" v-model="applicant.workingWithClient" name="avaulable_" :value="true" id="avaulable_0">
                                                            <label for="avaulable_0" style="font-weight:normal; color:#000;">Yes</label>
                                                        </td>
                                                        <td>
                                                            <input autocomplete="nope" type="radio" :class="{ error: submitted && $v.applicant.workingWithClient.$error }" v-model="applicant.workingWithClient" name="avaulable_" :value="false" id="avaulable_1">
                                                            <label for="avaulable_1" style="font-weight:normal; color:#000;">No</label>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mc-field-group">
                                        <div class="form_box_col1">
                                            <div class="group">
                                                <div class="form_label">
                                                    <label for="mce-hearAboutUs" class="text_uppercase">
                                                        <strong>How did you hear about us?</strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input
                                                        type="text"
                                                        v-model="applicant.hearAboutUs"
                                                        name="hearAboutUs"
                                                        class="form_field"
                                                        autocomplete="nope"
                                                        :class="{ error: submitted && $v.applicant.hearAboutUs.$error }"
                                                        id="mce-hearAboutUs"
                                                    />
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mc-field-group">
                                        <div class="form_box_col1">
                                            <div class="group">
                                                <div class="form_label">
                                                    <label for="hireYou" class="text_uppercase">
                                                        <strong>Why should we hire you?
                                                            <span class="required_filed">*</span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <textarea
                                                        v-model="applicant.request"
                                                        name="Tell_us_more_why_you_are_completing_this_form"
                                                        class="form_field"
                                                        id="hireYou"
                                                        :class="{ 'error': submitted && $v.applicant.request.$error }"
                                                        placeholder="Enter here">
                                                    </textarea>
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="form_box">
                                <div class="form_box_col1">
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Email Address
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" type="text" v-model="applicant.email" :class="{ 'error': submitted && $v.applicant.email.$error }" name="Email_Address" class="form_field" id="Email_Address" placeholder="Enter email address here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <!-- <div class="form_box">
                                <div class="form_box_col1">
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>What License Do You Currently Hold?
                                                    <span class="required_filed"></span>
                                                </strong>
                                            </label>
                                        </div>
                                        <table class="radio" border="0" cellpadding="0" cellspacing="0">
                                            <tr></tr>
                                            <tr>
                                                <td class="checkbox-container checkbox-block-label">
                                                    <input autocomplete="nope" type="checkbox" v-model="applicant.license" name="What_License_Do_You_Currently_Hold" value="HHA" id="What_License_Do_You_Currently_Hold0">
                                                    <label for="What_License_Do_You_Currently_Hold0" style="font-weight:normal; color:#000;">HHA</label>
                                                </td>
                                                <td class="checkbox-container checkbox-block-label">
                                                    <input autocomplete="nope" type="checkbox" v-model="applicant.license" name="What_License_Do_You_Currently_Hold" value="LPN" id="What_License_Do_You_Currently_Hold1">
                                                    <label for="What_License_Do_You_Currently_Hold1" style="font-weight:normal; color:#000;">LPN</label>
                                                </td>
                                                <td class="checkbox-container checkbox-block-label">
                                                    <input autocomplete="nope" type="checkbox" v-model="applicant.license" name="What_License_Do_You_Currently_Hold" value="RN" id="What_License_Do_You_Currently_Hold2">
                                                    <label for="What_License_Do_You_Currently_Hold2" style="font-weight:normal; color:#000;">RN</label>
                                                </td>
                                                <td class="checkbox-container checkbox-block-label">
                                                    <input autocomplete="nope" type="checkbox" v-model="applicant.license" name="What_License_Do_You_Currently_Hold" value="CNA" id="What_License_Do_You_Currently_Hold3">
                                                    <label for="What_License_Do_You_Currently_Hold3" style="font-weight:normal; color:#000;">CNA</label>
                                                </td>
                                                <td class="checkbox-container checkbox-block-label">
                                                    <input autocomplete="nope" type="checkbox" v-model="applicant.license" name="What_License_Do_You_Currently_Hold" value="None" id="What_License_Do_You_Currently_Hold4">
                                                    <label for="What_License_Do_You_Currently_Hold4" style="font-weight:normal; color:#000;">None</label>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="form_box">
								<div class="form_box_col1">
									<div class="group">
										<div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>What positions are you seeking for?
                                                    <span class="required_filed"></span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" type="text" v-model="applicant.position" name="What_positions_are_you_seeking_for" class="form_field" id="What_positions_are_you_seeking_for" placeholder="Enter position here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
								</div>
							</div>
                            <div class="form_box">
                                <div class="form_box_col1">
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Are you over 18?
                                                    <span class="required_filed"></span>
                                                </strong>
                                            </label>
                                        </div>
                                        <table class="radio" border="0" cellpadding="0" cellspacing="0">
                                            <tr></tr>
                                            <tr>
                                                <td>
                                                    <input autocomplete="nope" type="radio" v-model="applicant.over18" name="Are_you_over_18" :value="true" id="Are_you_over_180">
                                                    <label for="Are_you_over_180" style="font-weight:normal; color:#000;">Yes</label>
                                                </td>
                                                <td>
                                                    <input autocomplete="nope" type="radio" v-model="applicant.over18" name="Are_you_over_18" :value="false" id="Are_you_over_181">
                                                    <label for="Are_you_over_181" style="font-weight:normal; color:#000;">No</label>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="form_box">
                                <div class="form_box_col2">
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Do you have a driver's license?
                                                    <span class="required_filed"></span>
                                                </strong>
                                            </label>
                                        </div>
                                        <table class="radio" border="0" cellpadding="0" cellspacing="0">
                                            <tr></tr>
                                            <tr>
                                                <td>
                                                    <input autocomplete="nope" type="radio" v-model="applicant.driverLicense" name="Do_you_have_a_driver's_license?" :value="true" id="Do_you_have_a_driver's_license?0">
                                                    <label for="Do_you_have_a_driver's_license?0" style="font-weight:normal; color:#000;">Yes</label>
                                                </td>
                                                <td>
                                                    <input autocomplete="nope" type="radio" v-model="applicant.driverLicense" name="Do_you_have_a_driver's_license?" :value="false" id="Do_you_have_a_driver's_license?1">
                                                    <label for="Do_you_have_a_driver's_license?1" style="font-weight:normal; color:#000;">No</label>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Do you own a car?
                                                    <span class="required_filed"></span>
                                                </strong>
                                            </label>
                                        </div>
                                        <table class="radio" border="0" cellpadding="0" cellspacing="0">
                                            <tr></tr>
                                            <tr>
                                                <td>
                                                    <input autocomplete="nope" type="radio" v-model="applicant.haveCar" name="Do_you_own_a_car?" :value="true" id="Do_you_own_a_car?0">
                                                    <label for="Do_you_own_a_car?0" style="font-weight:normal; color:#000;">Yes</label>
                                                </td>
                                                <td>
                                                    <input autocomplete="nope" type="radio" v-model="applicant.haveCar" name="Do_you_own_a_car?" :value="false" id="Do_you_own_a_car?1">
                                                    <label for="Do_you_own_a_car?1" style="font-weight:normal; color:#000;">No</label>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="form_box">
                                <div class="form_box_col1">
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>What shift would you prefer?
                                                    <span class="required_filed"></span>
                                                </strong>
                                            </label>
                                        </div>
                                        <table class="radio" border="0" cellpadding="0" cellspacing="0">
                                            <tr></tr>
                                            <tr>
                                                <td class="checkbox-container checkbox-block-label">
                                                    <input autocomplete="nope" type="checkbox" v-model="applicant.shiftPeriod" name="What_shift_would_you_prefer" value="Days" id="What_shift_would_you_prefer0">
                                                    <label for="What_shift_would_you_prefer0" style="font-weight:normal; color:#000;">Days</label>
                                                </td>
                                                <td class="checkbox-container checkbox-block-label">
                                                    <input autocomplete="nope" type="checkbox" v-model="applicant.shiftPeriod" name="What_shift_would_you_prefer" value="PM" id="What_shift_would_you_prefer1">
                                                    <label for="What_shift_would_you_prefer1" style="font-weight:normal; color:#000;">PM</label>
                                                </td>
                                                <td class="checkbox-container checkbox-block-label">
                                                    <input autocomplete="nope" type="checkbox" v-model="applicant.shiftPeriod" name="What_shift_would_you_prefer" value="Nights" id="What_shift_would_you_prefer2">
                                                    <label for="What_shift_would_you_prefer2" style="font-weight:normal; color:#000;">Nights</label>
                                                </td>
                                                <td class="checkbox-container checkbox-block-label">
                                                    <input autocomplete="nope" type="checkbox" v-model="applicant.shiftPeriod" name="What_shift_would_you_prefer" value="Live-in" id="What_shift_would_you_prefer3">
                                                    <label for="What_shift_would_you_prefer3" style="font-weight:normal; color:#000;">Live-in</label>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="form_box">
                                <div class="form_box_col1">
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Previous Experience
                                                    <span class="required_filed"></span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <textarea v-model="applicant.experience" name="Previous_Experience" class="text form_field" id="Previous_Experience" placeholder="Enter here"></textarea>
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form_box">
                                <div class="form_box_col1">
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>How did you hear about us?
                                                    <span class="required_filed"></span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" type="text" v-model="applicant.hearAboutUs" name="How_did_you_hear_about_us" class="form_field" id="How_did_you_hear_about_us" placeholder="Enter how you heard about us">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form_box">
                                <div class="form_box_col1">
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Attach Resume
                                                    <span class="required_filed"></span>
                                                </strong>
                                            </label>
                                        </div>
                                        <input autocomplete="nope" type="file" name="attachment[]" id="file" class="input-file" multiple @change="uploadResume($event)">
                                        <label for="file" class="btn btn-tertiary js-labelFile">
                                            <span class="">{{ resumeName ? resumeName : 'Choose a file' }}</span>
                                            <span class="icon"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form_box">
								<div class="form_box_col1">
									<div class="group">
										<div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Tell us more why you are completing this form
                                                    <span class="required_filed"></span>
                                                </strong>
                                            </label>
                                        </div>
										<div class="field_holder animated_box">
                                            <textarea v-model="applicant.request" name="Tell_us_more_why_you_are_completing_this_form" class="form_field" placeholder="Enter here"></textarea>
                                            <span class="animated_class"></span>
                                        </div>
									</div>
								</div>
							</div> -->

                            <div class="form_box5 secode_box text-center">
                                <div class="group">
                                    <div class="inner_form_box1 recapBtn">
                                        <div class="btn-submit">
                                            <button type="submit" class="button form_button" :disabled="loading">SUBMIT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { postNewStaff } from '@/api/apiCalls'
import alert from '@/components/layouts/alert'
import { getUserAgent, getUserIp } from '@/helpers'
import { required, email } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            submitted: false,
            loading: false,
            resume: '',
            resumeName: '',
            attachments: [],
            applicant: {
                state: '',
                license: [],
                shiftPeriod: [],
                hearAboutUs: null,
            },
            alertData: {
                showAlert: false,
                alertType: 'success',
                alertText: 'Data Added Successfully!',
            }
        }
    },
    validations: {
        applicant: {
            clientName: { required },
            address: { required },
            city: { required },
            state: { required },
            zipCode: { required },
            phoneNumber: { required },
            email: { required, email },
            position: { required },
            request: { required },
            workingWithClient: { required },
            hearAboutUs: {},
        }
    },
    components: {
        alert
    },
    methods: {
        uploadResume(event) {
            const files = event.target.files;
            this.attachments = files;
            this.resumeName = files[0].name;

            var reader = new FileReader;
            reader.addEventListener('load', () => {
                this.resume = reader.result;
            });
            reader.readAsDataURL(files[0]);
        },
        handleSubmitForm() {
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();

            // Scroll To Error
            this.$nextTick(() => {
                let element = document.querySelector('.form_field.error');
                if(element) {
                    let domRect = element.getBoundingClientRect();
                    window.scrollTo({
                        left: domRect.left + document.documentElement.scrollLeft,
                        top: domRect.top + (document.documentElement.scrollTop - 100),
                        behavior: 'smooth'
                    });
                }
            });

            if(!this.$v.$invalid) {
                this.loading = true;

                let data = {
					address: this.applicant.address,
                    city: this.applicant.city,
                    clientName: this.applicant.clientName,
                    driverLicense: this.applicant.driverLicense,
                    email: this.applicant.email,
                    experience: this.applicant.experience,
                    haveCar: this.applicant.haveCar,
                    hearAboutUs: this.applicant.hearAboutUs,
                    license: this.applicant.license,
                    position: this.applicant.position,
                    over18: this.applicant.over18,
                    phoneEvening: this.applicant.phoneEvening,
                    phoneNumber: this.applicant.phoneNumber,
                    request: this.applicant.request,
                    shiftPeriod: this.applicant.shiftPeriod,
                    state: this.applicant.state,
                    zipCode: this.applicant.zipCode,
                    resume: this.resume,
                    resumeName: this.resumeName,
					userAgent: getUserAgent(),
					ip: getUserIp()
                };

                postNewStaff(data).then(() => {
                    this.alertData.showAlert = true;
                    this.loading = false;
                    this.submitted = false;
                    this.$router.push({
                        name: 'Confirmation'
                    });
                    this.applicant = {
                        state: "",
                        license: [],
                        shiftPeriod: []
                    };
                    this.resumeName = "";
                    setTimeout(() => {
                        this.alertData.showAlert = false;
                    }, 3000);
                }).catch(() => {
                    this.alertData = {
                        showAlert: true,
                        alertType: "danger",
                        alertText: "Something Went Wrong!"
                    };
                    this.loading = false;
                    this.submitted = false;
                    setTimeout(() => {
                        this.alertData.showAlert = false;
                    }, 3000);
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./../../assets/css/forms.css";
</style>