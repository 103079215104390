<template>
    <div>
        <!-- Banner -->
        <app-banner pageName="Confirmation"></app-banner>
        <!-- End Banner -->

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span class="post post-page current-item">Confirmation</span>
                    </div>
                    <div id="post-10" class="post-10 page type-page status-publish hentry">
                        <div class="entry-content text-center confirm-text">
                            <h3>Your Application received,</h3>
                            <h3>Thank you for your submission.</h3>
                            <h3>Will get back to you as soon as possible</h3>
                        </div>
                    </div>
                </main>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/components/layouts/banner'

export default {
    components: {
        appBanner: banner
    }
}
</script>
