<template>
    <div class="clearfix">
        <div class="wrapper">
            <div id="contact_us_form_1" class="template_form">
                <div class="form_frame_b">
                    <div class="form_content applicant-documents">
                        <form id="submitform" @submit.prevent="handleSubmitForm" name="contact">

                            <alert :alertData="alertData"/>

                            <span class="required-info">* Required Information</span>

                            <!-- Applicant Information -->
                            <div class="main fieldbox">
                                <div class="fieldbox">
                                    <div class="fieldheader">
                                        <p>
                                            <i class="fa fa-info-circle"></i> Applicant Documents
                                        </p>
                                    </div>
                                    <div class="fieldcontent">

                                        <div :class="[ items.includes('PPD') && items.includes('CriminalBackground') ? 'form_box_col2' : 'form_box_col1' ]">

                                            <div class="group" v-show="items.includes('PPD')">
                                                <label for="file" class="btn btn-tertiary js-labelFile">
                                                    <input
                                                        id="PPD"
                                                        type="hidden"
                                                        data-clearable
                                                        data-tabs="all"
                                                        data-images-only="true"
                                                        data-preview-step="true"
                                                        role="uploadcare-uploader"
                                                        name="ghost-vertical-uploader"
                                                        :data-public-key="uploadCareAPI"
                                                        data-btn-text="Upload PPD"
                                                        data-effects="crop, flip, enhance, grayscale, blur, rotate, mirror, sharp, invert"
                                                    />
                                                    <span class="image-upload-preview PPD-preview"></span>
                                                    <span class="icon"></span>
                                                </label>
                                            </div>

                                            <div class="group" v-show="items.includes('CriminalBackground')">
                                                <label for="file" class="btn btn-tertiary js-labelFile">
                                                    <input
                                                        id="CriminalBackground"
                                                        type="hidden"
                                                        data-clearable
                                                        data-tabs="all"
                                                        data-images-only="true"
                                                        data-preview-step="true"
                                                        role="uploadcare-uploader"
                                                        name="ghost-vertical-uploader"
                                                        :data-public-key="uploadCareAPI"
                                                        data-btn-text="Upload Criminal Background"
                                                        data-effects="crop, flip, enhance, grayscale, blur, rotate, mirror, sharp, invert"
                                                    />
                                                    <span class="image-upload-preview CriminalBackground-preview"></span>
                                                    <span class="icon"></span>
                                                </label>
                                            </div>

                                        </div>

                                        <div :class="[ items.includes('StateID') && items.includes('ProofOfAddress') ? 'form_box_col2' : 'form_box_col1' ]">

                                            <div class="group" v-show="items.includes('StateID')">
                                                <label for="file" class="btn btn-tertiary js-labelFile">
                                                    <input
                                                        id="StateID"
                                                        type="hidden"
                                                        data-clearable
                                                        data-tabs="all"
                                                        data-images-only="true"
                                                        data-preview-step="true"
                                                        role="uploadcare-uploader"
                                                        name="ghost-vertical-uploader"
                                                        :data-public-key="uploadCareAPI"
                                                        data-btn-text="Upload State ID"
                                                        data-effects="crop, flip, enhance, grayscale, blur, rotate, mirror, sharp, invert"
                                                    />
                                                    <span class="image-upload-preview StateID-preview"></span>
                                                    <span class="icon"></span>
                                                </label>
                                            </div>

                                            <div class="group" v-show="items.includes('ProofOfAddress')">
                                                <label for="file" class="btn btn-tertiary js-labelFile">
                                                    <input
                                                        id="ProofOfAddress"
                                                        type="hidden"
                                                        data-clearable
                                                        data-tabs="all"
                                                        data-images-only="true"
                                                        data-preview-step="true"
                                                        role="uploadcare-uploader"
                                                        name="ghost-vertical-uploader"
                                                        :data-public-key="uploadCareAPI"
                                                        data-btn-text="Upload Proof Of Address"
                                                        data-effects="crop, flip, enhance, grayscale, blur, rotate, mirror, sharp, invert"
                                                    />
                                                    <span class="image-upload-preview ProofOfAddress-preview"></span>
                                                    <span class="icon"></span>
                                                </label>
                                            </div>

                                        </div>

                                        <div :class="[ items.includes('Physical') && items.includes('ChestXRay') ? 'form_box_col2' : 'form_box_col1' ]">

                                            <div class="group" v-show="items.includes('Physical')">
                                                <label for="file" class="btn btn-tertiary js-labelFile">
                                                    <input
                                                        id="Physical"
                                                        type="hidden"
                                                        data-clearable
                                                        data-tabs="all"
                                                        data-images-only="true"
                                                        data-preview-step="true"
                                                        role="uploadcare-uploader"
                                                        name="ghost-vertical-uploader"
                                                        :data-public-key="uploadCareAPI"
                                                        data-btn-text="Upload Physical"
                                                        data-effects="crop, flip, enhance, grayscale, blur, rotate, mirror, sharp, invert"
                                                    />
                                                    <span class="image-upload-preview Physical-preview"></span>
                                                    <span class="icon"></span>
                                                </label>
                                            </div>

                                            <div class="group" v-show="items.includes('ChestXRay')">
                                                <label for="file" class="btn btn-tertiary js-labelFile">
                                                    <input
                                                        id="ChestXRay"
                                                        type="hidden"
                                                        data-clearable
                                                        data-tabs="all"
                                                        data-images-only="true"
                                                        data-preview-step="true"
                                                        role="uploadcare-uploader"
                                                        name="ghost-vertical-uploader"
                                                        :data-public-key="uploadCareAPI"
                                                        data-btn-text="Upload Chest X-ray"
                                                        data-effects="crop, flip, enhance, grayscale, blur, rotate, mirror, sharp, invert"
                                                    />
                                                    <span class="image-upload-preview ChestXRay-preview"></span>
                                                    <span class="icon"></span>
                                                </label>
                                            </div>

                                        </div>

                                        <div :class="[ items.includes('EnrollmentAgreement') && items.includes('PerformanceChecklist') ? 'form_box_col2' : 'form_box_col1' ]">

                                            <div class="group" v-show="items.includes('EnrollmentAgreement')">
                                                <label for="file" class="btn btn-tertiary js-labelFile">
                                                    <input
                                                        id="EnrollmentAgreement"
                                                        type="hidden"
                                                        data-clearable
                                                        data-tabs="all"
                                                        data-images-only="true"
                                                        data-preview-step="true"
                                                        role="uploadcare-uploader"
                                                        name="ghost-vertical-uploader"
                                                        :data-public-key="uploadCareAPI"
                                                        data-btn-text="Upload Enrollment Agreement"
                                                        data-effects="crop, flip, enhance, grayscale, blur, rotate, mirror, sharp, invert"
                                                    />
                                                    <span class="image-upload-preview EnrollmentAgreement-preview"></span>
                                                    <span class="icon"></span>
                                                </label>
                                            </div>

                                            <div class="group" v-show="items.includes('PerformanceChecklist')">
                                                <label for="file" class="btn btn-tertiary js-labelFile">
                                                    <input
                                                        id="PerformanceChecklist"
                                                        type="hidden"
                                                        data-clearable
                                                        data-tabs="all"
                                                        data-images-only="true"
                                                        data-preview-step="true"
                                                        role="uploadcare-uploader"
                                                        name="ghost-vertical-uploader"
                                                        :data-public-key="uploadCareAPI"
                                                        data-btn-text="Upload Performance Checklist"
                                                        data-effects="crop, flip, enhance, grayscale, blur, rotate, mirror, sharp, invert"
                                                    />
                                                    <span class="image-upload-preview PerformanceChecklist-preview"></span>
                                                    <span class="icon"></span>
                                                </label>
                                            </div>

                                        </div>

                                        <div :class="[ items.includes('ClinicalEval') && items.includes('AbuseQuiz') ? 'form_box_col2' : 'form_box_col1' ]">

                                            <div class="group" v-show="items.includes('ClinicalEval')">
                                                <label for="file" class="btn btn-tertiary js-labelFile">
                                                    <input
                                                        id="ClinicalEval"
                                                        type="hidden"
                                                        data-clearable
                                                        data-tabs="all"
                                                        data-images-only="true"
                                                        data-preview-step="true"
                                                        role="uploadcare-uploader"
                                                        name="ghost-vertical-uploader"
                                                        :data-public-key="uploadCareAPI"
                                                        data-btn-text="Upload Clinical Eval"
                                                        data-effects="crop, flip, enhance, grayscale, blur, rotate, mirror, sharp, invert"
                                                    />
                                                    <span class="image-upload-preview ClinicalEval-preview"></span>
                                                    <span class="icon"></span>
                                                </label>
                                            </div>

                                            <div class="group" v-show="items.includes('AbuseQuiz')">
                                                <label for="file" class="btn btn-tertiary js-labelFile">
                                                    <input
                                                        id="AbuseQuiz"
                                                        type="hidden"
                                                        data-clearable
                                                        data-tabs="all"
                                                        data-images-only="true"
                                                        data-preview-step="true"
                                                        role="uploadcare-uploader"
                                                        name="ghost-vertical-uploader"
                                                        :data-public-key="uploadCareAPI"
                                                        data-btn-text="Upload Abuse Quiz"
                                                        data-effects="crop, flip, enhance, grayscale, blur, rotate, mirror, sharp, invert"
                                                    />
                                                    <span class="image-upload-preview AbuseQuiz-preview"></span>
                                                    <span class="icon"></span>
                                                </label>
                                            </div>

                                        </div>

                                        <div :class="[ items.includes('ClinicalCharting') && items.includes('Certificate') ? 'form_box_col2' : 'form_box_col1' ]">

                                            <div class="group" v-show="items.includes('ClinicalCharting')">
                                                <label for="file" class="btn btn-tertiary js-labelFile">
                                                    <input
                                                        id="ClinicalCharting"
                                                        type="hidden"
                                                        data-clearable
                                                        data-tabs="all"
                                                        data-images-only="true"
                                                        data-preview-step="true"
                                                        role="uploadcare-uploader"
                                                        name="ghost-vertical-uploader"
                                                        :data-public-key="uploadCareAPI"
                                                        data-btn-text="Upload Clinical Charting"
                                                        data-effects="crop, flip, enhance, grayscale, blur, rotate, mirror, sharp, invert"
                                                    />
                                                    <span class="image-upload-preview ClinicalCharting-preview"></span>
                                                    <span class="icon"></span>
                                                </label>
                                            </div>

                                            <div class="group" v-show="items.includes('Certificate')">
                                                <label for="file" class="btn btn-tertiary js-labelFile">
                                                    <input
                                                        id="Certificate"
                                                        type="hidden"
                                                        data-clearable
                                                        data-tabs="all"
                                                        data-images-only="true"
                                                        data-preview-step="true"
                                                        role="uploadcare-uploader"
                                                        name="ghost-vertical-uploader"
                                                        :data-public-key="uploadCareAPI"
                                                        data-btn-text="Upload Certificate"
                                                        data-effects="crop, flip, enhance, grayscale, blur, rotate, mirror, sharp, invert"
                                                    />
                                                    <span class="image-upload-preview Certificate-preview"></span>
                                                    <span class="icon"></span>
                                                </label>
                                            </div>

                                        </div>

                                        <div class="form_box_col1">
                                            <div class="group" v-show="items.includes('Quantiferon')">
                                                <label for="file" class="btn btn-tertiary js-labelFile">
                                                    <input
                                                        id="Quantiferon"
                                                        type="hidden"
                                                        data-clearable
                                                        data-tabs="all"
                                                        data-images-only="true"
                                                        data-preview-step="true"
                                                        role="uploadcare-uploader"
                                                        name="ghost-vertical-uploader"
                                                        :data-public-key="uploadCareAPI"
                                                        data-btn-text="Upload PPD / Quantiferon / Chest x-ray"
                                                        data-effects="crop, flip, enhance, grayscale, blur, rotate, mirror, sharp, invert"
                                                    />
                                                    <span class="image-upload-preview Quantiferon-preview"></span>
                                                    <span class="icon"></span>
                                                </label>
                                            </div>
                                        </div>

                                        <div class="form_box5 secode_box text-center" style="margin-top: 10px;">
                                            <div class="inner_form_box1 recapBtn">
                                                <div class="btn-submit">
                                                    <button type="submit" class="button form_button" :disabled="loading">SUBMIT</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import alert from '@/components/layouts/alert'
import { postNurseAideTrainingDocuments } from '@/api/apiCalls'

export default {
    props: ['hash'],
    data() {
        return {
            items: [
                'PPD',
                'CriminalBackground',
                'StateID',
                'ProofOfAddress',
                'Physical',
                'ChestXRay',
                'EnrollmentAgreement',
                'PerformanceChecklist',
                'ClinicalEval',
                'AbuseQuiz',
                'ClinicalCharting',
                'Certificate',
                'Quantiferon'
            ],
            documents: {},
            loading: false,
            alertData: {
                showAlert: false,
                alertType: 'success',
                alertText: 'Data Added Successfully!'
            },
        }
    },
    validations: {},
    mounted () {
		const _vm = this

        this.items = this.$route.query && this.$route.query.items ? this.$route.query.items.split(',') : [
            'PPD',
            'CriminalBackground',
            'StateID',
            'ProofOfAddress',
            'Physical',
            'ChestXRay',
            'EnrollmentAgreement',
            'PerformanceChecklist',
            'ClinicalEval',
            'AbuseQuiz',
            'ClinicalCharting',
            'Certificate',
            'Quantiferon'
        ]

        if (this.items) {
            Array.from(this.items).forEach(element => {
                _vm.initUploadcare(`#${element}`)
            })
        }
	},
    components: {
        alert
    },
    methods: {
        handleSubmitForm() {
            this.$v.$touch()

            if (!this.$v.$invalid) {
                this.loading = true

                postNurseAideTrainingDocuments({
                    hash: this.hash,
                    documents: this.documents,
                }).then(() => {
					this.$scrollTo('#contact_us_form_1', 300)
                    this.alertData.showAlert = true

                    this.$router.push({
                        name: 'Confirmation'
                    })
                }).catch(({ response }) => {
					this.alertData = {
                        showAlert: true,
                        alertType: 'danger',
                        alertText: response.data.message || 'Something Went Wrong!'
                    }

                    setTimeout(() => {
                        this.alertData.showAlert = false
                    }, 3000)
                }).finally(() => {
                    this.loading = false
                })
            }
        }
    }
}
</script>

<style>
body {
    font-family: 'Muli', sans-serif;
}
</style>

<style lang="scss" scoped>
.form_head {border-radius: 10px; }
.form_head p.title_head:nth-child(1) { background: #ff3f3f;  margin: 0;  padding: 10px;  color: #fff;  font-weight: bold;  border-top-right-radius: 8px;  border-top-left-radius: 8px;}
.form_head .form_box .form_box_col1 p { margin-bottom: 4px; }
.form_head .form_box { margin: 0; padding: 25px 28px; border: 2px solid #ddd; border-top: none;  border-bottom-right-radius: 8px;  border-bottom-left-radius: 8px;}
.fieldh{text-align: center;}
.amount{
    padding: 10px 90px;
}
#icon {
    position: absolute;
    padding: 10px 39px 10px 10px;
    background: #002948;
    // height: 61px;
    color: #fff;
    font-size: 31px;
    top: 0;
    bottom: 0;
    margin-top: 0!important;
}
.fa-dollar-sign::before {
    content: "\f155";
    position: relative;
    left: 13px;
    top: 5px;
}
.label {text-transform: uppercase;font-weight: bold;font-size: 20px;display: block;background: #1d58b3;padding: 20px 0;text-align: center;color: #fff;margin:5px 0;}
.sub_cont {padding: 14px;background: #eeeeee;font-size: 20px;line-height: 29px;margin-bottom: 20px;}
.main.fieldbox { margin-bottom: 30px; }
.fieldbox { margin: 10px 0; }
.fieldheader p { margin: 0; background: #002948; padding: 8px; color: #fff; text-align: center; font-weight: 700; border-top-left-radius: 5px; border-top-right-radius: 10px; font-size:25px;}
.fieldheader p i.fa { margin: 0 auto!important;width: 45px;height: 20px;text-align: center; }
.fieldcontent { padding: 20px; border: 3px solid #002948; border-top: 0; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; }

@import "./../../assets/css/forms.css";
</style>

<style lang="scss">
.image-upload-preview {
    left: 0;
    width: 60px;
    height: 60px;
    position: absolute;
    display: inline-block;
    z-index: 99999999999999!important;
    background-size: contain!important;
    background-repeat: no-repeat!important;
    background-position: center center!important;
}
.applicant-documents {
    .btn {
        &.btn-tertiary {
            &.js-labelFile {
                padding: 0!important;
                position: relative!important;

                span {
                    &.icon {
                        margin-top: 15px!important;
                    }
                }

                &:hover {
                    color: #fff!important;

                    .uploadcare--widget.uploadcare--widget_option_clearable.uploadcare--widget_status_ready {
                        .uploadcare--widget__button {
                            color: #fff!important;
                        }
                    }
                }

                .uploadcare--widget.uploadcare--widget_option_clearable.uploadcare--widget_status_ready // Idial Button Status
                {
                    width: 90%!important;
                }

                .uploadcare--widget.uploadcare--widget_option_clearable.uploadcare--widget_status_loaded, // Image Selected
                .uploadcare--widget.uploadcare--widget_option_clearable.uploadcare--widget_status_ready // Idial Button Status
                {
                    cursor: pointer!important;

                    .uploadcare--widget__button {
                        border: 0!important;
                        padding: 0!important;
                        width: 100%!important;
                        color: #000!important;
                        cursor: pointer!important;
                        text-align: left!important;
                        box-shadow: none!important;
                        padding: 18px 10px !important;
                        background: transparent!important;

                        &:focus {
                            outline: none!important;
                            outline-offset: 0!important;
                        }

                        &:hover {
                            color: #fff!important;
                        }

                        &.uploadcare--widget__button_type_remove {
                            display: none!important;
                        }
                    }
                }

                .uploadcare--widget.uploadcare--widget_option_clearable.uploadcare--widget_status_loaded { // Image Selected
                    width: 70%!important;
                    margin-left: 70px!important;

                    .uploadcare--widget__button {
                        &.uploadcare--widget__button_type_remove {
                            color: #fff!important;
                            width: 80px!important;
                            height: 30px!important;
                            background: #d70c0c!important;
                            border-radius: 50px!important;
                            display: inline-block!important;
                            padding: 0!important;
                            margin: 17px !important;
                            text-align: center!important;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
</style>
