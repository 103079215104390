<template>
	<div id="app">

		<div class="protect-me">
			<div class="clearfix">

				<app-header v-if="!isHomePage" />

				<router-view />

				<app-footer v-if="!isHomePage" />

			</div>

      <div id="vonageClick2callme" style="position: fixed; right: 10px; bottom: 10px; z-index: 9999;"></div>
		</div>

    <button v-if="items && items.length && $route.name != 'CheckOut' && $route.name != 'Payment'" @click="checkout" class="checkout-btn">
      <span class="count">{{ cart.qty || 0 }}</span>
      <span class="price">${{ cart.price || 0 }}</span>
      <svg style="width:24px;height:24px" viewBox="0 0 24 24" id="cart">
        <path fill="#000000" d="M17,18A2,2 0 0,1 19,20A2,2 0 0,1 17,22C15.89,22 15,21.1 15,20C15,18.89 15.89,18 17,18M1,2H4.27L5.21,4H20A1,1 0 0,1 21,5C21,5.17 20.95,5.34 20.88,5.5L17.3,11.97C16.96,12.58 16.3,13 15.55,13H8.1L7.2,14.63L7.17,14.75A0.25,0.25 0 0,0 7.42,15H19V17H7C5.89,17 5,16.1 5,15C5,14.65 5.09,14.32 5.24,14.04L6.6,11.59L3,4H1V2M7,18A2,2 0 0,1 9,20A2,2 0 0,1 7,22C5.89,22 5,21.1 5,20C5,18.89 5.89,18 7,18M16,11L18.78,6H6.14L8.5,11H16Z" />
      </svg>
      <span>Checkout</span>
    </button>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import header from '@/components/layouts/header'
import footer from '@/components/layouts/footer'

export default {
	name: 'app',

	data() {
		return {
			cart: null,
		}
	},

	components: {
		appHeader: header,
		appFooter: footer
    },

	computed: {
        ...mapGetters('cart', [
			'items',
		]),

		isHomePage() {
			return this.$route.name === 'Home'
		}
    },

	watch: {
		items: {
			immediate: true,
			deep: true,
			handler(newValue) {
				if (newValue) {
					this.cart = newValue.reduce((acc, item) => {

						if (item.hasOptions) {
							const product = item.options.find(product => product.selected)
							if (product) {
								acc.qty += product.qty;
								acc.price += product.qty * Number(product.price);
							} else {
								acc.qty += 0;
								acc.price += 0;
							}
						} else {
							acc.qty += item.qty;
							acc.price += item.qty * Number(item.price);
						}

						return acc;
					}, { qty: 0, price: 0 })
				}
			}
		}
	},

	methods: {
        checkout() {
			this.$router.push({
                name: 'CheckOut'
            })
        }
    },

}
</script>

<style lang='scss'>
@import './assets/style.min.css';
@import './assets/css/media.min.css';
@import './assets/css/hamburgers.min.css';
@import './assets/css/rslides.min.css';
@import './assets/css/font-awesome.min.css';
@import './../node_modules/animate.css/animate.min.css';

#vonageClick2callme {
  a {
    color: #000!important;
    min-width: 44px!important;
    min-height: 44px!important;
    display: inline-block!important;
  }
}

#banner {
    position: static;
    margin: 0;
}

.main_logo {
    top: 121px;
    width: 220px;
    height: 220px;
    left: 0;
    right: auto;
    padding: 82px 24px;
}

.main_logo img {
    width: auto;
}

/* main Config */
#main_area {
    font-size: 16px;
    background: #f8f8f8;
    padding: 0;
    min-height: 0;
    margin: 0;
}

main {
    width: 100%;
    padding: 20px 10px;
    min-height: 300px;
    text-align: left;
    margin: 0;
    float: none
}

main p {
    margin-bottom: 20px;
    padding: 0;
}

.ftop_con {
    padding: 50px 0 0;
    height: 327.3px
}

/*media*/

@media only screen and (max-width:1010px) {
    main {
        padding: 20px 10px;
        text-align: left;
    }

    .main_logo {
        position: static;
        background: 0 0;
        width: 550px;
        height: auto;
        padding: 0;
    }

    .ftop_con {
        padding: 30px 12px;
        min-height: 0;
        height: auto;
        text-align: center;
    }

    .ftr_logo {
        margin: 0 auto;
        float: none;
        max-width: 100%;
    }
}

@media only screen and (max-width:800px) {
    .thumb_right_dd,
    .thumb_right_dr {
        width: 250px;
    }

}

@media only screen and (max-width:800px) {
    .main_logo {
        width: calc(100% - 200px);
    }
}

@media only screen and (max-width:600px) {
    .main_logo {
        width: calc(100% - 110px);
    }
}

@media only screen and (max-width:400px) {
    .main_logo {
        width: calc(100% - 70px);
    }
}

a:hover {
    text-decoration: none!important;
}
</style>

<style lang="scss">
.checkout-btn {
    .count,
    .price {
        top: -30px;
        position: absolute;
        font-size: 1.2em;
        line-height: 25px;
    }

    .count {
        width: 25px;
        height: 25px;
        background: linear-gradient(90deg,#b6ca0a -11%,#649a15 103%);
        color: #fff;
        border-radius: 50%;
    }

    .price {
        right: 2em;
    }
}
button.checkout-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  display: flex;
  align-items: center;
  padding: 16px 20px 16px 15px;
  height: 56px;
  position: fixed;
  right: 5em;
  top: 5em;
  z-index: 9999;
  font-family: inherit;
  font-size: 1em;
  line-height: 1em;
  font-weight: 500;
  background-color: white;
  cursor: pointer;
  border-radius: 32px;
  box-shadow: 0 8px 32px rgba(0,0,0,0.25);
  -webkit-tap-highlight-color: transparent;
  transition: box-shadow 0.2s ease, background-color 150ms ease;
  &:focus {
    box-shadow: 0 8px 64px 16px rgba(0,0,0,0.5);
    outline: none;
  }

  &:hover, &:active {
    background-color: #eee;
  }
}

#cart {
  margin-right: 12px;
  transform-origin: 20% 100%;
  opacity: 1;
}

@keyframes slide-up-fade {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-16px);
    opacity: 0;
  }
}

@keyframes roll-out {
  0% {
    transform: translate(0) rotate(0);
  }
  20% {
    transform: translate(0) rotate(-70deg);
    opacity: 1;
  }
  50% {
    transform: translate(0) rotate(-45deg);
    opacity: 1;
  }
  100% {
    transform: translate(140px) rotate(-47deg);
    opacity: 0;
  }
}

.checked-out span {
  animation: slide-up-fade 150ms 1;
  animation-fill-mode: both;
}

.checked-out #cart {
  animation: roll-out 1s 1 150ms;
  animation-timing-function: ease-in;
  animation-fill-mode: both;
}

@keyframes checkmark {
  from {
    stroke-dashoffset: 26px;
  }
  to {
    stroke-dashoffset: 0;
  }
}

#check {
  position: absolute;
  left: calc(50% - 12px);
}

#check path {
  stroke-dasharray: 26px;
  stroke-dashoffset: 26px;
}

.checked-out #check path {
  animation: checkmark 150ms 1 1150ms;
  animation-timing-function: ease-in;
  animation-fill-mode: both;
}

.checked-out button {
  background-color: #20bf6b;
  transition-delay: 1150ms;
}
</style>
