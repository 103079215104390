export const getUserAgent = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return "Mobile";
    } else {
        return "PC";
    }
};

export const getUserIp = () => {
    return localStorage.getItem('userIp') ? localStorage.getItem('userIp') : null;
};