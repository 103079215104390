<template>
    <div>
        <!-- Banner -->
        <app-banner pageName="Refer Someone to Us"></app-banner>
        <!-- End Banner -->

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span class="post post-page current-item">Refer Someone to Us</span>
                    </div>
                    <div id="post-32" class="post-32 page type-page status-publish hentry">
                        <div class="entry-content">
                            <p>We gladly accept referrals, To refer someone for us to hire or care for please fill this form.</p>

                            <!-- START FORM -->
                            <refer-someone-to-us-form/>
                            <!-- END FORM -->

                        </div>
                    </div>
                </main>
                <div class="clearfix"></div>
            </div>
        </div>

    </div>
</template>

<script>
import banner from '@/components/layouts/banner'
import ReferSomeoneToUsForm from '@/components/forms/ReferSomeoneToUsForm';

export default {
    components: {
        appBanner: banner,
        ReferSomeoneToUsForm
    }
}
</script>
