<template>
    <div id="servicesForm">
        <div class="clearfix">
            <div class="wrapper">
                <div id="contact_us_form_1" class="template_form">
                    <div class="form_frame_b">
                        <div class="form_content">

                            <alert :alertData="alertData"/>

                            <form id="submitform" name="contact" novalidate @submit.prevent="handleSubmitForm">
                                <span class="required-info">* Required Information</span>
                                <div class="form_box_col2">
                                    <div class="group">
                                        <div class="form_label">
                                            <label for="First_Name" class="text_uppercase">
                                                <strong>First Name
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" v-model="applicant.firstName" :class="{ 'error': submitted && $v.applicant.firstName.$error }" type="text" name="First_Name" class="form_field" id="First_Name" placeholder="Enter first name here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                    <div class="group">
                                        <div class="form_label">
                                            <label for="Last_Name" class="text_uppercase">
                                                <strong>Last Name
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" v-model="applicant.lastName" :class="{ 'error': submitted && $v.applicant.lastName.$error }" type="text" name="Last_Name" class="form_field" id="Last_Name" placeholder="Enter last name here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_box_col2">
                                    <div class="group">
                                        <div class="form_label">
                                            <label for="Email" class="text_uppercase">
                                                <strong>Email
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" v-model="applicant.email" :class="{ 'error': submitted && $v.applicant.email.$error }" type="text" name="Email" class="form_field" id="Email" placeholder="Enter email here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                    <div class="group">
                                        <div class="form_label">
                                            <label for="Phone_Number" class="text_uppercase">
                                                <strong>Phone Number
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" v-model="applicant.phoneNumber" :class="{ 'error': submitted && $v.applicant.phoneNumber.$error }" type="text" name="Phone_Number" class="form_field" id="Phone_Number" placeholder="Enter phone number here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_box">
                                    <div class="form_box_col2">
                                        <div class="group">
                                            <div class="form_label">
                                                <label for="Address" class="text_uppercase">
                                                    <strong>Address
                                                        <span class="required_filed">*</span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <input autocomplete="nope" type="text" name="Address" v-model="applicant.address" :class="{ 'error': submitted && $v.applicant.address.$error }" class="form_field" id="Address" placeholder="Enter address here">
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                        <div class="group">
                                            <div class="form_label">
                                                <label for="Apartment_Number" class="text_uppercase">
                                                    <strong>Apartment Number
                                                        <span class="required_filed">*</span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <input autocomplete="nope" type="text" name="Apartment_Number" v-model="applicant.apartmentNumber" :class="{ 'error': submitted && $v.applicant.apartmentNumber.$error }" class="form_field" id="Apartment_Number" placeholder="Enter apartment number here">
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form_box_col3">
                                        <div class="group">
                                            <div class="form_label">
                                                <label for="City" class="text_uppercase">
                                                    <strong>City
                                                        <span class="required_filed">*</span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <input autocomplete="nope" type="text" name="City" v-model="applicant.city" :class="{ 'error': submitted && $v.applicant.city.$error }" class="form_field" id="City" placeholder="Enter city here">
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                        <div class="group">
                                            <div class="form_label">
                                                <label for="State" class="text_uppercase">
                                                    <strong>State
                                                        <span class="required_filed"></span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="select-field">
                                                <select name="State" id="State" v-model="applicant.state" class="form_field">
                                                    <option value="">Please select state.</option>
                                                    <option value="Alabama">Alabama</option>
                                                    <option value="Alaska">Alaska</option>
                                                    <option value="Arizona">Arizona</option>
                                                    <option value="Arkansas">Arkansas</option>
                                                    <option value="California">California</option>
                                                    <option value="Colorado">Colorado</option>
                                                    <option value="Connecticut">Connecticut</option>
                                                    <option value="Delaware">Delaware</option>
                                                    <option value="District Of Columbia">District Of Columbia</option>
                                                    <option value="Florida">Florida</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Hawaii">Hawaii</option>
                                                    <option value="Idaho">Idaho</option>
                                                    <option value="Illinois">Illinois</option>
                                                    <option value="Indiana">Indiana</option>
                                                    <option value="Iowa">Iowa</option>
                                                    <option value="Kansas">Kansas</option>
                                                    <option value="Kentucky">Kentucky</option>
                                                    <option value="Louisiana">Louisiana</option>
                                                    <option value="Maine">Maine</option>
                                                    <option value="Maryland">Maryland</option>
                                                    <option value="Massachusetts">Massachusetts</option>
                                                    <option value="Michigan">Michigan</option>
                                                    <option value="Minnesota">Minnesota</option>
                                                    <option value="Mississippi">Mississippi</option>
                                                    <option value="Missouri">Missouri</option>
                                                    <option value="Montana">Montana</option>
                                                    <option value="Nebraska">Nebraska</option>
                                                    <option value="Nevada">Nevada</option>
                                                    <option value="New Hampshire">New Hampshire</option>
                                                    <option value="New Jersey">New Jersey</option>
                                                    <option value="New Mexico">New Mexico</option>
                                                    <option value="New York">New York</option>
                                                    <option value="North Carolina">North Carolina</option>
                                                    <option value="North Dakota">North Dakota</option>
                                                    <option value="Ohio">Ohio</option>
                                                    <option value="Oklahoma">Oklahoma</option>
                                                    <option value="Oregon">Oregon</option>
                                                    <option value="Pennsylvania">Pennsylvania</option>
                                                    <option value="Puerto Rico">Puerto Rico</option>
                                                    <option value="Rhode Island">Rhode Island</option>
                                                    <option value="South Carolina">South Carolina</option>
                                                    <option value="South Dakota">South Dakota</option>
                                                    <option value="Tennessee">Tennessee</option>
                                                    <option value="Texas">Texas</option>
                                                    <option value="Utah">Utah</option>
                                                    <option value="Vermont">Vermont</option>
                                                    <option value="Virgin Islands">Virgin Islands</option>
                                                    <option value="Virginia">Virginia</option>
                                                    <option value="Washington">Washington</option>
                                                    <option value="West Virginia">West Virginia</option>
                                                    <option value="Wisconsin">Wisconsin</option>
                                                    <option value="Wyoming">Wyoming</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="group">
                                            <div class="form_label">
                                                <label for="Zip" class="text_uppercase">
                                                    <strong>Zip
                                                        <span class="required_filed">*</span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <input autocomplete="nope" type="text" name="Zip" v-model="applicant.zipCode" :class="{ 'error': submitted && $v.applicant.zipCode.$error }" class="form_field" id="Zip" placeholder="Enter zip here">
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="form_box">
                                    <div class="form_box_col2">

                                        <div class="group">
                                            <div class="form_label">
                                                <label for="Position_Applying_For" class="text_uppercase">
                                                    <strong>Position Applying For
                                                        <span class="required_filed">*</span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="select-field field_holder animated_box">
                                                <select
                                                    autocomplete="nope"
                                                    v-model="applicant.position"
                                                    :class="{ 'error': submitted && $v.applicant.position.$error }"
                                                    name="Position_Applying_For"
                                                    class="form_field"
                                                    id="Position_Applying_For"
                                                    placeholder="Enter position applying for here"
                                                >
                                                    <option value="">- Please select -</option>
                                                    <option value="Home Health Aide">Home Health Aide</option>
                                                    <option value="Office Position">Office Position</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="group">
                                            <div class="form_label">
                                                <label for="Preferred_Date" class="text_uppercase">
                                                    <strong>Date Available to Start
                                                        <span class="required_filed">*</span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <datepicker
                                                    format="MM/dd/yyyy"
                                                    v-model="applicant.availableDate"
                                                    class="form_field Date"
                                                    :disabled-dates="disabledDates"
                                                    :class="{ 'error': submitted && $v.applicant.availableDate.$error }"
                                                    id="Preferred_Date"
                                                    placeholder="Enter preferred date here"
                                                />
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="form_box">
                                    <div class="form_box_col1">
                                        <div class="group">
                                            <div class="form_label">
                                                <div class="text_uppercase">
                                                    <strong>Attach Resume  (PDF or Doc file only)
                                                        <span class="required_filed"></span>
                                                    </strong>
                                                </div>
                                            </div>
                                            <input autocomplete="nope" accept="application/msword, application/pdf" type="file" name="attachment[]" id="file" class="input-file" multiple @change="uploadResume($event)">
                                            <label for="file" class="btn btn-tertiary js-labelFile">
                                                <span class="">{{ resumeName ? resumeName : 'Choose a file' }}</span>
                                                <span class="icon"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="clear"></div>

                                <div class="form_box">
                                    <div class="form_box_col1">
                                        <div class="group">
                                            <div class="form_label">
                                                <label for="mce-referredBy" class="text_uppercase">
                                                    <strong>How did you hear about us?</strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <input
                                                    type="text"
                                                    v-model="applicant.referredBy"
                                                    name="referredBy"
                                                    class="form_field"
                                                    autocomplete="nope"
                                                    placeholder="How did you hear about us?"
                                                    :class="{ error: submitted && $v.applicant.referredBy.$error }"
                                                    id="mce-referredBy"
                                                />
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form_box">
                                    <div class="form_box_col1">
                                        <div class="group">
                                            <div class="form_label">
                                                <label for="Message" class="text_uppercase">
                                                    <strong>Message
                                                        <span class="required_filed"></span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <textarea name="Message" v-model="applicant.message" class="text form_field" id="Message" placeholder="Enter your message here"></textarea>
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form_box5 secode_box">
                                    <div class="inner_form_box1 recapBtn">
                                        <div class="btn-submit">
                                            <button type="submit" class="button form_button" :disabled="loading">SUBMIT</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import alert from '@/components/layouts/alert'
import { postNewHealthCare } from '@/api/apiCalls'
import { getUserAgent, getUserIp } from '@/helpers'
import { required, email } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            submitted: false,
            loading: false,
            resume: '',
            resumeName: '',
            attachments: [],
            disabledDates: {
                to: new Date(), // Disable all dates up to specific date
            },
            applicant: {
                state: '',
                position: '',
                referredBy: null,
            },
            alertData: {
                showAlert: false,
                alertType: 'success',
                alertText: 'Data Added Successfully!'
            }
        }
    },

    components: {
        alert
    },

    validations: {
        applicant: {
            firstName: { required },
            lastName: { required },
            email: { required, email },
            phoneNumber: { required },
            cellNumber: {},
            //
            address: { required },
            apartmentNumber: { required },
            city: { required },
            state: { required },
            zipCode: { required },
            availableDate: { required },
            //
            position: { required },
            referredBy: {}
        }
    },

    methods: {
        uploadResume(event) {
            const files = event.target.files;
            this.attachments = files;
            this.resumeName = files[0].name;

            var reader = new FileReader;
            reader.addEventListener('load', () => {
                this.resume = reader.result;
            });
            reader.readAsDataURL(files[0]);
        },
        handleSubmitForm() {
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();

            // Scroll To Error
            this.$nextTick(() => {
                let element = document.querySelector('.form_field.error');
                if(element) {
                    let domRect = element.getBoundingClientRect();
                    window.scrollTo({
                        left: domRect.left + document.documentElement.scrollLeft,
                        top: domRect.top + (document.documentElement.scrollTop - 100),
                        behavior: 'smooth'
                    });
                }
            });

            if (!this.$v.$invalid) {
                this.loading = true;

                postNewHealthCare({
					firstName: this.applicant.firstName,
					lastName: this.applicant.lastName,
                    email: this.applicant.email,
                    phoneNumber: this.applicant.phoneNumber,
                    cellNumber: this.applicant.cellNumber,
                    position: this.applicant.position,
                    message: this.applicant.message,
                    resume: this.resume,
                    resumeName: this.resumeName,
                    referredBy: this.applicant.referredBy,
                    //
                    availableDate: this.applicant.availableDate,
                    address: this.applicant.address,
                    apartmentNumber: this.applicant.apartmentNumber,
                    city: this.applicant.city,
                    state: this.applicant.state,
                    zipCode: this.applicant.zipCode,
                    //
					userAgent: getUserAgent(),
					ip: getUserIp()
                }).then(({ data }) => {
                    this.alertData.showAlert = true;

                    // Redirect to continue
                    if (data.data.hash) {
                        this.$router.push({
                            name: 'CompleteHomeHealthCareServiceApplication',
                            query: {
                                hash: data.data.hash
                            }
                        });
                    } else {
                        this.$router.push({
                            name: 'Confirmation'
                        });
                    }

                    this.applicant = {};
                    this.resume = "";
                    this.resumeName = "";
                }).catch(() => {
                    this.alertData = {
                        showAlert: true,
                        alertType: "danger",
                        alertText: "Something Went Wrong!"
                    };
                }).finally(() => {
                    this.loading = false;
                    this.submitted = false;
                    setTimeout(() => {
                        this.alertData.showAlert = false;
                    }, 3000);
                })
            }
        }
    },

}
</script>

<style lang="scss" scoped>
@import "./../../assets/css/forms.css";

[type="radio"]:checked + label::after,
[type="radio"]:not(:checked) + label::after { left: 3px; }

[type="radio"]:checked + label::before,
[type="radio"]:not(:checked) + label::before { left: 0; }

.form_head { border-radius: 10px; }
.form_head p.title_head:nth-child(1) { background: #ff3f3f;  margin: 0;  padding: 10px;  color: #fff;  font-weight: bold;  border-top-right-radius: 8px;  border-top-left-radius: 8px;}
.form_head .form_box .form_box_col1 p { margin-bottom: 4px; }
.form_head .form_box { margin: 0; padding: 25px 28px; border: 2px solid #ddd; border-top: none;  border-bottom-right-radius: 8px;  border-bottom-left-radius: 8px;}
</style>
