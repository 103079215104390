import axios from 'axios'

// Import tha api config
import {
    apiMethods,
    loginData
} from '@/api/apiConfig'

let axiosResponse, header

export function agingcarenetAccessToken() {
    axiosResponse = axios.post(apiMethods.loginURL, {
        client_id: loginData.clientId,
        client_secret: loginData.clientSecret,
        username: loginData.email,
        password: loginData.password,
        grant_type: 'password'
    }).then(function ({ data }) {
        localStorage.setItem('agingcarenetToken', data.access_token)
        header = data.access_token
    })
    return axiosResponse
}

function getHeaders() {
    return {
        headers: {
            Authorization: `Bearer ${header}`
        }
    }
}

export function agingcarenetToken() {
    if (!header)
        header = localStorage.getItem('agingcarenetToken')

    if (header) {
        return new Promise(resolve => {
            resolve()
        })
    } else if (axiosResponse) {
        return axiosResponse
    } else {
        return agingcarenetAccessToken()
    }
}

// Get Settings
export const getSettings = () => {
    return new Promise(resolve => {
        resolve(agingcarenetAccessToken().then(function () {
            return axios.get(apiMethods.settings, getHeaders())
        }))
    })
}

/* ------------------------- Start Api Methods ------------------------- */
// PAYMENT
export const stripeMakeCharge = data => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.post(apiMethods.stripeMakeCharge, data, getHeaders())
        }))
    })
}

// SERVICES APIS

/* NURSE AIDE TRAINING */
export const postNewNurseAideTraining = data => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.post(apiMethods.nurseAideTraining, data, getHeaders())
        }))
    })
}

export const postNurseAideTrainingDocuments = data => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.post(`${apiMethods.nurseAideTraining}/applicant-documents`, data, getHeaders())
        }))
    })
}

export const nurseAideTrainingWithHash = hash => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.get(`${apiMethods.nurseAideTrainingWithHash}/${hash}`, getHeaders())
        }))
    })
}

/* CPR TRAINING */
export const postNewCPRTraining = data => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.post(apiMethods.CPRTraining, data, getHeaders())
        }))
    })
}

export const CPRTrainingWithHash = hash => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.get(`${apiMethods.CPRTrainingWithHash}/${hash}`, getHeaders())
        }))
    })
}

/* HOME ASSESSMENT */
export const postNewHomeAssessment = data => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.post(apiMethods.homeAssessment, data, getHeaders())
        }))
    })
}

/* HEALTH CARE */
export const postNewHealthCare = data => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.post(apiMethods.healthCare, data, getHeaders())
        }))
    })
}

export const getHealthCareInfo = hash => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.get(`${apiMethods.healthCare}/${hash}`, getHeaders())
        }))
    })
}

export const postHealthCareService = (hash, data) => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.post(`${apiMethods.healthCare}/${hash}`, data, getHeaders())
        }))
    })
}

export const deleteHealthCareEmployment = id => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.delete(`${apiMethods.healthCareEmployment}/${id}`, getHeaders())
        }))
    })
}

/* LPN Program */
export const postNewLPNProgram = data => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.post(`${apiMethods.LPNProgram}`, data, getHeaders())
        }))
    })
}

export const LPNProgramWithHash = hash => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.get(`${apiMethods.LPNProgramWithHash}/${hash}`, getHeaders())
        }))
    })
}

/* PROVIDE HOME CARE */
export const postNewHomeCare = data => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.post(apiMethods.homeCare, data, getHeaders())
        }))
    })
}

/* REFERRALS */
export const postNewReferral = data => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.post(apiMethods.referrals, data, getHeaders())
        }))
    })
}

/* MAILING LIST */
export const postNewMailingList = data => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.post(apiMethods.mailingList, data, getHeaders())
        }))
    })
}

/* CONTACT US */
export const postNewContact = data => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.post(apiMethods.contactUs, data, getHeaders())
        }))
    })
}

/* STAFF */
export const postNewStaff = data => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.post(apiMethods.jobs, data, getHeaders())
        }))
    })
}

/* FAQ */
export const getFAQs = type => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.get(`${apiMethods.FAQs}/${type}`, getHeaders())
        }))
    })
}

/* GALLERY */
export const getGallery = ({ perPage = 20, page = 1 }) => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.get(`${apiMethods.gallery}?perPage=${perPage}&page=${page}`, getHeaders())
        }))
    })
}

/* STORE */
export const getStoreProducts = () => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.get(apiMethods.store, getHeaders())
        }))
    })
}

export const getStoreProductItem = id => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.get(`${apiMethods.store}/${id}`, getHeaders())
        }))
    })
}

export const postNewOrder = data => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.post(apiMethods.order, data, getHeaders())
        }))
    })
}

export const orderWithHash = hash => {
    return new Promise(resolve => {
        resolve(agingcarenetToken().then(function () {
            return axios.get(`${apiMethods.order}/${hash}`, getHeaders())
        }))
    })
}
/* -------------------------- End Api Methods -------------------------- */
