/* eslint-disable no-console */
export default {
	namespaced: true,
	state: () => ({
        items: [],
	}),
	getters: {
        items(state) {
            return state.items
        },
	},
	mutations: {
        SET_ITEMS(state, payload) {
            state.items = payload
        },
	},
	actions: {}
}
