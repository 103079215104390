<template>
    <div>
        <!-- Banner -->
        <app-banner pageName="About Us"></app-banner>
        <!-- End Banner -->

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span class="post post-page current-item">About Us</span>
                    </div>
                    <div id="post-16" class="post-16 page type-page status-publish hentry">
                        <div class="entry-content">
                            <div class=intro_txt>We are all about successful aging experience.</div>
                            <figure class="thumb_right_dd">
                                <img src="~@/assets/images/thumbnails/thumb-61134208.jpg" alt="nurse assisting elder man">
                            </figure>
                            <p>
                                <strong>Who We Are</strong>
                                <br />
                                Successful Aging Home Health is a distinguished healthcare company dedicated to providing quality geriatric care services in Pennsylvania. Since 2003, we have supported countless residents in aging successfully, becoming an integral part of our clients' families. Over the years, we have alleviated the stress of health management through our dedicated assistance, expert guidance, and compassionate care. Our team addresses the needs of not only the elderly but also individuals living with disabilities. We also ensure that our caregivers are well supported, enabling them to provide the highest level of care. Whether you or your loved ones require short-term skilled services or round-the-clock personal care, you can trust us to deliver with dignity and compassion.
                            </p>
                            <p>
                                <strong>Mission:</strong>
                                <br />
                                The mission of Successful Aging Care Net Inc. is to provide quality home health care services, ensure patient satisfaction, and support and appreciate caregivers, all to enable patients to age successfully at home.
                            </p>
                            <p>
                                <strong>Vision:</strong>
                                <br />
                                Successful Aging aims to be North America's largest and leading home healthcare provider, setting the standard for excellence and compassion, supporting caregivers, and ensuring successful aging at home.
                            </p>
                            <!-- <ul class="bullet">
                                <li>To be committed to the wellness and safety of the elderly</li>
                                <li>To offer affordable geriatric care programs</li>
                                <li>To ensure that seniors get quality health services that are second to none</li>
                            </ul> -->
                            <p>
                                <strong>Get in Touch</strong>
                                <br />
                                We&#8217;d love to hear from you! Schedule a
                                <router-link :to="{ name: 'FreeInHomeAssessment' }" tag="a">free in-home assessment</router-link>
                                with us so we can personally assess your needs. For more information,
                                <router-link :to="{ name: 'ContactUs' }" tag="a">contact us</router-link> or send us an e-mail at
                                <a href="mailto:admin@agingcarenet.com">admin@agingcarenet.com</a>.
                            </p>
                        </div>
                    </div>
                </main>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/components/layouts/banner'

export default {
    data() {
        return {

        }
    },
    components: {
        appBanner: banner,
    }
}
</script>
