import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

// M O D U L E S : : :::
import cartModule from './modules/cart'

// const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
	status: {
		//
	},
	actions: {
		//
	},
	mutations: {
		//
	},
	getters: {
		//
	},
	modules: {
		cart: cartModule,
	}
	// strict: debug
})
