<template>
    <div>
        <!-- Banner -->
        <app-banner pageName="Contact Us"></app-banner>
        <!-- End Banner -->

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span class="post post-page current-item">Contact Us</span>
                    </div>
                    <div id="post-10" class="post-10 page type-page status-publish hentry">
                        <div class="entry-content">
                            <p>
                                We appreciate the time you took to visit our website. Go ahead and submit your
                                questions, inquiries,
                                concerns, feedback, and comments. Feel free to fill out the form below and you will
                                receive our reply
                                shortly.
                            </p>
                            <div id="mc_embed_signup">
                                <contac-us-form />
                            </div>
                        </div>
                    </div>
                </main>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/components/layouts/banner'
import ContacUsForm from '@/components/forms/ContactUsForm'

export default {
    components: {
        appBanner: banner,
        ContacUsForm,
    }
}
</script>
