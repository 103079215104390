<template>
    <footer :class="{ 'is-education-footer': !showFooterLogo }">
        <div class="footer_top">
            <div class="wrapper">
                <div class="ftop_con" :class="{ 'home-footer': homeFooter }">
                    <div class="contact_info">
                        <div class="widget-container classic-textwidget custom-classic-textwidget">
                            <div class="classic-text-widget">
                                <h2>Contact Information</h2>
                                <p>We'd love to hear from you! Reach us through the following details.</p>
                                <ul>
                                    <li>Corporate Headquarter:
                                        <address style="color: #000;">- 38 Garrett Road<small>,</small> Upper Darby PA 19082</address>
                                        <address style="color: #000;">- 46 Garrett Road<small>,</small> Upper Darby PA 19082</address>
                                        Phone: <mark>888-960-4090 <small> | </small>215-710-0098</mark> <small> | </small>
                                        Email: <a href="mailto:admin@agingcarenet.com">admin@agingcarenet.com</a>
                                    </li>
                                    <li>
                                        <div class="wrapper">
                                            <div class="header_con">
                                                <div class="social_media">
                                                    <ul>
                                                        <li>
                                                            <a href="https://www.facebook.com/Successful-Aging-Home-Health-1834184990134677/" target="_blank">
                                                                <figure>
                                                                    <img src="~@/assets/images/icon_fb.png" alt="facebook">
                                                                </figure>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.twitter.com/successagingHH" target="_blank">
                                                                <figure>
                                                                    <img src="~@/assets/images/icon_tw.png" alt="twitter">
                                                                </figure>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://plus.google.com/b/117079547268548121252/+Agingcarenet-homecare-in-pennsylvania" target="_blank">
                                                                <figure>
                                                                    <img src="~@/assets/images/gplus.png" alt="google plus">
                                                                </figure>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.pinterest.com/successagingHH/" target="_blank">
                                                                <figure>
                                                                    <img src="~@/assets/images/pinterest.png" alt="pinterest">
                                                                </figure>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="Affiliates">
                        <h2>Visit our Affiliates</h2>
                        <p>
                            <a href="https://www.sacafepa.com/" target="_blank">www.sacafepa.com</a>
                            for all your hall rental and restaurant needs
                        </p>
                        <!-- <p>
                            <a href="http://www.successfulaginginc.com/" target="_blank">www.successfulaginginc.com</a>
                            to enroll in the nurse aide program and get a job
                        </p> -->
                        <p>
                            <a href="http://www.successfulgrowthacademy.com/" target="_blank">www.successfulgrowthacademy.com</a>
                            to enroll your child at our Day care
                        </p>
                    </div>
                    <div v-if="showFooterLogo" class="ftr_logo">
                        <router-link :to="{ name: 'Home' }" tag="a">
                            <figure>
                                <img src="~@/assets/images/main_logo.png" alt="Successful Aging Home Health" />
                            </figure>
                        </router-link>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>

        <div class="footer_btm">
            <div class="wrapper">
                <div class="fbtm_con">
                    <div class="footer_nav">
                        <div class="menu-footer-menu-container">
                            <ul id="menu-footer-menu" class="menu" style="text-align: center;">
                                <!-- <li id="menu-item-27" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-27">
                                    <router-link :to="{ name: 'Home' }" tag="a">Home <small>Welcome Page</small></router-link>
                                </li>
                                <li id="menu-item-23" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-23">
                                    <router-link :to="{ name: 'AboutUs' }" tag="a">About Us <small>Who We Are</small></router-link>
                                </li>
                                <li id="menu-item-24" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-24">
                                    <router-link :to="{ name: 'Services' }" tag="a">Services <small>What We Do</small></router-link>
                                </li>
                                <li id="menu-item-25" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-25">
                                    <router-link :to="{ name: 'Careers' }" tag="a">Careers <small>Join Us Now</small></router-link>
                                </li>
                                <li id="menu-item-149" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-149">
                                    <router-link :to="{ name: 'NurseAideTrainingServices' }" tag="a">Nurse Aide<small>Training Services</small></router-link>
                                </li>
                                <li id="menu-item-26" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26">
                                    <router-link :to="{ name: 'ContactUs' }" tag="a">Contact Us <small>Keep in Touch</small></router-link>
                                </li> -->
                                <li id="menu-item-26" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26">
                                    <router-link :to="{ name: 'PrivacyPolicy' }" tag="a">Privacy Policy</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="copyright">
                        &copy; Copyright {{ new Date().getFullYear() }} <br class="ftr_br" />
                        <span class="copyr">Powered by</span>:
                        <a href="https://www.crmzz.com" target="_blank" rel="nofollow">CRMzz</a>
                    </div>
                </div>
            </div>

        </div>
    </footer>
</template>

<script>
export default {
    data() {
        return {
            homeFooter: true,
        }
    },
    watch: {
        $route: {
            // eslint-disable-next-line
            handler: function(newVal, oldVal) {
                if (newVal && newVal.name != 'Home') {
                    this.homeFooter = false;
                } else if (newVal && newVal.name == 'Home') {
                    this.homeFooter = true;
                }
            },
            deep: true
        },
    },
    computed: {
        showFooterLogo() {
            return !this.$route.fullPath.includes('/education')
        }
    },
    mounted() {
        if (this.$route.name != 'Home') {
            this.homeFooter = false;
        } else if (this.$route.name == 'Home') {
            this.homeFooter = true;
        }
    },
}
</script>

<style>
.ftop_con.home-footer {
    height: 502.3px!important;
    padding: 232px 0 0!important;
}

@media only screen and (max-width: 1010px) {
    .footer_top .wrapper .ftop_con {
        padding: 30px 12px!important;
        min-height: 0;
        height: auto!important;
        text-align: center;
    }
}

footer.is-education-footer .footer_top .ftop_con {
    padding: 40px 0;
    height: auto!important;
}

footer.is-education-footer .footer_top .ftop_con address {
    display: block!important;
}

footer.is-education-footer .footer_top .ftop_con .contact_info {
    float: left!important;
}

footer.is-education-footer .footer_top .ftop_con .Affiliates {
    top: 25%!important;
}

footer.is-education-footer .footer_btm .fbtm_con .copyright {
    padding: 0!important;
}
</style>
