<template>
    <div>
        <!-- Banner -->
        <app-banner pageName="Apply to Provide Home Care to Your Loved One and Get Paid"></app-banner>
        <!-- End Banner -->

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span class="post post-page current-item">Apply to Provide Home Care to Your Loved One and Get Paid</span>
                    </div>

                    <!-- START FORM -->
                    <careers-form />
                    <!-- <apply-to-provide-home-care-form /> -->
                    <!-- END FORM -->

                </main>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/components/layouts/banner'
import CareersForm from '@/components/forms/CareersForm';
// import ApplyToProvideHomeCareForm from '@/components/forms/ApplyToProvideHomeCareForm';

export default {
    components: {
        appBanner: banner,
        CareersForm
        // ApplyToProvideHomeCareForm
    }
}
</script>
