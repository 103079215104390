<template>
    <div>
        <!-- Banner -->
        <Banner pageName="Payment" :type="type" />

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span class="post post-page current-item">Payment</span>
                    </div>
                    <div id="post-10" class="post-10 page type-page status-publish hentry">
                        <div class="entry-content text-center confirm-text">
                            <h3 v-if="type == 'store'">Store Items Payment</h3>
                            <h3 v-else>Application Fee</h3>
                            <h3>Please make payment of ${{ amount }}</h3>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-12 text-center">
                                <p class="mb-4" style="line-height: 40px; word-spacing: 5px;">
                                    YOUR PERSONAL AND FINANCIAL INFORMATION ARE TRANSMITTED <br />
                                    OVER SSL SECURED CONNECTION USING 256 BIT BANK LEVEL ENCRYPTION
                                </p>

                                <!-- description="Stripe Online Payment" -->

                                <vue-stripe-checkout
                                    ref="checkoutRef"
                                    name="Agingcare"
                                    currency="USD"
                                    :amount="amount * 100"
                                    :allow-remember-me="true"
                                    @done="submitPayment"
                                />

                                <img src="~@/assets/images/credit-card.png" @click="fireCheckout" style="cursor: pointer;" alt="PAYMENT GATEWAY">
                            </div>
                        </div>
                    </div>
                </main>
                <div class="clearfix"></div>
            </div>
        </div>

    </div>
</template>

<script>
import Vue from 'vue'
import Banner from '@/components/layouts/banner'
import VueStripeCheckout from 'vue-stripe-checkout'
// Vue.use(VueStripeCheckout, 'pk_test_51I3ptUK3CSO6T7X0h45Z6CAt4y4fq3Av4LGkZLGoBLZzccdXD7eWQlCoNURZwV4E8KoOqxu0qJLSSKJSP8afYjcR00Y4YJE0yr')
Vue.use(VueStripeCheckout, 'pk_live_51JmWMtGgfN6QolTHc2js0CcdsWJntcqHqb8CkrBFewKPdGRTRjNbcbQQUnVS7X5BHgKKErI6V6NByXdSEM1dz4IM00fmke9iYd')
import { nurseAideTrainingWithHash, LPNProgramWithHash, CPRTrainingWithHash, orderWithHash, stripeMakeCharge } from '@/api/apiCalls'

export default {
    props: ['hash'],
    data() {
        return {
            crm: {},
            amount: 0
        }
    },
    computed: {
        type() {
            return this.$route.query && this.$route.query.type ? this.$route.query.type : 'nurse-aide-training-services'
        },
        stage() {
            return this.$route.query && this.$route.query.stage ? this.$route.query.stage : null
        }
    },
    created () {
        this.getData()
    },
    components: {
        Banner,
    },
    methods: {
        getData() {
            if (this.type == 'nurse-aide-training-services') {
                nurseAideTrainingWithHash(this.hash).then(({ data }) => {
                    this.crm = data.data
                    this.amount = this.stage === 'full' ? data.data.remainingBalance : data.data.stagePrice
                })
            } else if (this.type == 'cpr-training-services') {
                CPRTrainingWithHash(this.hash).then(({ data }) => {
                    this.crm = data.data
                    this.amount = data.amout
                })
            } else if (this.type == 'store') {
                orderWithHash(this.hash).then(({ data }) => {
                    this.crm = data.data
                    this.amount = Number(data.amount || 0)
                })
            } else if (this.type == 'lpn-program') {
                LPNProgramWithHash(this.hash).then(({ data }) => {
                    this.crm = data.data
                    this.amount = this.stage === 'full' ? data.data.remainingBalance : data.data.stagePrice
                })
            }
        },
        fireCheckout() {
			this.$refs.checkoutRef.open()
        },
        submitPayment({ token }) {
            stripeMakeCharge({
                type: this.type,
                source: token.id,
				amount: this.amount * 100,
                token: token.id,
                clientIp: token.client_ip,
                created: token.created,
                email: token.email,
                cardId: token.card.id,
                last4: token.card.last4,
                brand: token.card.brand,
                card: token.card,
                hash: this.crm.hash,
                id: this.crm.id,
                stage: this.stage === 'full' ? 'full-payment' : this.crm.currentStage
            }).then(() => {
                this.$router.push({
                    name: 'Confirmation'
                })
            }).catch(({ response }) => {
                this.$swal('', response.data.message, 'error')
            })
		},
    },
}
</script>
