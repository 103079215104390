<template>
    <div>
        <!-- Banner -->
        <app-banner pageName="Attendant Care or Companionship Services"></app-banner>
        <!-- End Banner -->

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Services' }" tag="a" property="item" typeof="WebPage" title="Go to Services." class="post post-page">
                                <span property="name">Services</span>
                            </router-link>
                            <meta property="position" content="2">
                        </span> &gt;
                        <span class="post post-page current-item">Attendant Care or Companionship Services</span>
                    </div>
                    <div id="post-47" class="post-47 page type-page status-publish hentry">
                        <div class="entry-content">
                            <div class=intro_txt>We will be happy to visit you at home and provide you with assistance in daily living activities.</div>
                            <p>
                                Certain daily living activities may no longer be as easy because of your health
                                condition. Don’t worry; our personal care attendants and companions can help. They
                                can clean your house, provide assistance with personal care, accompany you wherever
                                you need to be, and more. Whether you need round-the-clock or short-term help, you
                                can rely on us.
                            </p>
                            <figure class="thumb_right_dd">
                                <img src="~@/assets/images/thumbnails/thumb-55895904.jpg" alt="caregiver serving meal to elder woman">
                            </figure>
                            <p>We can also help you with the following:</p>
                            <ul class="bullet">
                                <li>Escort to medical appointments</li>
                                <li>Grocery shopping</li>
                                <li>Meal preparation</li>
                                <li>Medication reminders</li>
                                <li>And more</li>
                            </ul>
                            <p>
                                If you’re a family/primary caregiver, we can also provide you with respite care.
                                We&#8217;ll take over your caregiving duties while you take a break or deal with
                                other responsibilities.
                            </p>
                            <p>
                                <strong>Get in Touch</strong><br />
                                We&#8217;d love to hear from you! Schedule a
                                <router-link :to="{ name: 'FreeInHomeAssessment' }" tag="a">free in-home assessment</router-link>
                                with us so we can personally assess your needs. For more information,
                                <router-link :to="{ name: 'ContactUs' }" tag="a">contact us</router-link>
                                or send us an e-mail at
                                <a href="mailto:admin@agingcarenet.com">admin@agingcarenet.com</a>.
                            </p>
                        </div>
                    </div>
                </main>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/components/layouts/banner'

export default {
    data() {
        return {

        }
    },
    components: {
        appBanner: banner,
    }
}
</script>
