<template>
    <div>

        <!-- Banner -->
        <app-banner pageName="Care Service Aid Application" />
        <!-- End Banner -->

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span class="post post-page current-item">Care Service Aid Application</span>
                    </div>
                    <div id="post-14" class="post-14 page type-page status-publish hentry">
                        <div class="entry-content">
                            <div class=intro_txt>We believe that patience and understanding makes a world of difference.</div>
                            <figure class="thumb_right_dd">
                                <img src="~@/assets/images/thumbnails/thumb-102192060.jpg" alt="caregiver assisting elder man">
                            </figure>
                            <p>
                                We are dedicated to helping you keep your dignity and independence for as long as
                                possible. We assess your condition and develop a personalized care plan to ensure
                                that you get the care that you deserve. We can provide assistance as you transition
                                from hospitalization to companionship as you recover from an injury, and other
                                geriatric care services in PA, making sure that the level of care you receive is
                                tailored to your needs.
                            </p>
                            <p>These are the services that we currently offer:</p>
                            <ul class="bullet">
                                <li>
                                    <router-link :to="{ name: 'RNAndLPNSkilled' }" tag="a">RN and LPN Skilled Home Health Care Services</router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'AttendantCareOrCompanionshipServices' }" tag="a">Attendant Care or Companionship Services</router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'NurseAideTrainingServices' }" tag="a">Nurse Aide Training Services</router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'CPRTrainingServices' }" tag="a">CPR Training Services</router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'CareManagementEnvironmentalModifications' }" tag="a">Care Management Environmental Modifications</router-link>
                                </li>
                            </ul>
                            <p>
                                Do you know someone 21 and older, making less than $2300 a month and has a medical
                                condition or disability that will last more than 6 months? Call us today to apply
                                for services or fill out the form below.
                            </p>

                            <!-- START FORM -->
                            <services-form />
                            <!-- END FORM -->

                            <p style="margin-top: 20px;">
                                <strong>Get in Touch</strong>
                                <br />
                                We&#8217;d love to hear from you! Schedule a
                                <router-link :to="{ name: 'FreeInHomeAssessment' }" tag="a">free in-home assessment</router-link>
                                with us so we can personally assess your needs. For more information,
                                <router-link :to="{ name: 'ContactUs' }" tag="a">contact us</router-link> or send us an e-mail at
                                <a href="mailto:admin@agingcarenet.com">admin@agingcarenet.com</a>.
                            </p>
                        </div>
                    </div>
                </main>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/components/layouts/banner'
import ServicesForm from '@/components/forms/ServicesForm'

export default {
    components: {
        appBanner: banner,
        ServicesForm
    }
}
</script>
