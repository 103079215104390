<template>
    <form id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" novalidate @submit.prevent="handleSubmitForm">

        <alert :alertData="alertData"/>

        <div id="mc_embed_signup_scroll">
            <h2>Contact Us</h2>
            <div class="indicates-required">
                <span class="asterisk">*</span> indicates required
            </div>
            <div class="mc-field-group">
                <label for="mce-FNAME">Full Name <span class="asterisk">*</span></label>
                <input
                    type="text"
                    v-model="contact.clientName"
                    name="FNAME"
                    class="form_field"
                    autocomplete="nope"
                    :class="{ error: submitted && $v.contact.clientName.$error }"
                    id="mce-FNAME"
                />
            </div>
            <div class="mc-field-group">
                <label for="mce-EMAIL">Email Address <span class="asterisk">*</span></label>
                <input
                    type="email"
                    v-model="contact.email"
                    name="EMAIL"
                    class="form_field"
                    autocomplete="nope"
                    :class="{ error: submitted && $v.contact.email.$error }"
                    id="mce-EMAIL"
                />
            </div>
            <div class="mc-field-group">
                <label for="mce-PHONE">Phone Number <span class="asterisk">*</span></label>
                <vue-tel-input
                    v-model="contact.phoneNumber"
                    autocomplete="nope"
                    name="Phone"
                    class="form_field cphone"
                    :inputOptions="{
                        id: 'mce-PHONE'
                    }"
                    :class="{ error: submitted && $v.contact.phoneNumber.$error }"
                    placeholder="+1 (phone number)">
                </vue-tel-input>
            </div>
            <div class="mc-field-group">
                <label for="mce-SUBJECT">Subject <span class="asterisk">*</span></label>
                <input
                    type="text"
                    v-model="contact.subject"
                    name="Subject"
                    class="form_field"
                    autocomplete="nope"
                    :class="{ error: submitted && $v.contact.subject.$error }"
                    id="mce-SUBJECT"
                />
            </div>

            <div class="mc-field-group">
                <label for="mce-referredBy">How did you hear about us?</label>
                <input
                    type="text"
                    v-model="contact.referredBy"
                    name="referredBy"
                    class="form_field"
                    autocomplete="nope"
                    :class="{ error: submitted && $v.contact.referredBy.$error }"
                    id="mce-referredBy"
                />
            </div>

            <div class="mc-field-group">
                <label for="mce-MESSAGE">Message <span class="asterisk">*</span></label>
                <div class="field_holder animated_box">
                    <textarea
                        name="Message"
                        v-model="contact.message"
                        class="text form_field"
                        id="mce-MESSAGE"
                        :class="{ error: submitted && $v.contact.message.$error }">
                    </textarea>
                    <span class="animated_class"></span>
                </div>
            </div>

            <!-- <div class="mc-field-group">
                <label for="mce-LNAME">Last Name </label>
                <input type="text" v-model="contact.lastName" name="LNAME" class="form_field" autocomplete="nope" :class="{ 'error': submitted && $v.contact.lastName.$error }" id="mce-LNAME">
            </div> -->
            <!-- <div class="mc-field-group size1of2">
                <label for="mce-BIRTHDAY-month">Birthday </label>
                <div class="datefield">
                    <span class="subfield monthfield">
                        <input class="birthday form_field" type="text" pattern="[0-9]*" autocomplete="nope" v-model="contact.birthMonth" :class="{ 'error': submitted && $v.contact.birthMonth.$error }" placeholder="MM" size="2" maxlength="2" name="BIRTHDAY[month]" id="mce-BIRTHDAY-month">
                    </span> /
                    <span class="subfield dayfield">
                        <input class="birthday form_field" type="text" pattern="[0-9]*" autocomplete="nope" v-model="contact.birthDay" :class="{ 'error': submitted && $v.contact.birthDay.$error }" placeholder="DD" size="2" maxlength="2" name="BIRTHDAY[day]" id="mce-BIRTHDAY-day">
                    </span>
                    <span class="small-meta nowrap">( mm / dd )</span>
                </div>
            </div> -->

            <div class="clear secode_box">
                <button type="submit" class="button form_button" :disabled="loading">Subscribe</button>
            </div>
        </div>
    </form>
</template>

<script>
import alert from '@/components/layouts/alert'
import { postNewContact } from '@/api/apiCalls'
import { getUserAgent, getUserIp } from '@/helpers'
import { required, email } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            contact: {},
            submitted: false,
            loading: false,
            alertData: {
                showAlert: false,
                alertType: 'success',
                alertText: 'Data Added Successfully!'
            }
        }
    },
    components: {
        alert
    },
    validations: {
        contact: {
            email: { required, email },
            clientName: { required },
            phoneNumber: { required },
            subject: { required },
            message: { required },
            referredBy: {},
            // firstName: { required },
            // lastName: { required },
            // birthDay: { required, between: between(1, 31) },
            // birthMonth: { required, between: between(1, 12) },
        }
    },
    methods: {
        handleSubmitForm() {
            this.submitted = true

            // stop here if form is invalid
            this.$v.$touch()

            // Scroll To Error
            this.$nextTick(() => {
                let element = document.querySelector('.form_field.error')
                if (element) {
                    let domRect = element.getBoundingClientRect()
                    window.scrollTo({
                        left: domRect.left + document.documentElement.scrollLeft,
                        top: domRect.top + (document.documentElement.scrollTop - 100),
                        behavior: 'smooth'
                    })
                }
            })

            if (!this.$v.$invalid) {
                this.loading = true

                const data = {
					// firstName: this.contact.firstName,
					// lastName: this.contact.lastName,
					clientName: this.contact.clientName,
                    phoneNumber: this.contact.phoneNumber,
					subject: this.contact.subject,
					message: this.contact.message,
					email: this.contact.email,
                    referredBy: this.contact.referredBy,
					// birthDate: `${this.contact.birthDay}/${this.contact.birthMonth}`,
					userAgent: getUserAgent(),
					ip: getUserIp()
                }

                postNewContact(data).then(() => {
                    this.alertData.showAlert = true;
                    this.loading = false;
                    this.submitted = false;
                    this.$router.push({
                        name: 'Confirmation'
                    })
                    this.contact = {}
                    setTimeout(() => {
                        this.alertData.showAlert = false
                    }, 3000)
                }).catch(({ response }) => {
                    this.alertData = {
                        showAlert: true,
                        alertType: 'danger',
                        alertText: response.data.errors.email[0]
                    }
                    this.loading = false
                    this.submitted = false
                    setTimeout(() => {
                        this.alertData.showAlert = false
                    }, 3000)
                })
            }
        }
    }
}
</script>

<style scoped>
/* MailChimp Form Embed Code - Classic - 12/17/2015 v10.7 */
#mc_embed_signup form {display:block; position:relative; text-align:left; padding:10px 0 10px 3%}
#mc_embed_signup h2 {font-weight:bold; padding:0; margin:15px 0; font-size:1.4em;}
#mc_embed_signup input {border: 1px solid #ABB0B2; -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px;}
#mc_embed_signup input[type=checkbox]{-webkit-appearance:checkbox;}
#mc_embed_signup input[type=radio]{-webkit-appearance:radio;}
#mc_embed_signup input:focus {border-color:#333;}
/* #mc_embed_signup .button {clear:both; background-color: #aaa; border: 0 none; border-radius:4px; transition: all 0.23s ease-in-out 0s; color: #FFFFFF; cursor: pointer; display: inline-block; font-size:15px; font-weight: normal; height: 32px; line-height: 32px; margin: 0 5px 10px 0; padding: 0 22px; text-align: center; text-decoration: none; vertical-align: top; white-space: nowrap; width: auto;} */
/* #mc_embed_signup .button:hover {background-color:#777;} */
#mc_embed_signup .small-meta {font-size: 11px;}
#mc_embed_signup .nowrap {white-space:nowrap;}

#mc_embed_signup .mc-field-group {clear:left; position:relative; width:96%; padding-bottom:3%; min-height:50px;}
#mc_embed_signup .size1of2 {clear:none; float:left; display:inline-block; width:46%; margin-right:4%;}
* html #mc_embed_signup .size1of2 {margin-right:2%; /* Fix for IE6 double margins. */}
#mc_embed_signup .mc-field-group label {display:block; margin-bottom:3px;}
#mc_embed_signup .mc-field-group input {display:block; width:100%; padding:8px 0; text-indent:2%;}
#mc_embed_signup .mc-field-group select {display:inline-block; width:99%; padding:5px 0; margin-bottom:2px;}

#mc_embed_signup .datefield, #mc_embed_signup .phonefield-us{padding:5px 0;}
#mc_embed_signup .datefield input, #mc_embed_signup .phonefield-us input{display:inline; width:60px; margin:0 2px; letter-spacing:1px; text-align:center; padding:5px 0 2px 0;}
#mc_embed_signup .phonefield-us .phonearea input, #mc_embed_signup .phonefield-us .phonedetail1 input{width:40px;}
#mc_embed_signup .datefield .monthfield input, #mc_embed_signup .datefield .dayfield input{width:30px;}
#mc_embed_signup .datefield label, #mc_embed_signup .phonefield-us label{display:none;}

#mc_embed_signup .indicates-required {text-align:right; font-size:11px; margin-right:4%;}
#mc_embed_signup .asterisk {color:#e85c41; font-size:150%; font-weight:normal; position:relative; top:5px;}
#mc_embed_signup .clear {clear:both;}

#mc_embed_signup .mc-field-group.input-group ul {margin:0; padding:5px 0; list-style:none;}
#mc_embed_signup .mc-field-group.input-group ul li {display:block; padding:3px 0; margin:0;}
#mc_embed_signup .mc-field-group.input-group label {display:inline;}
#mc_embed_signup .mc-field-group.input-group input {display:inline; width:auto; border:none;}

#mc_embed_signup div#mce-responses {float:left; top:-1.4em; padding:0em .5em 0em .5em; overflow:hidden; width:90%; margin: 0 5%; clear: both;}
#mc_embed_signup div.response {margin:1em 0; padding:1em .5em .5em 0; font-weight:bold; float:left; top:-1.5em; z-index:1; width:80%;}
#mc_embed_signup #mce-error-response {display:none;}
#mc_embed_signup #mce-success-response {color:#529214; display:none;}
#mc_embed_signup label.error {display:block; float:none; width:auto; margin-left:1.05em; text-align:left; padding:.5em 0;}

/* #mc-embedded-subscribe {clear:both; width:auto; display:block; margin:1em 0 1em 5%;} */
#mc_embed_signup #num-subscribers {font-size:1.1em;}
#mc_embed_signup #num-subscribers span {padding:.5em; border:1px solid #ccc; margin-right:.5em; font-weight:bold;}

#mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {display:inline-block; margin:2px 0 1em 0; padding:5px 10px; background-color:rgba(255,255,255,0.85); -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; font-size:14px; font-weight:normal; z-index:1; color:#e85c41;}
#mc_embed_signup #mc-embedded-subscribe-form input.mce_inline_error {border:2px solid #e85c41;}
</style>
