<template>
    <div>
        <!-- Banner -->
        <app-banner pageName="Insurance Accepted"></app-banner>
        <!-- End Banner -->

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span class="post post-page current-item">Insurance Accepted</span>
                    </div>
                    <div id="post-34" class="post-34 page type-page status-publish hentry">
                        <div class="entry-content">
                            <figure class="thumb_right_dd">
                                <img src="~@/assets/images/thumbnails/thumb-117434271.jpg" alt="insurance concept">
                            </figure>
                            <p>We want to make sure that you can afford the quality care that you deserve. We currently accept private pay and insurance reimbursements through:</p>
                            <ul class="bullet">
                                <li>Medicare</li>
                                <li>Medicaid</li>
                                <li>VA insurance MCOs such as Keystone , PA Health and Wellness, UPMC etc.</li>
                            </ul>
                            <p>
                                <strong>Get in Touch</strong><br />
                                For inquiries about other payment options, don’t hesitate to
                                <router-link :to="{ name: 'ContactUs' }" tag="a">contact us</router-link>
                                at any time. We&#8217;d love to hear from you! You may also schedule a
                                <router-link :to="{ name: 'FreeInHomeAssessment' }" tag="a">free in-home assessment</router-link>
                                with us so we can personally assess your needs.
                            </p>
                        </div>
                    </div>
                </main>
                <div class="clearfix"></div>
            </div>
        </div>

    </div>
</template>

<script>
import banner from '@/components/layouts/banner'

export default {
    data() {
        return {

        }
    },
    components: {
        appBanner: banner,
    }
}
</script>
