<template>
    <div>
        <!-- Banner -->
        <app-banner pageName="RN and LPN Skilled Home Health Care Services"></app-banner>
        <!-- End Banner -->

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Services' }" tag="a" property="item" typeof="WebPage" title="Go to Services." class="post post-page">
                                <span property="name">Services</span>
                            </router-link>
                            <meta property="position" content="2">
                        </span> &gt;
                        <span class="post post-page current-item">RN and LPN Skilled Home Health Care Services</span>
                    </div>
                    <div id="post-45" class="post-45 page type-page status-publish hentry">
                        <div class="entry-content">
                            <div class=intro_txt>Let us help you reduce the chances of hospital re-admission and health complications.</div>
                            <p>
                                We want to help you or your loved ones live in comfort and enhance your quality of
                                life as you recover or manage your condition. For your wellness and convenience, our
                                reliable nurses and other healthcare professionals can bring the skilled help that
                                you need straight to your home. We understand that staying at home may be the best
                                option for you. Rest assured, we’ll create a personalized care plan tailored to your
                                needs.
                            </p>
                            <figure class="thumb_right_dd">
                                <img src="~@/assets/images/thumbnails/thumb-695046409.jpg" alt="caregiver working with elder patient in clinic">
                            </figure>
                            <p>The skilled services that we offer include:</p>
                            <ul class="bullet">
                                <li>Injections</li>
                                <li>Intravenous or nutrition therapy</li>
                                <li>Monitoring serious illness and unstable health status</li>
                                <li>Patient and caregiver education</li>
                                <li>Physical and occupational therapy</li>
                                <li>Speech Therapy</li>
                                <li>Wound care for pressure sores or a surgical wound</li>
                                <li>And more</li>
                            </ul>
                            <p>
                                <strong>Get in Touch</strong><br />
                                We&#8217;d love to hear from you! Schedule a
                                <router-link :to="{ name: 'FreeInHomeAssessment' }" tag="a">free in-home assessment</router-link>
                                with us so we can personally assess your needs. For more information,
                                <router-link :to="{ name: 'ContactUs' }" tag="a">contact us</router-link>
                                or send us an e-mail at
                                <a href="mailto:admin@agingcarenet.com">admin@agingcarenet.com</a>.
                            </p>
                        </div>
                    </div>
                </main>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/components/layouts/banner'

export default {
    data() {
        return {

        }
    },
    components: {
        appBanner: banner,
    }
}
</script>
