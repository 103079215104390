<template>
    <div>
        <!-- Banner -->
        <app-banner pageName="Free In-Home Assessment"></app-banner>
        <!-- End Banner -->

        <!-- Page Content -->
        <div id="main_area">
			<div class="wrapper">
                <main>
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span class="post post-page current-item">Free In-Home Assessment</span>
                    </div>
                    <div id="post-28" class="post-28 page type-page status-publish hentry">
                        <div class="entry-content">
                            <p>There is no charge for the evaluation of your home health needs. Schedule a free in-home assessment with our registered nurse by filling out the form below.</p>

                            <!-- START FORM -->
                            <free-in-home-assessment-form />
                            <!-- END FORM -->

                        </div>
                    </div>
                </main>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/components/layouts/banner'
import FreeInHomeAssessmentForm from '@/components/forms/FreeInHomeAssessmentForm'

export default {
    components: {
        appBanner: banner,
        FreeInHomeAssessmentForm,
    }
}
</script>
