<template>
    <div id="banner">
        <div class="wrapper">
            <div class="non_ban">
                <figure>
                    <img v-if="pageName == 'Privacy Policy'" src="~@/assets/images/privacy-policy.png" alt="">
                    <img v-else-if="(pageName == 'CPR Training Services') || (type == 'cpr-training-services')" src="~@/assets/images/cpr.png" alt="">
                    <img v-else-if="pageName == 'Photo Gallery'" src="~@/assets/images/gallery.png" alt="">
                    <img v-else-if="pageName == 'Nurse Aide Training Services'" src="~@/assets/images/education-header.png" alt="">
                    <img v-else-if="pageName == 'LPN Program'" src="~@/assets/images/lpn-program.png" alt="">
                    <img v-else src="~@/assets/images/slider/non_home.jpg" alt="caregiver and elder man smiling">
                </figure>
                <div class="page_title">
                    <h1 class="h1_title">{{ pageName }}</h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props : {
        pageName: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: false,
            default: 'nurse-aide-training-services'
        }
    }
}
</script>
