<template>
    <div>
        <!-- Banner -->
        <app-banner pageName="Care Management Environmental Modifications"></app-banner>
        <!-- End Banner -->

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Services' }" tag="a" property="item" typeof="WebPage" title="Go to Services." class="post post-page">
                                <span property="name">Services</span>
                            </router-link>
                            <meta property="position" content="2">
                        </span> &gt;
                        <span class="post post-page current-item">Care Management Environmental Modifications</span>
                    </div>
                    <div id="post-52" class="post-52 page type-page status-publish hentry">
                        <div class="entry-content">
                            <div class=intro_txt>Trust our reliable care manager to coordinate your short or long-term care arrangement.</div>
                            <figure class="thumb_right_dd">
                                <img src="~@/assets/images/thumbnails/thumb-1172233891.jpg" alt="caregiver caring for elder man">
                            </figure>
                            <p>
                                Whether you need short or long-term care, we can make sure that your care arrangement
                                is tailored to your needs. Our care management assistance includes telephone
                                consultations to taking total care of your assets, as well as contacting persons as
                                your trusted legal guardian, and more.
                            </p>
                            <p>
                                Also, we give utmost priority to ensuring your safety. We can protect you from abuse
                                or potential abuse, whether at home or your previous care facility. Let us conduct
                                an investigation and report to the authorities, if necessary.
                            </p>
                            <p>
                                <strong>Get in Touch</strong><br />
                                We&#8217;d love to hear from you! Schedule a
                                <router-link :to="{ name: 'FreeInHomeAssessment' }" tag="a">free in-home assessment</router-link>
                                with us so we can personally assess your needs. For more information,
                                <router-link :to="{ name: 'ContactUs' }" tag="a">contact us</router-link>
                                or send us an e-mail at
                                <a href="mailto:admin@agingcarenet.com">admin@agingcarenet.com</a>.
                            </p>
                        </div>
                    </div>
                </main>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/components/layouts/banner'

export default {
    data() {
        return {

        }
    },
    components: {
        appBanner: banner,
    }
}
</script>
