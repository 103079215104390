/* eslint-disable */
import Vue from 'vue'

//
import 'es6-promise/auto'

// VUEX
import Vuex from 'vuex'
Vue.use(Vuex)

// MAIN COMPONENT
import App from './App.vue'

// ROUTING
import router from './router'

// STORE
import store from './store'

//
import { apiDomain } from '@/api/apiConfig'

// PHONE PACKAGE
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
Vue.use(VueTelInput, {
    preferredCountries: ['us', 'gb'],
    validCharactersOnly: true,
})

// IMAGE POPUP
import VueImg from 'v-img'
Vue.use(VueImg)

// DATEPICKER PACKAGE
import Datepicker from 'vuejs-datepicker'
Vue.component('datepicker', Datepicker)

// VALIDATE PACKAGE
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

// SLIDER PACKAGE
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)

// SWEETALERT
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
Vue.use(VueSweetalert2)

// Get Client IP
// TODO: Change to https before upload
fetch('https://api.ipify.org?format=json').then(function (response) {
    return response.json()
}).then(function (data) {
    localStorage.setItem('userIp', data.ip)
})

// CONFIG
Vue.config.productionTip = false

//
import uploadcare from 'uploadcare-widget/uploadcare.lang.en.min.js'
import uploadcareTabEffects from 'uploadcare-widget-tab-effects/dist/uploadcare.tab-effects.lang.en.min.js'

Vue.mixin({
    data() {
        return {
            apiDomain,
            uploadCareAPI: '42cce51128fd6b47d3d2',
        }
    },
    methods: {
        initUploadcare(element, multiple = false) {
            if (document.querySelector(element)) {

                uploadcare.registerTab('preview', uploadcareTabEffects)

                const widget = uploadcare.Widget(element, {
                    publicKey: this.uploadCareAPI,
                    multiple,
                    metadata: {
                        name: element.replace(/[.#]/g, '') // .toLowerCase()
                    },
                    multipleMax: 1,
                    tabs: 'all',
                    crop: 'free, 16:9, 4:3, 5:4, 1:1',
                    clearable: true,
                    doNotStore: true,
                    imagesOnly: true,
                    previewStep: true,
                    debugUploads: true,
                    inputAcceptTypes: 'image/*',
                    effects: 'crop, flip, enhance, grayscale, blur, rotate, mirror, sharp, invert'
                })

                if (widget) {

                    // Change Widget Button Name
                    widget.inputElement.nextSibling.querySelector(".uploadcare--widget__button_type_open").innerHTML = widget.inputElement.getAttribute('data-btn-text')

                    // Make Preview Widget
                    const preview = document.querySelector(`.${element.replace(/[.#]/g, '')}-preview`)

                    // Open dialog on click on preview area
                    if (preview) {
                        preview.addEventListener('click', () => {
                            widget.openDialog()
                        })

                        // Upload with the widget
                        widget.onChange(file => {
                            if (file) {
                                file.done(fileInfo => {
                                    preview.style.backgroundImage = `url(${fileInfo.cdnUrl}-/format/auto/-/resize/600x/)`
                                    preview.classList.remove('empty')
                                });
                            } else { // Clear preview if file removed from widget
                                preview.style.backgroundImage = 'none'
                                preview.classList.add('empty')
                            }
                        })

                        // Drag and drop upload
                        uploadcare.dragdrop.uploadDrop(preview, file => {
                            widget.value(file)
                        })
                    }

                    // Listen WHen Upload Finish
                    widget.onUploadComplete(file => {
                        this.documents[file.metadata.name] = file.cdnUrl // -/resize/600x/ * IF YOU NEED TO RESIZE IMAGE

                        // Reset Uploader
                        // 	widget.value(null)
                    })
                }
            }
        },
        scrollToError(className = '.form_field.error') {
            this.$nextTick(() => {
                let element = document.querySelector(className)
                if (element) {
                    let domRect = element.getBoundingClientRect()
                    window.scrollTo({
                        left: domRect.left + document.documentElement.scrollLeft,
                        top: domRect.top + (document.documentElement.scrollTop - 100),
                        behavior: 'smooth',
                    })
                }
            })
        },
    },
})

new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')
