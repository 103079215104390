<template>
    <div>

        <!-- Banner -->
        <banner pageName="Photo Gallery" />

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span class="post post-page current-item">Photo Gallery</span>
                    </div>

                    <div id="post-36" class="post-36 page type-page status-publish hentry">
                        <div class="entry-content">
                            <!-- <p>Check out our collection of photos to get a glimpse of the quality care that we deliver!</p> -->

                            <!-- <div id="P_MS5cfcd2220110f" class="master-slider-parent msl ms-parent-id-1" style="max-width:800px;">
                                <div id="MS5cfcd2220110f" class="master-slider ms-skin-default">

                                    <swiper :options="swiperOptionTop" class="gallery-top" ref="swiperTop">
                                        <swiper-slide class="ms-slide">
                                            <img src="~@/assets/images/2019/04/4.jpg" alt="image">
                                        </swiper-slide>
                                        <swiper-slide class="ms-slide">
                                            <img src="~@/assets/images/2019/04/5.jpg" alt="image">
                                        </swiper-slide>
                                        <swiper-slide class="ms-slide">
                                            <img src="~@/assets/images/2019/04/2.jpg" alt="image">
                                        </swiper-slide>
                                        <swiper-slide class="ms-slide">
                                            <img src="~@/assets/images/2019/04/3.jpg" alt="image">
                                        </swiper-slide>
                                        <swiper-slide class="ms-slide">
                                            <img src="~@/assets/images/2019/04/1.jpg" alt="image">
                                        </swiper-slide>
                                        <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                                        <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
                                    </swiper>

                                    <swiper :options="swiperOptionThumbs" class="gallery-thumbs" ref="swiperThumbs">
                                        <swiper-slide class="ms-slide">
                                            <img class="ms-thumb" src="~@/assets/images/2019/04/4-140x80.jpg" alt="image" />
                                        </swiper-slide>
                                        <swiper-slide class="ms-slide">
                                            <img class="ms-thumb" src="~@/assets/images/2019/04/5-140x80.jpg" alt="image" />
                                        </swiper-slide>
                                        <swiper-slide class="ms-slide">
                                            <img class="ms-thumb" src="~@/assets/images/2019/04/2-140x80.jpg" alt="image" />
                                        </swiper-slide>
                                        <swiper-slide class="ms-slide">
                                            <img class="ms-thumb" src="~@/assets/images/2019/04/3-140x80.jpg" alt="image" />
                                        </swiper-slide>
                                        <swiper-slide class="ms-slide">
                                            <img class="ms-thumb" src="~@/assets/images/2019/04/1-140x80.jpg" alt="image" />
                                        </swiper-slide>
                                    </swiper>
                                </div>
                            </div> -->

                            <div class="row justify-content-center mt-5">
                                <div class="col-6" v-for="(image, i) in images" :key="i">
                                    <img class="image" :src="image" v-img:gallery :alt="image">
                                </div>
                            </div>

                            <div class="row clearfix">
                                <infinite-loading @infinite="infiniteHandler"></infinite-loading>
                            </div>

                        </div>
                    </div>
                </main>
                <div class="clearfix"></div>
            </div>
        </div>

    </div>
</template>

<script>
/* eslint-disable */
import { getGallery } from '@/api/apiCalls'
import Banner from '@/components/layouts/banner'
import InfiniteLoading from 'vue-infinite-loading'

export default {
    data() {
        return {
            page: 1,
            pages: 1,
            images: [],
            perPage: 20,
            loading: false,
            swiperOptionTop: {
                spaceBetween: 10,
                loop: true,
                loopedSlides: 5, //looped slides should be the same
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            swiperOptionThumbs: {
                spaceBetween: 10,
                slidesPerView: 4,
                touchRatio: 0.2,
                loop: true,
                loopedSlides: 5, // looped slides should be the same
                slideToClickedSlide: true,
            }
        }
    },
    components: {
        Banner,
        InfiniteLoading,
    },
    mounted () {
        // this.$nextTick(() => {
        //     const swiperTop = this.$refs.swiperTop.swiper
        //     const swiperThumbs = this.$refs.swiperThumbs.swiper
        //     swiperTop.controller.control = swiperThumbs
        //     swiperThumbs.controller.control = swiperTop
        // })
    },
    methods: {
        infiniteHandler($state) {
            getGallery({ perPage: this.perPage, page: this.page }).then(({ data }) => {
                if (data.data.length) {
                    this.images.push(...data.data)
                    this.page += 1
                    $state.loaded()
                } else {
                    $state.complete()
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.swiper-container {
    background-color: #000;
}
.swiper-slide {
    background-size: cover;
    background-position: center;
}
.gallery-top {
    height: 80%!important;
    width: 100%;
}
.gallery-thumbs {
    height: 20%!important;
    box-sizing: border-box;
    padding: 10px 0;
}
.gallery-thumbs .swiper-slide {
    width: 20%;
    margin: 0 5px!important;
    height: 100%;
    opacity: 0.4;
}
.gallery-thumbs .swiper-slide-active {
    opacity: 1;
}
.master-slider-parent.msl.ms-parent-id-1 {
    margin: 0 auto;
    text-align: center;
}

$width: 96%;
$gutter: 4%;
$breakpoint-small: 33.75em; // 540px
$breakpoint-med: 45em; // 720px
$breakpoint-large: 60em; // 960px

.container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (min-width: $breakpoint-small) {
        width: 80%;
    }

    @media only screen and (min-width: $breakpoint-large) {
        width: 75%;
        max-width: 60rem;
    }
}

.row {
    position: relative;
    width: 100%;
}

.row [class^="col"] {
    float: left;
    margin: 0.5rem;
    min-height: 0.125rem;
}

.row::after {
    content: "";
    display: table;
    clear: both;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
    width: $width;
}

.col-1-sm { width:($width / 12) - ($gutter * 11 / 12); }
.col-2-sm { width: ($width / 6) - ($gutter * 10 / 12); }
.col-3-sm { width: ($width / 4) - ($gutter * 9 / 12); }
.col-4-sm { width: ($width / 3) - ($gutter * 8 / 12); }
.col-5-sm { width: ($width / (12 / 5)) - ($gutter * 7 / 12); }
.col-6-sm { width: ($width / 2) - ($gutter * 6 / 12); }
.col-7-sm { width: ($width / (12 / 7)) - ($gutter * 5 / 12); }
.col-8-sm { width: ($width / (12 / 8)) - ($gutter * 4 / 12); }
.col-9-sm { width: ($width / (12 / 9)) - ($gutter * 3 / 12); }
.col-10-sm { width: ($width / (12 / 10)) - ($gutter * 2 / 12); }
.col-11-sm { width: ($width / (12 / 11)) - ($gutter * 1 / 12); }
.col-12-sm { width: $width; }

@media only screen and (min-width: $breakpoint-med) {
    .col-1 { width:($width / 12) - ($gutter * 11 / 12); }
    .col-2 { width: ($width / 6) - ($gutter * 10 / 12); }
    .col-3 { width: ($width / 4) - ($gutter * 9 / 12); }
    .col-4 { width: ($width / 3) - ($gutter * 8 / 12); }
    .col-5 { width: ($width / (12 / 5)) - ($gutter * 7 / 12); }
    .col-6 { width: ($width / 2) - ($gutter * 6 / 12); }
    .col-7 { width: ($width / (12 / 7)) - ($gutter * 5 / 12); }
    .col-8 { width: ($width / (12 / 8)) - ($gutter * 4 / 12); }
    .col-9 { width: ($width / (12 / 9)) - ($gutter * 3 / 12); }
    .col-10 { width: ($width / (12 / 10)) - ($gutter * 2 / 12); }
    .col-11 { width: ($width / (12 / 11)) - ($gutter * 1 / 12); }
    .col-12 { width: $width; }
}
</style>
