<template>
    <div>
        <!-- Banner -->
        <app-banner pageName="Meet Our Staff"></app-banner>
        <!-- End Banner -->

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span class="post post-page current-item">Meet Our Staff</span>
                    </div>
                    <div id="post-30" class="post-30 page type-page status-publish hentry">
                        <div class="entry-content">
                            <div class=intro_txt>A dependable team to care for you!</div>
                            <p>
                                Our reliable staff of compassionate care professionals specializes in the care of the
                                geriatric population. We are composed of experts from various healthcare
                                disciplines. Some of the individuals who will always be ready to answer your call
                                and attend to your needs include:
                            </p>
                            <figure class="thumb_right_dd">
                                <img src="~@/assets/images/thumbnails/thumb-45014867.jpg" alt="smiling nurse in front of her medical team">
                            </figure>
                            <ul class="bullet">
                                <li>Care Coordinators</li>
                                <li>Case Managers</li>
                                <li>Certified Nursing Assistants</li>
                                <li>Home Health Aides</li>
                                <li>Medical Social Workers</li>
                                <li>Occupational Therapists</li>
                                <li>Physical Therapists</li>
                                <li>Registered Nurses</li>
                                <li>Speech Therapists</li>
                            </ul>
                            <p>
                                <strong>Get in Touch</strong><br />
                                We&#8217;d love to hear from you! Schedule a
                                <router-link :to="{ name: 'FreeInHomeAssessment' }" tag="a">free in-home assessment</router-link>
                                with us so we can personally assess your needs. For more information,
                                <router-link :to="{ name: 'ContactUs' }" tag="a">contact us</router-link> or send us an e-mail at
                                <a href="mailto:admin@agingcarenet.com">admin@agingcarenet.com</a>.
                            </p>
                        </div>
                    </div>
                </main>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/components/layouts/banner'

export default {
    data() {
        return {

        }
    },
    components: {
        appBanner: banner,
    }
}
</script>
