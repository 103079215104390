<template>
    <div>
        <!-- Banner -->
        <app-banner pageName="Careers" />
        <!-- End Banner -->

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span class="post post-page current-item">Careers</span>
                    </div>
                    <div id="post-12" class="post-12 page type-page status-publish hentry">
                        <div class="entry-content">
                            <p>
                                Join our excellent team if you want to have a career where you can grow
                                professionally. Fill out this form to apply. If you qualify for a position, you will
                                be shortlisted for an interview.
                            </p>

                            <!-- START FORM -->
                            <careers-form />
                            <!-- END FORM -->

                        </div>
                    </div>
                </main>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/components/layouts/banner'
import CareersForm from '@/components/forms/CareersForm'

export default {
    components: {
        appBanner: banner,
        CareersForm
    }
}
</script>
