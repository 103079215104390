<template>
    <div>
        <header :class="{ 'home-header': homeHeader }" id="main-header">
            <div class="wrapper">
                <div class="header_con">
                    <!-- <div class="main_logo">
                        <router-link :to="{ name: 'Home' }" tag="a">
                            <figure>
                                <img src="~@/assets/images/main_logo.png" alt="Successful Aging Home Health" />
                            </figure>
                        </router-link>
                    </div> -->

                    <!-- <div class="header_info">
                        <div class="social_media">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/Successful-Aging-Home-Health-1834184990134677/" target="_blank">
                                        <figure>
                                            <img src="~@/assets/images/icon_fb.png" alt="facebook" />
                                        </figure>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.twitter.com/successagingHH" target="_blank">
                                        <figure>
                                            <img src="~@/assets/images/icon_tw.png" alt="twitter" />
                                        </figure>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://plus.google.com/b/117079547268548121252/+Agingcarenet-homecare-in-pennsylvania" target="_blank">
                                        <figure>
                                            <img src="~@/assets/images/gplus.png" alt="google plus" />
                                        </figure>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.pinterest.com/successagingHH/" target="_blank">
                                        <figure>
                                            <img src="~@/assets/images/pinterest.png" alt="pinterest" />
                                        </figure>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="header_hdng">
                            <div class="widget-container classic-textwidget custom-classic-textwidget">
                                <div class="classic-text-widget">
                                    <h2>Call Us: <br> <mark>888-960-4090 <br> 215-710-0098</mark></h2>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="clearfix"></div>
                </div>
            </div>
        </header>
        <!-- End Header -->

        <!-- Navigation -->
        <div id="nav_area">
            <div class="nav_toggle_button">
                <div class="logo_wrap"></div>
                <div v-if="showFooterLogo" class="main_logo">
                    <router-link :to="{ name: 'Home' }" tag="a">
                        <figure>
                            <img src="~@/assets/images/main_logo.png" alt="Successful Aging Home Health" />
                        </figure>
                    </router-link>
                </div>
                <div class="toggle_holder" @click="showSidebar = true;">
                    <div class="hamburger hamburger--spin-r">
                        <div class="hamburger-box">
                            <div class="hamburger-inner"></div>
                        </div>
                    </div>
                    <small>Menu</small>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="toggle_right_nav" :class="{ 'toggle_right_cont': showSidebar }">
                <nav class="page_nav" :class="{ 'toggle_right_style': showSidebar, 'is-education-nav': !showFooterLogo }">
                    <div class="menu_slide_right">
                        <router-link v-if="showFooterLogo" :to="{ name: 'Home' }" tag="a" class="logo_slide_right">
                            <figure>
                                <img src="~@/assets/images/main_logo.png" alt="Successful Aging Home Health" />
                            </figure>
                        </router-link>
                        <div class="toggle_holder" @click="showSidebar = false;">
                            <div class="hamburger hamburger--spin-r" :class="{ 'is-active': showSidebar }">
                                <div class="hamburger-box">
                                    <div class="hamburger-inner"></div>
                                </div>
                            </div>
                            <small>Close</small>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="wrapper">
                        <div class="nav-menu">
                            <ul id="menu-main-menu" class="menu">

                                <li v-if="showFooterLogo" id="small-logo" :class="{ 'current-menu-item current_page_item': isCurrentComponent('Home')}" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-22"> <!-- current-menu-item current_page_item  page_item page-item-8 -->
                                    <router-link
                                        @click.native="showSidebar = false;"
                                        :to="{ name: 'Home' }"
                                        tag="a">
                                        <img style="width: 100px; margin-top: -30px;" src="~@/assets/images/small_logo.png" alt="Successful Aging Home Health" />
                                    </router-link>
                                </li>
                                <li id="menu-item-22" :class="{ 'current-menu-item current_page_item': isCurrentComponent('Home')}" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-22"> <!-- current-menu-item current_page_item  page_item page-item-8 -->
                                    <router-link @click.native="showSidebar = false;" :to="{ name: 'Home' }" tag="a">
                                        Home
                                        <!-- <small>Welcome Page</small> -->
                                    </router-link>
                                    <!-- <span>
                                        <i class="fa fa-2x">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                                    </span> -->
                                </li>

                                <template v-if="isHomeCareSection">

                                    <li id="menu-item-18" :class="{ 'current-menu-item current_page_item': isCurrentComponent('AboutUs')}" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-18">
                                        <router-link @click.native="showSidebar = false;" :to="{ name: 'AboutUs' }" tag="a">
                                            About Us
                                            <!-- <small>Who We Are</small> -->
                                        </router-link>
                                        <!-- <span>
                                            <i class="fa fa-2x">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                                        </span> -->
                                    </li>

                                    <li id="menu-item-19" :class="{ 'current-menu-item current_page_item': isCurrentComponent('Services')}" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-19">
                                        <router-link @click.native="showSidebar = false;" :to="{ name: 'Services' }" tag="a">
                                            <!-- Home
                                            <small> -->
                                                Care Services
                                            <!-- </small> -->
                                        </router-link>
                                        <!-- <span>
                                            <i class="fa fa-2x">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                                        </span> -->
                                    </li>

                                    <li id="menu-item-20" :class="{ 'current-menu-item current_page_item': isCurrentComponent('Careers')}" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-20">
                                        <router-link @click.native="showSidebar = false;" :to="{ name: 'Careers' }" tag="a">
                                            Careers
                                            <!-- <small>Join Us Now</small> -->
                                        </router-link>
                                        <!-- <span>
                                            <i class="fa fa-2x">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                                        </span> -->
                                    </li>

                                    <li id="menu-item-21" :class="{ 'current-menu-item current_page_item': isCurrentComponent('ContactUs')}" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-21">
                                        <router-link @click.native="showSidebar = false;" :to="{ name: 'ContactUs' }" tag="a">
                                            Contact Us
                                            <!-- <small>Keep in Touch</small> -->
                                        </router-link>
                                        <!-- <span>
                                            <i class="fa fa-2x">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                                        </span> -->
                                    </li>

                                </template>

                                <template v-if="isEducationSection">

                                    <li id="menu-item-150" :class="{ 'current-menu-item current_page_item': isCurrentComponent('NurseAideTrainingServices')}" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-150">
                                        <router-link @click.native="showSidebar = false;" :to="{ name: 'NurseAideTrainingServices' }" tag="a">
                                            Nurse Aide
                                            <!-- <small>Training Services</small> -->
                                        </router-link>
                                        <!-- <span>
                                            <i class="fa fa-2x">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                                        </span> -->
                                    </li>

                                    <!-- <li id="menu-item-150" :class="{ 'current-menu-item current_page_item': isCurrentComponent('LPNProgram') }" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-150">
                                        <router-link @click.native="showSidebar = false;" :to="{ name: 'LPNProgram' }" tag="a">
                                            LPN Program
                                        </router-link>
                                    </li> -->

                                    <li id="menu-item-150" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-150">
                                        <a @click="showSidebar = false;" href="https://api.agingcarenet.com/revised-saci-catalouge-2022.pdf" tag="a" download>Catalog</a>
                                    </li>

                                    <li id="menu-item-150" :class="{ 'current-menu-item current_page_item': isCurrentComponent('CPRTrainingServices')}" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-150">
                                        <router-link @click.native="showSidebar = false;" :to="{ name: 'CPRTrainingServices' }" tag="a">
                                            CPR
                                            <!-- <small>Training Services</small> -->
                                        </router-link>
                                        <!-- <span>
                                            <i class="fa fa-2x">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                                        </span> -->
                                    </li>

                                    <li id="menu-item-21" :class="{ 'current-menu-item current_page_item': isCurrentComponent('Store')}" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-21">
                                        <router-link @click.native="showSidebar = false;" :to="{ name: 'Store' }" tag="a">
                                            Store
                                            <!-- <small>Asked Questions</small> -->
                                        </router-link>
                                        <!-- <span>
                                            <i class="fa fa-2x">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                                        </span> -->
                                    </li>

                                    <li id="menu-item-21" :class="{ 'current-menu-item current_page_item': isCurrentComponent('FAQ')}" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-21">
                                        <router-link @click.native="showSidebar = false;" :to="{ name: 'FAQ' }" tag="a">
                                            FAQ
                                            <!-- <small>Asked Questions</small> -->
                                        </router-link>
                                        <!-- <span>
                                            <i class="fa fa-2x">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                                        </span> -->
                                    </li>

                                </template>


                            </ul>
                        </div>
                    </div>
                </nav>
                <div class="toggle_nav_close"></div>
            </div>
        </div>

        <!-- <header id="responsive-header">
            <div class="wrapper">
                <div class="header_con">
                    <div class="header_info">
                        <div class="social_media">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/Successful-Aging-Home-Health-1834184990134677/" target="_blank">
                                        <figure>
                                            <img src="~@/assets/images/icon_fb.png" alt="facebook">
                                        </figure>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.twitter.com/successagingHH" target="_blank">
                                        <figure>
                                            <img src="~@/assets/images/icon_tw.png" alt="twitter">
                                        </figure>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://plus.google.com/b/117079547268548121252/+Agingcarenet-homecare-in-pennsylvania" target="_blank">
                                        <figure>
                                            <img src="~@/assets/images/gplus.png" alt="google plus">
                                        </figure>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.pinterest.com/successagingHH/" target="_blank">
                                        <figure>
                                            <img src="~@/assets/images/pinterest.png" alt="pinterest">
                                        </figure>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="header_hdng">
                            <div class="widget-container classic-textwidget custom-classic-textwidget">
                                <div class="classic-text-widget">
                                    <h2>Call Us: <br> <mark>888-960-4090 <br> 215-710-0098</mark></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </header> -->

    </div>
</template>

<script>
export default {
    data() {
        return {
            homeHeader: true,
            showSidebar: false,
            isHomeCareSection: false,
            isEducationSection: false,
        }
    },
    watch: {
        '$route': {
            // eslint-disable-next-line
            handler: function(newVal) {
                if (newVal && newVal.name != 'Home') {
                    this.homeHeader = false;
                } else if (newVal && newVal.name == 'Home') {
                    this.homeHeader = true;
                }

                if (newVal.fullPath.includes('/home-care')) {
                    this.isHomeCareSection = true
                    this.isEducationSection = false
                } else if (newVal.fullPath.includes('/education')) {
                    this.isHomeCareSection = false
                    this.isEducationSection = true
                } else {
                    this.isHomeCareSection = true
                    this.isEducationSection = false
                }
            },
            deep: true
        },
    },
    mounted() {
        if (this.$route.name != 'Home') {
            this.homeHeader = false;
        } else if (this.$route.name == 'Home') {
            this.homeHeader = true;
        }

        if (this.$route.fullPath.includes('/home-care')) {
            this.isHomeCareSection = true
            this.isEducationSection = false
        } else if (this.$route.fullPath.includes('/education')) {
            this.isHomeCareSection = false
            this.isEducationSection = true
        } else {
            this.isHomeCareSection = true
            this.isEducationSection = false
        }
    },

    computed: {
        showFooterLogo() {
            return !this.$route.fullPath.includes('/education')
        }
    },

    methods: {
        isCurrentComponent(componentName) {
            return this.$route.name == componentName ? true : false;
        }
    },
}
</script>

<style>
header#responsive-header {
    display: none!important;
}
header.home-header .main_logo {
    position: absolute;
    left: -152px;
    right: 0;
    top: 410px;
    width: 390.6px;
    background: #fff;
    border-radius: 100%;
    float: left;
    text-align: center;
    margin: 0 auto;
    z-index: 25;
    height: 390.6px;
    padding: 140px 0 0;
}
header.home-header .main_logo img {
    width: 325.6px;
}

@media screen and (max-width: 1030px) {
    #small-logo {
        display: none!important;
    }
}

@media only screen and (max-width: 1010px) {
    header {
        padding: 0;
        position: static;
        background: #f8f8f8;
    }

    header .header_con .main_logo {
        position: static!important;
        background: 0!important;
        width: 550px!important;
        height: auto!important;
        padding: 0!important;
    }

    .main_logo img {
        width: 100%!important;
    }
}

@media only screen and (max-width: 800px) {
    header#main-header {
        display: none!important;
    }

    header#responsive-header {
        display: block!important;
    }

    #nav_area {
        padding: 15px 10px!important;
        background: #f0f0f0!important;
    }

    .nav_toggle_button {
        display: block;
    }

    .nav_area .nav_toggle_button .main_logo {
        width: calc(100% - 200px)!important;
    }
}

nav.page_nav:not(.is-education-nav) ul li:first-child:after {
    display: none!important;
}
</style>
