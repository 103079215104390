<template>
    <div class="clearfix">
        <div class="wrapper">
            <div id="contact_us_form_1" class="template_form">
                <div class="form_frame_b">
                    <div class="form_content">
                        <form id="submitform" name="contact" @submit.prevent="handleSubmitForm">

                            <alert :alertData="alertData"/>

                            <span class="required-info">* Required Information</span>
                            <div class="form_box">
                                <div class="form_box_col2">
                                    <div class="group">
                                        <div class="form_label">
                                            <label for="Name_of_Referrer" class="text_uppercase">
                                                <strong>Name of Referrer
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" v-model="applicant.clientName" :class="{ 'error': submitted && $v.applicant.clientName.$error }" type="text" class="form_field" id="Name_of_Referrer" placeholder="Enter name of referrer here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                    <div class="group">
                                        <div class="form_label">
                                            <label :for="`Email_Address-${index}`" class="text_uppercase">
                                                <strong>Email Address
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" v-model="applicant.email" :class="{ 'error': submitted && $v.applicant.email.$error }" type="text" class="form_field" :id="`Email_Address-${index}`" placeholder="Enter email address here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>

                            <div class="cloneField extra-referral" v-for="(referral, index) in applicant.referrals" :key="index">
                                <span class="remove-item" v-if="index > 0" @click="removeReferral(index)">
                                    <i class="fa fa-times"></i>
                                </span>
                                <div class="form_box">
                                    <div class="form_box_col1 referral_name">
                                        <div class="group">
                                            <div class="form_label">
                                                <label :for="`Name-${index}`" class="text_uppercase">
                                                    <strong>Name
                                                        <span class="required_filed"></span>
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="field_holder animated_box">
                                                <input autocomplete="nope" v-model="applicant.referrals[index].name" type="text" class="form_field" :id="`Name-${index}`" placeholder="Enter name here">
                                                <span class="animated_class"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_box">
                                    <div class="form_box_col2">
                                        <div class="group">
                                            <div class="referral_email">
                                                <div class="form_label">
                                                    <label :for="`Email_Address_${indx}`" class="text_uppercase">
                                                        <strong>Email Address
                                                            <span class="required_filed"></span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" v-model="applicant.referrals[index].email" class="form_field" :id="`Email_Address_${indx}`" placeholder="Enter email address here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="group">
                                            <div class="referral_phone">
                                                <div class="form_label">
                                                    <label :for="`Phone-${index}`" class="text_uppercase">
                                                        <strong>Phone
                                                            <span class="required_filed"></span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" v-model="applicant.referrals[index].phoneNumber" class="form_field Phone" :id="`Phone-${index}`" placeholder="Enter phone here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="addMore" @click="addReferrals();">
                                <i class="fa fa-plus-circle"></i> Add more referrals...
                            </div>

                            <div class="form_box5 secode_box">
                                <div class="group">
                                    <div class="inner_form_box1 recapBtn">
                                        <div class="btn-submit">
                                            <button type="submit" class="button form_button" :disabled="loading">SUBMIT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import alert from '@/components/layouts/alert'
import { postNewReferral } from '@/api/apiCalls'
import { getUserAgent, getUserIp } from '@/helpers'
import { required, email } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            submitted: false,
            loading: false,
            applicant: {
                clientName: "",
                email: "",
                referrals: [{}],
            },
            alertData: {
                showAlert: false,
                alertType: "success",
                alertText: "Data Added Successfully!"
            }
        }
    },
    validations: {
        applicant: {
            clientName: { required },
            email: { required, email },
        }
    },
    components: {
        alert
    },
    methods: {
        addReferrals() {
            this.applicant.referrals.push({});
        },
        removeReferral(index) {
            this.applicant.referrals.splice(index, 1);
        },
        handleSubmitForm() {
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();

            // Scroll To Error
            this.$nextTick(() => {
                let element = document.querySelector('.form_field.error');
                if(element) {
                    let domRect = element.getBoundingClientRect();
                    window.scrollTo({
                        left: domRect.left + document.documentElement.scrollLeft,
                        top: domRect.top + (document.documentElement.scrollTop - 100),
                        behavior: "smooth"
                    });
                }
            });

            if(!this.$v.$invalid) {
                this.loading = true;

                let data = {
                    clientName: this.applicant.clientName,
                    email: this.applicant.email,
                    referrals: this.applicant.referrals,
                    userAgent: getUserAgent(),
					ip: getUserIp()
                };

                postNewReferral(data).then(() => {
                    this.alertData.showAlert = true;
                    this.loading = false;
                    this.submitted = false;
                    this.$router.push({
                        name: 'Confirmation'
                    });
                    this.applicant = {
                        clientName: "",
                        email: "",
                        referrals: [{}]
                    };
                    setTimeout(() => {
                        this.alertData.showAlert = false;
                    }, 3000);
                }).catch(errors => {
                    this.alertData = {
                        showAlert: true,
                        alertType: "danger",
                        alertText: errors.response.data.errors.email[0]
                    };
                    this.loading = false;
                    this.submitted = false;
                    setTimeout(() => {
                        this.alertData.showAlert = false;
                    }, 3000);
                });
            }
        }
    },
}
</script>
